import { MouseEventHandler } from 'react';
import '../../styles/Buttons.scss';

type TagProps = {
  title: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  key?: React.Key;
  tooltip?: string;
};

export const RSTag = (props: TagProps): JSX.Element => {
  return (
    <button
      className={`rs-tag ${props.className} bg-opacity-25 `}
      onClick={props.onClick}
      disabled={!props.onClick}
      title={props.tooltip}
    >
      {props.title}
    </button>
  );
};
