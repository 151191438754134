import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import FormErrorText from '../form-error-text/FormErrorText';
import FormLabel from '../FormLabel';

type BaseFormNumberInputProps<T> = {
  name: string;
  label?: string;
  suffix?: ReactNode | ((value?: T) => ReactNode);
  placeholder?: string;
  required?: boolean;
  icon?: IconProp;
  disabled?: boolean;
  min?: number | ((currValue: T) => number | undefined);
  max?: number | ((currValue: T) => number | undefined);
  step?: number | string | undefined;
  onChangeHandler?: (value: number) => void;
  getFieldValue: (value: number, currValue?: T) => T;
  getDisplayValue: (currValue: T | null) => number | undefined;
};

export default function BaseFormNumberInput<T>(props: BaseFormNumberInputProps<T>) {
  const [field, meta] = useField<T>(props);

  const form = useFormikContext();

  const min = typeof props.min === 'function' ? props.min(field.value) : props.min;
  const max = typeof props.max === 'function' ? props.max(field.value) : props.max;
  const getValidValue = (value: string) => {
    let valueAsNum = Number.parseFloat(value);

    if (Number.isNaN(valueAsNum)) valueAsNum = 0;

    if (min !== undefined && max !== undefined) {
      return Math.min(max, Math.max(valueAsNum, min));
    } else if (min !== undefined) {
      return Math.max(min, valueAsNum);
    } else if (max !== undefined) {
      return Math.min(max, valueAsNum);
    }

    return valueAsNum;
  };

  return (
    <div className={`flex flex-col flex-grow`}>
      <FormLabel label={props.label} required={props.required} />
      <div className={`rs-form-icon-container w-full`}>
        {/* Icon */}
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {/* Input */}
        <input
          type={'number'}
          placeholder={props.placeholder}
          disabled={props.disabled}
          min={min}
          max={max}
          step={props.step}
          autoComplete="off"
          className={`rs-form-input w-full ${props.suffix ? 'suffix-padding' : ''} ${!props.icon ? 'pl-4' : ''} ${
            meta.error && meta.touched ? 'border-red-500' : ''
          }`}
          {...field}
          value={props.getDisplayValue(field.value)}
          onChange={(e) => {
            // ensure that an appropriate value is always set
            const strVal = e.target.value;
            if (!strVal || strVal === '') {
              form.setFieldTouched(props.name, true);
              form.setFieldValue(props.name, null);
              return;
            }
            const val = getValidValue(strVal);
            form.setFieldTouched(props.name, true);
            form.setFieldValue(props.name, props.getFieldValue(val, field.value));
            props.onChangeHandler && props.onChangeHandler(val);
          }}
        />
        {props.suffix && (
          <div className="input-suffix">
            {typeof props.suffix === 'function' ? props.suffix(field.value) : props.suffix}
          </div>
        )}
      </div>
      <FormErrorText meta={meta} />
    </div>
  );
}
