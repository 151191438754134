import { ApolloError } from '@apollo/client/errors';
import {
  faBed,
  faCalendarDay,
  faClock,
  faClockRotateLeft,
  faCode,
  faDroplet,
  faIdBadge,
  faPencil,
  faSatellite,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Device, FirmwareLockStatus, useManufacturerGetDeviceQuery } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable from '../../../shared/layout/table/RSPivotTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import DeviceFormDialog from '../components/DeviceFormDialog';
import FirmwarePivotRow from '../components/FirmwarePivotRow';
import SprayLogTable from '../components/SprayLogTable';
import { GetFullUnitDisplay } from '../../../shared/utilities/UnitConversionUtilities';

export default function ManufacturingDevicePage() {
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const { deviceId } = useParams();

  const {
    data: deviceData,
    loading: deviceLoading,
    error: deviceError,
  } = useManufacturerGetDeviceQuery({
    variables: {
      id: deviceId as string,
    },
  });
  const device = deviceData?.manufacturerDevice as Device;

  useSetBreadcrumbs({
    loading: deviceLoading,
    entity: device,
    params: {
      deviceId: (x) => x.alias,
    },
  });

  const rows = useMemo(() => {
    const rows = [
      {
        name: 'Id/IMEI',
        value: device?.id,
        icon: faIdBadge,
      },
      {
        name: 'Alias',
        value: device?.alias,
        icon: faPencil,
      },
      {
        name: 'Calibration Volume',
        value: `${GetFullUnitDisplay(device?.calibrationVolume, 2)}`,
        icon: faDroplet,
      },
      {
        name: 'Calibration Timeout',
        value: `${device?.calibrationTimeout}  (s)`,
        icon: faClock,
      },
      {
        name: 'Rinse Volume',
        value: `${GetFullUnitDisplay(device?.rinseVolume, 2)}`,
        icon: faDroplet,
      },
      {
        name: 'Rinse Timeout',
        value: `${device?.rinseTimeout} (s)`,
        icon: faClock,
      },
      {
        name: 'Logging Period',
        value: `${device?.loggingPeriod} (s)`,
        icon: faClockRotateLeft,
      },
      {
        name: 'Sleep Timeout',
        value: `${device?.sleepTimeout} (min)`,
        icon: faBed,
      },
      {
        name: 'GPS Accuracy',
        value: `${GetFullUnitDisplay(device?.gpsAccuracy, 2)}`,
        icon: faSatellite,
      },
      {
        name: 'Registration Date',
        value: new Date(device?.registrationDate).toLocaleString(),
        icon: faCalendarDay,
      },
      {
        name: 'Firmware',
        icon: faCode,
        notCopyable: true,
        value: <FirmwarePivotRow firmwareInformation={device?.firmwareInformation} />,
      },
    ];

    return rows;
  }, [deviceData]);

  if (deviceLoading) {
    return <BubbleLoader />;
  } else if (deviceError) {
    return <QueryError resourceName={`device '${deviceId}'`} apolloError={deviceError as ApolloError} />;
  } else if (device === null && !deviceLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      {/* Component */}
      <RSGrid>
        <GridTile
          height={450}
          colSpan={3}
          title={'Overview'}
          actions={
            <RSButton variant="primary" onClick={() => setShowEdit(true)}>
              <FontAwesomeIcon icon={faPencil} />
              Edit Device
            </RSButton>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        <GridTile colSpan={6} title={'Spray Logs'}>
          <SprayLogTable deviceId={deviceId as string}></SprayLogTable>
        </GridTile>
      </RSGrid>
      <DeviceFormDialog
        initialValues={device}
        orgLock={
          device.organisation?.organisationFirmware?.lockStatus == FirmwareLockStatus.Locked
            ? device.organisation?.organisationFirmware?.firmware.id
            : undefined
        }
        isOpen={showEdit}
        onClose={() => setShowEdit(false)}
      />
    </>
  );
}
