import { toast } from 'react-toastify';
import { weedUpdateValidator, weedValidator } from '../validation/WeedValidator';
import {
  CreateWeedInput,
  Weed,
  useCreateWeedMutation,
  UpdateWeedInput,
  useUpdateWeedMutation,
  GetWeedsByOrganisationIdDocument,
} from '../../../graphql/generated';
import WeedFormContent from './WeedFormContent';
import useCreateNavigate from '../../../shared/components/UseCreateNavigate';

type Props = {
  currentOrganisation: string;
  initialValues?: Weed;
  isOpen: boolean;
  onClose: () => void;
};

export default function WeedFormDialog({ currentOrganisation, initialValues, isOpen, onClose }: Props) {
  const [createWeed] = useCreateWeedMutation();
  const [updateWeed] = useUpdateWeedMutation();
  const navigateToWeed = useCreateNavigate();
  if (initialValues) {
    return (
      <WeedFormContent<UpdateWeedInput, Weed>
        isEdit
        initialValues={
          weedUpdateValidator.cast(
            { ...initialValues, weedId: initialValues.id },
            { stripUnknown: true },
          ) as UpdateWeedInput
        }
        validator={weedUpdateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          return (
            await updateWeed({
              variables: {
                input: {
                  weedId: value.weedId,
                  name: value.name,
                  species: value.species,
                  description: value.description,
                  updateImage: value.updateImage,
                },
              },
              onCompleted(data) {
                toast(`A weed with name - ${data.updateWeed.name} has been successfully updated!`, {
                  type: 'success',
                });
              },
            })
          ).data?.updateWeed as Weed;
        }}
      />
    );
  } else {
    return (
      <WeedFormContent<CreateWeedInput, Weed>
        initialValues={weedValidator.cast({ organisationId: currentOrganisation }) as CreateWeedInput}
        validator={weedValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          return (
            await createWeed({
              variables: {
                // This is to discard the image validation in weedValidator
                input: {
                  organisationId: value.organisationId,
                  name: value.name,
                  species: value.species,
                  description: value.description,
                  updateImage: value.updateImage,
                },
              },
              onCompleted(data) {
                toast(`A new weed with name - ${data.createWeed.name} has been successfully created!`, {
                  type: 'success',
                });
                //Navigates to created weed page
                navigateToWeed(data.createWeed.id);
              },
              refetchQueries: [GetWeedsByOrganisationIdDocument],
            })
          ).data?.createWeed as Weed;
        }}
      />
    );
  }
}
