import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, FormikHelpers, isNaN } from 'formik';
import moment from 'moment';
import { Coordinate } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';
import {
  CoordinatesInput,
  UnitMeasurement,
  UnitSystem,
  UnitType,
  Weed,
  WeedFilterInput,
  WeedSearchQuery,
  WeedSearchQueryVariables,
  useWeedSearchQuery,
} from '../../../graphql/generated';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import FormDateInput from '../../../shared/components/forms/form-date-input/FormDateInput';
import FormNumberInput from '../../../shared/components/forms/form-number-input/FormNumberInput';
import FormDebounceInput from '../../../shared/components/forms/form-text-input/FormDebounceInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { OptionalLatLonValues } from '../../../shared/utilities/OpenLayersUtilities';
import { DateInputFormat } from '../../../shared/utilities/TimeUtilities';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';
import { searchValidator } from '../validation/searchValidator';
import AutoCompleteWrapper from './AutoCompleteWrapper';
import { WeedsMapUnitSystemToUnitType } from '../pages/WeedsMap';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';

interface SearchFilterValues {
  weedId: string;
  coordinates: CoordinatesInput;
  radius: number;
  startTime: string;
  endTime: string;
}

type StringCallback = (value: string) => void;
type NumberCallback = (value: number) => void;
type CoordinateCallback = (val: OptionalLatLonValues) => void;

interface SearchFilterProps extends Omit<SearchFilterValues, 'coordinates'> {
  coordinates: Coordinate;
  onSubmitCallback: (values: SearchFilterValues, formikHelpers: FormikHelpers<SearchFilterValues>) => void;
  onPlaceChangedCallback: (autocomplete: google.maps.places.Autocomplete) => void;
  setCoordinateCallback: CoordinateCallback;
  setRadiusCallback: NumberCallback;
  setWeedIdCallback: StringCallback;
  setStartTimeCallback: StringCallback;
  setEndTimeCallback: StringCallback;
}

const WeedsMapUnitSystemToConstraints: { [key in UnitSystem]: { min: number; max: number } } = {
  [UnitSystem.Imperial]: { min: 1, max: 60 },
  [UnitSystem.Metric]: { min: 1, max: 100 },
};

export default function WeedMapSearchFilter(props: SearchFilterProps) {
  const currentOrganisation = useOrganisationId();
  const coordinates = toLonLat(props.coordinates);

  const { unitSystem } = useSelectedOrganisationIdStore();
  const lengthUnit = WeedsMapUnitSystemToUnitType[unitSystem];

  return (
    <Formik<SearchFilterValues>
      initialValues={{
        weedId: props.weedId,
        coordinates: { latitude: coordinates[1], longitude: coordinates[0] },
        radius: props.radius,
        startTime: props.startTime,
        endTime: props.endTime,
      }}
      enableReinitialize
      validateOnMount
      validationSchema={searchValidator}
      onSubmit={props.onSubmitCallback}
    >
      {(formik) => {
        return (
          <Form>
            <AutoCompleteWrapper
              icon={faSearch}
              placeHolder="Address Search"
              onPlaceChangedCallback={props.onPlaceChangedCallback}
            />
            <div className="grid sm:grid-cols-2 gap-x-4">
              <FormNumberInput
                required
                name="coordinates.latitude"
                label="Latitude"
                placeholder="Latitude"
                suffix="°"
                max={90}
                min={-90}
                step="any"
                onChangeHandler={(value) => props.setCoordinateCallback({ lat: value })}
              />
              <FormNumberInput
                required
                name="coordinates.longitude"
                label="Longitude"
                placeholder="Longitude"
                suffix="°"
                max={180}
                min={-180}
                step="any"
                onChangeHandler={(value) => props.setCoordinateCallback({ lon: value })}
              />
            </div>
            <FormDebounceInput
              onChangeHandler={(radius) => {
                let num = Number(radius);
                if (radius === '') num = 1;
                if (isNaN(num)) return;
                props.setRadiusCallback(num);
              }}
              onTypeHandler={(valString) => {
                if (valString === '') return true;
                if (isNaN(Number(valString))) return false;
                const currentValue = parseInt(valString);
                return !(
                  currentValue < WeedsMapUnitSystemToConstraints[unitSystem].min ||
                  currentValue > WeedsMapUnitSystemToConstraints[unitSystem].max
                );
              }}
              name="radius"
              label="Radius"
              debounceTimeout={500}
              value={props.radius.toString()}
              suffix={GetUnitSymbol(lengthUnit)}
            />
            <FormComboBoxWithQuery<Weed, WeedSearchQuery, WeedSearchQueryVariables, WeedFilterInput>
              required
              initialValue={{
                value: '',
                displayValue: '',
              }}
              label="Weed"
              name={'weedId'}
              dataAccessorCallback={(data) =>
                data?.weedsByOrganisationId?.nodes?.map((weed) => ({
                  displayValue: weed.name,
                  value: weed.id,
                })) ?? []
              }
              searchFieldNames={['name']}
              query={useWeedSearchQuery}
              variables={{ id: currentOrganisation }}
              onChangeHandler={(item) => props.setWeedIdCallback(item?.value ?? '')}
            />
            <FormDateInput
              name="startTime"
              label="Start Time"
              required
              onChangeHandler={(value) => props.setStartTimeCallback(value)}
            />
            <FormDateInput
              name="endTime"
              label="End Time"
              required
              onChangeHandler={(value) => props.setEndTimeCallback(value)}
              min={moment(props.startTime).add(1, 'minute').format(DateInputFormat)}
              max={moment(props.startTime).add(1, 'year').format(DateInputFormat)}
            />
            <div className="flex flex-row w-full justify-end pt-2">
              <RSButton variant={formik.isValid ? 'primary' : 'disabled'} onClick={formik.submitForm}>
                Search
              </RSButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
