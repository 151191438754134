import React from 'react';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';

type Props = {
  loaded: number;
  total: number;
  fileName: string;
  error?: string;
};

export default function UploadFileProgressCard({ loaded, total, error }: Props) {
  const renderInnerDiv = () => {
    if (error) {
      return (
        <>
          <h3 className="text-center py-3 font-bold text-xl">
            An error occurred whilst attempting to upload the file.
          </h3>
          <p className="text-center py-3">
            The item will still appear in the item list, you can try to upload the file again at any time, if you
            continue to have issues, please contact Rapid Logix support.
          </p>
        </>
      );
    } else {
      return (
        <>
          <h3 className="text-center py-3 font-bold text-xl">Please wait while your file is uploaded.</h3>
          <p className="text-center py-3">
            Please don&apos;t leave this page until the download is complete, it&apos;s okay to let this tab run in the
            background while you wait.
          </p>
          <h1 className="text-center py-2 my-2">{((loaded * 100) / total).toFixed(0)} %</h1>
          <BubbleLoader />
        </>
      );
    }
  };

  return (
    <div className="form-layout flex flex-col justify-center py-3">
      <div className="flex flex-col justify-center py-3">{renderInnerDiv()}</div>
    </div>
  );
}
