import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat, getPointResolution, METERS_PER_UNIT } from 'ol/proj';

export enum ConvertDistanceMode {
  Metres_To_Map_Units,
  Map_Units_To_Metres,
}

export interface OptionalLatLonValues {
  lat?: number;
  lon?: number;
}

/**
 * Converts a distance near a point between metres and map units or vice versa
 * @param mode Whether the conversion is from metres to map units or vice versa
 * @param map The map being used
 * @param distance The distance to convert
 * @param atPoint A coordinate close to the distance being converted
 * @returns The converted distance
 */
export function convertDistance(mode: ConvertDistanceMode, map: Map, distance: number, atPoint: Coordinate) {
  const view = map.getView();
  const projection = view.getProjection();
  const resolutionAtEquator = view.getResolution() as number;
  const pointResolution = getPointResolution(projection, resolutionAtEquator, atPoint);
  const resolutionFactor = resolutionAtEquator / pointResolution;

  if (mode == ConvertDistanceMode.Map_Units_To_Metres) {
    return (distance / resolutionFactor) * METERS_PER_UNIT.m;
  }

  return (distance / METERS_PER_UNIT.m) * resolutionFactor;
}

/**
 * Updates the latitude value of existing coordinates
 * @param coordinates The existing coordinates which latitude should be updated
 * @param lat The latitude in LonLat (e.g. from Google Maps)
 * @returns The updated coordinates
 */
export function updateLatFromLonLat(coordinates: Coordinate, lat: number) {
  const projected = fromLonLat([0, lat]);
  return [coordinates[0], projected[1]];
}

/**
 * Updates the longitude value of existing coordinates
 * @param coordinates The existing coordinates which longitude should be updated
 * @param lat The longitude in LonLat (e.g. from Google Maps)
 * @returns The updated coordinates
 */
export function updateLonFromLonLat(coordinates: Coordinate, lon: number) {
  const projected = fromLonLat([lon, 0]);
  return [projected[0], coordinates[1]];
}

/**
 * Updates a set of coordinates from provided lat lon values.
 * @param coordinate Coordinates to update
 * @param val Values for latitude and longitude
 * @returns Updated coordinates (if they could updated)
 */
export function updateCoordinatesFromLonLat(coordinate: Coordinate, val: OptionalLatLonValues) {
  if (val.lat !== undefined && val.lon !== undefined) {
    return fromLonLat([val.lon, val.lat]);
  } else if (val.lat !== undefined) {
    return updateLatFromLonLat(coordinate, val.lat);
  } else if (val.lon !== undefined) {
    return updateLonFromLonLat(coordinate, val.lon);
  }

  return coordinate;
}
