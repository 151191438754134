import { useField } from 'formik';
import { ReactNode } from 'react';
import RSCheckBox from './RSCheckBox';

interface RSCheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  key: string;
  label: ReactNode;
  name: string;
  initialValue: boolean | null;
}
export default function RSFieldCheckbox({ key, label, name, initialValue }: RSCheckBoxProps) {
  const [_, meta, helpers] = useField<boolean>(name);

  return (
    <RSCheckBox
      key={key}
      name={name}
      label={label}
      initialValue={initialValue ?? meta.initialValue ?? false}
      onChangeHandler={(event, isChecked) => {
        helpers.setValue(!isChecked);
        return !isChecked;
      }}
    />
  );
}
