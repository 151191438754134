import create from 'zustand';
import { CognitoUserAmplify } from '@aws-amplify/ui';
import { UserRole } from '../../../graphql/generated';

type CurrentUserStoreState = {
  currentUser: CognitoUserAmplify | undefined;
};

type Actions = {
  setCurrentUser: (currentUser: CognitoUserAmplify | undefined) => void;
  getCurrentUserRole: () => UserRole | undefined;
  isSuperAdmin: () => boolean;
  getCurrentUserOrganisationId: () => string | undefined;
  reset: () => void;
};

// define the initial state
const initialState: CurrentUserStoreState = {
  currentUser: undefined,
};

// create store
const useCurrentUserStore = create<CurrentUserStoreState & Actions>((set, get) => ({
  ...initialState,
  setCurrentUser: (currentUser) => {
    set(() => ({ currentUser: currentUser }));
  },
  getCurrentUserRole: () => {
    const currentUser = get().currentUser;

    if (!currentUser || !currentUser.attributes) {
      return undefined;
    }

    const roleClaim = currentUser.attributes['custom:userRole'] as keyof typeof UserRole;

    if (roleClaim) {
      return UserRole[roleClaim] as UserRole;
    } else {
      return undefined;
    }
  },
  isSuperAdmin() {
    return get().getCurrentUserRole() == UserRole.SuperAdmin;
  },
  getCurrentUserOrganisationId() {
    const currentUser = get().currentUser;

    if (!currentUser || !currentUser.attributes) {
      return undefined;
    }

    return currentUser.attributes['custom:organisation'];
  },
  reset: () => {
    set(initialState);
  },
}));

export default useCurrentUserStore;
