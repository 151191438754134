import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Chemical,
  useGetChemicalsByOrganisationIdQuery,
  ChemicalFilterInput,
  ChemicalSortInput,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import ChemicalFormDialog from '../components/ChemicalFormDialog';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { chemicalTypeToText } from '../utilities/ChemicalTypeUtilities';

export default function ChemicalsPage() {
  const [showCreateChemical, setShowCreateChemical] = useState<boolean>(false);

  const currentOrganisation = useOrganisationId();

  const {
    searchString: searchString,
    where: where,
    setSearchString: setSearchString,
    order: order,
    setSort: setSort,
  } = useSearchSortFilter<ChemicalFilterInput>({
    searchableFieldNames: ['name', 'description', 'activeIngredient', 'manufacturer'],
  });

  const {
    data: data,
    loading: loading,
    fetchMore: fetchMore,
  } = useGetChemicalsByOrganisationIdQuery({
    variables: {
      id: currentOrganisation,
      where: where,
      order: order,
    },
  });

  const cols = [
    {
      title: 'Name',
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Active Ingredient',
      id: 'activeIngredient',
      accessor: (x) => x.activeIngredient,
    },
    {
      title: 'Type',
      id: 'type',
      accessor: (x) => chemicalTypeToText[x.type],
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
    {
      title: 'Manufacturer',
      id: 'manufacturer',
      accessor: (x) => x.manufacturer,
    },
  ] as Array<RSTableColumnDefinition<Chemical>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Chemicals'}
          actions={
            <PermissionGuard policy={AuthPolicy.RecipeManagement}>
              <RSButton onClick={() => setShowCreateChemical(true)}>
                <FontAwesomeIcon icon={faPlus} />
                Create Chemical
              </RSButton>
            </PermissionGuard>
          }
        >
          <SearchSortFilterBar<ChemicalSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['name', 'description', 'manufacturer', 'activeIngredient']}
          />
          <RSTable<Chemical>
            isLoading={loading}
            columns={cols}
            data={(data?.chemicalsByOrganisationId?.edges?.map((e) => e.node) as Chemical[]) ?? []}
            totalCount={data?.chemicalsByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.chemicalsByOrganisationId?.pageInfo.endCursor as string) : undefined,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={showCreateChemical}>
        <ChemicalFormDialog
          currentOrganisation={currentOrganisation}
          isOpen={true}
          onClose={() => setShowCreateChemical(false)}
        />
      </PermissionGuard>
    </>
  );
}
