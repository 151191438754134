import { DependencyList, ReactNode, useMemo } from 'react';

interface RenderGuardProps {
  children: ReactNode;
  isVisible?: boolean;
  deps?: DependencyList;
}

export default function RenderGuard({ children, isVisible, deps }: RenderGuardProps) {
  return useMemo(() => {
    return <>{isVisible == true || isVisible == undefined ? children : undefined}</>;
  }, ([isVisible] as unknown[]).concat(deps));
}
