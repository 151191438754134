/**
 * This global store allows key value pairs to be set for react router paths
 * that allow matching breadcrumbs values to be overridden.
 * For example for the path
 *
 * /Organisations/:organisationId
 * We could set the breadcrumb using setCrumbForPath({organisationId : "Jaegersoft"})
 * This can then be used allow the breadcrumb to show a display name instead of the id.
 * Organisations/u8ct783249ucc0923uc0984u5f => Organisations/Jaegersoft
 * Author: Max Wroe
 */

import create from 'zustand';

interface OverrideBreadcrumbState {
  crumbsForPath: { [path: string]: string };
  setCrumbForPath: (newCrumbs: { [path: string]: string }) => void;
}

const useOverrideBreadcrumbStore = create<OverrideBreadcrumbState>((set) => ({
  crumbsForPath: {},
  setCrumbForPath: (newCrumbs: { [path: string]: string }) => {
    set(() => {
      const result = {
        crumbsForPath: {
          ...newCrumbs,
        },
      };
      return result;
    });
  },
}));

export default useOverrideBreadcrumbStore;
