import { ScaleLine } from 'ol/control';
import { useContext, useEffect } from 'react';
import useSelectedOrganisationIdStore from '../../../../hooks/stores/UseSelectedOrganisationIdStore';
import MapContext from '../MapContext';
import { UnitSystem } from '../../../../../graphql/generated';
import { Units } from 'ol/control/ScaleLine';

const unitSystemToScaleLine: { [key in UnitSystem]: Units } = {
  [UnitSystem.Imperial]: 'imperial',
  [UnitSystem.Metric]: 'metric',
};

export default function ScaleLineControl() {
  const map = useContext(MapContext);
  const { unitSystem } = useSelectedOrganisationIdStore();

  useEffect(() => {
    if (!map) return;

    const scaleLineControl = new ScaleLine({
      units: unitSystemToScaleLine[unitSystem],
    });

    map.addControl(scaleLineControl);

    return () => {
      map.removeControl(scaleLineControl);
    };
  }, [map]);

  return null;
}
