import * as Yup from 'yup';
import { UserRole } from '../../../graphql/generated';
import { MaximumNameLength, MongoIdLength, UserIdLength } from '../../../shared/constants/ValidationConstants';

export const userInviteValidator = Yup.object({
  firstName: Yup.string()
    .required()
    .max(MaximumNameLength, `name can not exceed ${MaximumNameLength} characters`)
    .default(''),
  lastName: Yup.string()
    .required()
    .max(MaximumNameLength, `name can not exceed ${MaximumNameLength} characters`)
    .default(''),
  email: Yup.string().required().email().default(''),
  role: Yup.string().oneOf(Object.values(UserRole)).default(UserRole.Operator),
  organisationId: Yup.string()
    .when('role', {
      is: (val: UserRole) => ![UserRole.SuperAdmin, UserRole.Manufacturer].includes(val),
      then: Yup.string()
        .required('Organisation is a required field')
        .length(MongoIdLength, 'The selected organisation id is invalid')
        .default(''),
    })
    .default(''),
});

export const userUpdateValidator = userInviteValidator
  .shape({
    id: Yup.string().required().length(UserIdLength),
  })
  .omit(['organisationId', 'email']);
