import { WebhookEventKind } from '../../../graphql/generated';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { WebhookEventDefinitions } from '../WebhookEventUtils';

type WebhookEventKindTagProps = {
  event: WebhookEventKind;
};

export function WebhookEventKindTag({ event }: WebhookEventKindTagProps) {
  return <RSTag title={`${WebhookEventDefinitions[event].displayName}`} className={'bg-gray-300'} />;
}
