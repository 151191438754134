import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import Vector from 'ol/source/Vector';
import Point from 'ol/geom/Point';

interface WebGLLayerProps {
  layer: WebGLPointsLayer<Vector<Point>>;
}

export default function WebGLLayer({ layer }: WebGLLayerProps) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.addLayer(layer);

    return () => {
      if (map) {
        map.removeLayer(layer);
      }
    };
  }, [map, layer]);

  return null;
}
