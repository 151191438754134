import { FormikProps, FormikValues } from 'formik';
import RSButton from '../../input/rs-button/RSButton';
import RSNotification from '../../rs-notification/RSNotification';
import RSPopover from '../../rs-popover/RSPopover';

type FormConfirmCloseButtonProps<T extends FormikValues> = {
  isBlocking: boolean;
  formik?: FormikProps<T> | null;
  setIsBlocking: (isBlocking: boolean) => void;
  onClose: () => void;
};

export default function FormConfirmCloseButton<T extends FormikValues>({
  isBlocking,
  formik,
  setIsBlocking,
  onClose,
}: FormConfirmCloseButtonProps<T>) {
  return (
    <>
      {isBlocking ? (
        <RSPopover buttonLabel="Close" buttonType="secondary">
          <RSNotification variant="danger" title="Are you sure?">
            {`Your unsaved form data will be lost if you select 'confirm'.`}
            <div className="pt-2 flex gap-x-2 justify-end">
              <RSButton variant="secondary">Cancel</RSButton>
              <RSButton
                variant="danger"
                onClick={() => {
                  setIsBlocking(false);
                  formik?.resetForm();
                  onClose();
                }}
              >
                Confirm
              </RSButton>
            </div>
          </RSNotification>
        </RSPopover>
      ) : (
        <RSButton variant="secondary" type="button" onClick={onClose}>
          Close
        </RSButton>
      )}
    </>
  );
}
