interface RSStatelessCheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name?: string;
  className?: string;
  checked: boolean;
  disabled?: boolean;
  title?: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function RSStatelessCheckBox({
  checked,
  onChangeHandler,
  disabled,
  id,
  title,
  name,
  className,
}: RSStatelessCheckBoxProps) {
  return (
    <div>
      <input
        id={id}
        name={name}
        title={title}
        disabled={disabled}
        type="checkbox"
        className={`${disabled ? 'bg-gray-700' : 'accent-primary'} rounded ${className}`}
        checked={checked}
        onChange={onChangeHandler}
      />
    </div>
  );
}
