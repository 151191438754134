import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import env from '@beam-australia/react-env';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';

const httpLink = new HttpLink({
  uri: env('GRAPHQL_SERVER_URL'),
});

const authMiddleware = setContext(async (operation, forward) => {
  // Retrieve jwt token from store.
  const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      ...forward.headers,
      authorization: 'Bearer ' + authToken,
    },
  };
});

const apolloClient = new ApolloClient({
  //Must first cast as unknown due to inconsistencies between apollo link and apollo client.
  link: from([authMiddleware as unknown as ApolloLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          sprayLogsForDevice: relayStylePagination(['$deviceId']),
          jobsByOrganisationId: relayStylePagination(['$id', '$where', '$order']),
          users: relayStylePagination(['$id', '$where', '$order']),
          existingJobs: relayStylePagination(['$id', '$where', '$order', '$first']),
          usersByOrganisationId: relayStylePagination(['$id', '$where', '$order']),
          devicesByOrganisationId: relayStylePagination(['$id', '$where', '$order']),
          superAdminGetAllUsers: relayStylePagination(['$where', '$order']),
          chemicalsByOrganisationId: relayStylePagination(['$id', '$where', '$order']),
          recipesByOrganisationId: relayStylePagination(['$id', '$where', '$order']),
          activeJobs: relayStylePagination(['$id', '$where', '$order']),
          devices: relayStylePagination(['$where', '$order']),
          manufacturingDevices: relayStylePagination(['$where', '$order']),
          topSprayers: relayStylePagination(['$id', '$monthOf']),
          firmware: relayStylePagination(['$id', '$where', '$order']),
          enabledFirmware: relayStylePagination(['$id', '$where', '$order']),
          organisations: relayStylePagination(['$id', '$where', '$order']),
          weedsByOrganisationId: relayStylePagination(['$id', '$where', '$order']),
          webhooksByOrganisationId: relayStylePagination(['$organisationId', '$where', '$order']),
          myJobs: relayStylePagination(['$where', '$order']),
        },
      },
      Weed: {
        fields: {
          jobs: relayStylePagination(),
        },
      },
      Device: {
        fields: {
          jobs: relayStylePagination(),
        },
      },
      User: {
        fields: {
          jobs: relayStylePagination(),
        },
      },
      Recipe: {
        fields: {
          jobs: relayStylePagination(),
        },
      },
      Chemical: {
        fields: {
          recipes: relayStylePagination(),
        },
      },
      Organisations: {
        fields: {
          recipes: relayStylePagination(),
        },
      },
    },
  }),
});

export default apolloClient;
