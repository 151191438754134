import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UnitMeasurement, UnitType } from '../../../../graphql/generated';
import { GetConvertUnit, GetUnitSymbol } from '../../../utilities/UnitConversionUtilities';
import BaseFormNumberInput from './BaseFormNumberInput';
import convert from 'convert-units';

type Props = {
  name: string;
  defaultUnit: UnitType;
  targetUnit?: UnitType;
  label?: string;
  placeholder?: string;
  required?: boolean;
  icon?: IconProp;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number | string | undefined;
  onChangeHandler?: (value: number) => void;
};

export default function FormUnitInput(props: Props) {
  const convertBound = (value?: number, measurement?: UnitMeasurement) => {
    if (!value) {
      return;
    }

    if (!props.targetUnit) {
      throw new Error('bound (min/max) specified, but target unit not!');
    }
    const unit = measurement?.unit ?? props.defaultUnit;
    return convert(value).from(GetConvertUnit(unit)).to(GetConvertUnit(props.targetUnit));
  };

  return BaseFormNumberInput<UnitMeasurement>({
    ...props,
    min: (x) => convertBound(props.min, x),
    max: (x) => convertBound(props.max, x),
    getDisplayValue: (x) => {
      if (!x) {
        return;
      }

      return x.value;
    },
    getFieldValue: (value, currValue) => {
      return {
        value: value,
        unit: currValue?.unit ?? props.defaultUnit,
      };
    },
    suffix: (x) => GetUnitSymbol(x?.unit ?? props.defaultUnit),
  });
}
