import { ApolloError } from '@apollo/client/errors';
import { faImage, faLeaf, faPencil, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Weed, useGetWeedQuery, Job } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import Thumbnail from '../../../shared/components/thumbnail/Thumbnail';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import WeedFormDialog from '../components/WeedFormDialog';
import DefaultThumbnail from '../../../shared/components/thumbnail/DefaultThumbnail';

export default function WeedPage() {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const currentOrganisation = useOrganisationId();
  const { weedId } = useParams();

  const {
    data: data,
    loading: loading,
    error: error,
    fetchMore,
  } = useGetWeedQuery({
    variables: {
      id: weedId as string,
    },
    returnPartialData: true,
  });

  const weed = data?.weed as Weed;

  useSetBreadcrumbs({
    loading: loading,
    entity: weed,
    params: {
      weedId: (x) => x.name,
    },
  });

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName="weed" apolloError={error as ApolloError} />;
  } else if (weed === null && !loading) {
    return <NotFoundPage />;
  }

  const rows: RSPivotRowProps[] = [
    {
      name: 'Name',
      value: weed.name,
      icon: faPencil,
    },
    {
      name: 'Species',
      value: weed.species,
      icon: faLeaf,
    },
    {
      name: 'Description',
      value: weed.description,
      icon: faStickyNote,
    },
    {
      name: 'Image',
      value: weed.imageUrl ? (
        <Thumbnail imageUrl={weed.imageUrl.split('?')[0]} />
      ) : (
        //default thumbnail
        <DefaultThumbnail className={''} />
      ),
      icon: faImage,
      notCopyable: true,
    },
  ];

  const jobCols: Array<RSTableColumnDefinition<Job>> = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Description',
      accessor: (x) => x.description,
      id: 'description',
    },
    {
      title: 'Start Time',
      accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
      id: 'startTime',
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <PermissionGuard policy={AuthPolicy.WeedManagement}>
              <RSButton variant="primary" onClick={() => setShowEdit(true)}>
                <FontAwesomeIcon className="pr-2" icon={faPencil} />
                Edit Weed
              </RSButton>
            </PermissionGuard>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        <GridTile colSpan={3} title={'Associated Jobs'}>
          <RSTable
            columns={jobCols}
            data={(data?.weed?.jobs?.edges?.map((e) => e.node) as unknown as Job[]) ?? []}
            totalCount={data?.weed?.jobs?.totalCount}
            onClickPathPrefix={`${localRoutingConstants.jobs.root}`}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.weed?.jobs?.pageInfo.endCursor as string) : undefined,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.WeedManagement} isVisible={showEdit}>
        <WeedFormDialog
          initialValues={weed}
          isOpen={true}
          onClose={() => setShowEdit(false)}
          currentOrganisation={currentOrganisation}
        />
      </PermissionGuard>
    </>
  );
}
