import moment from 'moment';
import { useNavigate } from 'react-router';
import { Job, useGetActiveJobsQuery } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';

interface ActiveJobsTable {
  organisationId: string;
}

export default function ActiveJobsTable(props: ActiveJobsTable) {
  const navigate = useNavigate();

  const { data, fetchMore } = useGetActiveJobsQuery({
    variables: {
      id: props.organisationId,
    },
  });

  const columns: RSTableColumnDefinition<Job>[] = [
    {
      id: 'name',
      title: 'Name',
      accessor: (x) => x.name,
    },
    {
      id: 'description',
      title: 'Description',
      accessor: (x) => x.description,
    },
    {
      id: 'startTime',
      title: 'Start Time',
      accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
    },
  ];

  return (
    <GridTile
      colSpan={3}
      title={'Active Jobs'}
      actions={
        <>
          <RSButton variant="secondary" onClick={() => navigate(localRoutingConstants.jobs.root)}>
            View All
          </RSButton>
        </>
      }
    >
      <RSTable
        onClickPathPrefix={`${localRoutingConstants.jobs.root}`}
        data={(data?.activeJobs?.edges?.map((e) => e.node) ?? []) as Job[]}
        totalCount={data?.activeJobs?.totalCount}
        columns={columns}
        fetchMore={async (_, pageSize, increment) => {
          await fetchMore({
            variables: {
              first: pageSize,
              after: increment ? (data?.activeJobs?.pageInfo.endCursor as string) : undefined,
            },
          });
        }}
      />
    </GridTile>
  );
}
