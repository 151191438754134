import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';

export interface Progress {
  loaded: number;
  total: number;
  complete: boolean;
}

interface FileUpload {
  uploadProgress: Progress | undefined;
  uploadFile: (video: File, preSignedURL: string, config?: AxiosRequestConfig) => Promise<void>;
}

interface UseUploadFileProps {
  onCompletion?: () => void;
  onError?: (message: string) => void;
}

export default function UseFileUpload(props?: UseUploadFileProps): FileUpload {
  const [uploadProgress, setUploadProgress] = useState<Progress>();

  const uploadFile = async (data: File, preSignedURL: string, config?: AxiosRequestConfig) => {
    try {
      if (preSignedURL !== undefined) {
        await axios.put(preSignedURL, data, {
          ...config,
          onUploadProgress: (prog) => {
            setUploadProgress({
              loaded: prog.loaded,
              total: prog.total,
              complete: prog.loaded == prog.total,
            });
          },
        });

        props?.onCompletion && props.onCompletion();
      }
    } catch (ex) {
      console.log(ex);
      props?.onError &&
        props?.onError('An error occurred whilst uploading the file, try again later or contact support.');
    }
  };

  return { uploadProgress, uploadFile };
}
