import PulseLoader from 'react-spinners/PulseLoader';

interface BubbleLoaderProps {
  className?: string;
}

export default function BubbleLoader(props: BubbleLoaderProps) {
  return (
    <div className={`flex flex-row justify-center h-full pt-20 ${props.className}`}>
      <PulseLoader speedMultiplier={0.5} color="#00788A" />
    </div>
  );
}
