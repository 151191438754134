import { NavigateFunction } from 'react-router';
import { Concentration } from '../../../graphql/generated';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';

interface ChemicalNameTagsProps {
  concentrations: Array<Concentration>;
  navigate?: NavigateFunction;
  jobId: string | null;
}

export default function ConcentrationTags({ concentrations, navigate, jobId }: ChemicalNameTagsProps) {
  return (
    <div className="flex flex-row gap-x-2 z-10 flex-wrap">
      {concentrations.map((value, index) => {
        return ConcentrationTag({ concentration: value, key: index, navigate: navigate, jobId: jobId });
      })}
    </div>
  );
}

interface ChemicalNameTagProps {
  concentration: Concentration;
  key?: React.Key;
  navigate?: NavigateFunction;
  jobId: string | null;
}

function ConcentrationTag(props: ChemicalNameTagProps): JSX.Element {
  const suffix = props.jobId ? `?jobId=${props.jobId}` : '';
  return (
    <RSTag
      key={props.key}
      title={`${props.concentration?.chemical.name} - ${formatNumber(
        props.concentration?.amount.value,
      )} ${GetUnitSymbol(props.concentration.amount.unit)}`}
      className={'bg-gray-300'}
      onClick={(event) => {
        event.stopPropagation();
        props.navigate &&
          props.navigate(`${localRoutingConstants.chemicals.root}/${props.concentration?.chemical.id}${suffix}`);
      }}
    />
  );
}

function formatNumber(num: number) {
  // Use toFixed(4) and parseFloat to remove unnecessary trailing zeros
  return parseFloat(num.toFixed(4)).toString();
}
