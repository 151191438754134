import create from 'zustand';
import { Organisation, UnitSystem } from '../../../graphql/generated';

type selectedOrganisationIdState = {
  selectedOrganisation: Organisation | undefined;
  unitSystem: UnitSystem;
  setSelectedOrganisation: (arg0: Organisation) => void;
  clearSelectedOrganisation: () => void;
};

const selectedOrganisationKey = 'selectedOrganisation';

const useSelectedOrganisationIdStore = create<selectedOrganisationIdState>((set) => {
  const storageOrg = localStorage.getItem(selectedOrganisationKey);
  const org = storageOrg ? (JSON.parse(storageOrg) as Organisation) : undefined;
  return {
    selectedOrganisation: org,
    setSelectedOrganisation: (newOrganisation: Organisation | undefined) => {
      set(() => ({ selectedOrganisation: newOrganisation, unitSystem: newOrganisation?.unitSystem }));
      localStorage.setItem(selectedOrganisationKey, JSON.stringify(newOrganisation));
    },
    clearSelectedOrganisation: () => {
      set(() => ({ selectedOrganisation: undefined, unitSystem: UnitSystem.Metric }));
      localStorage.removeItem(selectedOrganisationKey);
    },
    unitSystem: org?.unitSystem ?? UnitSystem.Metric,
  };
});

export default useSelectedOrganisationIdStore;
