interface WeedValueToButtonsPressedInterface {
  [key: number]: number[];
}

/**
 * Maps a weed value to a list of buttons that value represents as having been pressed
 */
export const weedValueToButtonsPressed: WeedValueToButtonsPressedInterface = {
  0: [],
  1: [1],
  2: [2],
  3: [1, 2],
  4: [3],
  5: [1, 3],
  6: [2, 3],
  7: [1, 2, 3],
};
