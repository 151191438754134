import {
  AccessorFn,
  ColumnDef,
  createColumnHelper,
  DeepKeys,
  flexRender,
  getCoreRowModel,
  PaginationState,
  Row,
  RowSelectionState,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RSStatelessCheckBox from '../../components/input/RSStatelessCheckbox';
import RSDropdownMenu, { RSDropdownMenuItem } from '../../components/RSDropdownMenu';
import { AuthPolicy } from '../../utilities/AuthPolicy';
import BubbleLoader from '../loading/BubbleLoader';
import PaginationOptions from './PaginationOptions';
import './RSTable.scss';
import RSTableRow from './RSTableRow';
export interface RSTableColumnDefinition<T> {
  title: string;
  id: string;
  accessor: AccessorFn<T, unknown> | DeepKeys<T>;
}

export interface BatchSelection {
  data: Array<string>;
  selectAll: boolean;
}

export interface BatchOperation<T> {
  action: (selection: BatchSelection) => void;
  title: string;
  permission: AuthPolicy;
  predicate?: (value: T) => boolean;
  predicateMessage?: string;
}

export interface RSTablePropBase<T> {
  data: Array<T>;
  columns: Array<RSTableColumnDefinition<T>>;
  onClickPathPrefix?: string;
  totalCount?: number;
  onClick?: (row: Row<T>) => void;
  fetchMore?: (pageIndex: number, pageSize: number, increment: boolean) => void;
  className?: string;
  isLoading?: boolean;
  toggleSelect?: boolean;
  isChecked?: boolean;
  batchOperationActions?: Array<BatchOperation<T>>;
  tableCallback?: (table: Table<T>) => void;
}
export interface RSTableProps<T> extends RSTablePropBase<T> {
  idAccessor?: AccessorFn<T, string>;
}

/**
 * TODO: Implement some way to pass in filters that can be applied - only one at a time.
 * Implement some way to pass in sorting - only one at a time.
 */
export default function RSTable<T extends object>(props: RSTableProps<T>) {
  const navigate = useNavigate();

  return (
    <RSTableBase<T>
      onClick={(row) => {
        const id = getId(row);

        if (!id) {
          return;
        }

        return navigate(`${props.onClickPathPrefix?.concat('/') ?? ''}${id}`);
      }}
      {...props}
    />
  );
}

function getId<T extends object>(row: Row<T>, idAccessor?: AccessorFn<T, string>) {
  const item = row.original as Record<'id', string | undefined> | T;
  let id: string | undefined;

  if ('id' in item) {
    id = item.id as string;
  } else if (idAccessor) {
    id = idAccessor(item, row.index);
  }

  return id;
}

export function RSTableBase<T extends object>(props: RSTablePropBase<T>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  //checkbox functionality
  const [allChecked, setAllChecked] = useState(false);
  const [individualCheck, setIndividualCheck] = useState(new Map<string, boolean>());
  const [state, updateState] = useState(false);

  const onGlobalCheck = () => {
    //if all are checked, we are unchecking, so clear all the individual
    //checks
    const newCheck = new Map<string, boolean>();

    //if we are now checking all, exclude jobs where the predicate fails, so that they aren't checked by a global check.
    if (!allChecked) {
      for (const val of table.getRowModel().rows) {
        if (props.batchOperationActions?.every((x) => (x.predicate ? x.predicate(val.original) : false))) {
          newCheck.set(getId(val) ?? '', false);
        }
      }
    }

    setIndividualCheck(newCheck);
    setAllChecked(!allChecked);
  };

  const onIndividualCheck = (id: string) => {
    //otherwise set the individual checkbox
    let val = individualCheck.get(id);

    //val could be undefined
    if (val == undefined) val = allChecked;

    individualCheck.set(id, !val);
    setIndividualCheck(individualCheck);
    updateState(!state);
  };

  function isChecked(id: string | undefined): boolean {
    if (!id) return false;

    const value = individualCheck.get(id);
    if (value == undefined) {
      return allChecked;
    }

    return value;
  }

  const columnHelper = createColumnHelper<T>();
  const columns = props.columns.map((col) => {
    return columnHelper.accessor(col.accessor, {
      id: col.id,
      cell: (info) => info.getValue(),
      header: col.title,
    });
  }) as ColumnDef<T>[];

  const numLoadedEntities = props.data.length;

  const isPaginated = props.fetchMore && props.totalCount;

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const offset = pageIndex * pageSize;

  const table = useReactTable({
    data: isPaginated ? props.data.slice(offset, offset + pageSize) : props.data,
    columns,
    pageCount: isPaginated ? (props.totalCount ? Math.ceil(props.totalCount / pageSize) : 1) : undefined,
    state: {
      pagination: isPaginated ? pagination : undefined,
      rowSelection: props.toggleSelect ? rowSelection : undefined,
    },
    onRowSelectionChange: props.toggleSelect ? setRowSelection : undefined,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const checkPredicate = () => {
    let update = false;
    for (const val of table.getRowModel().rows) {
      if (props.batchOperationActions?.every((x) => (x.predicate ? x.predicate(val.original) : false))) {
        if (isChecked(getId(val)) == true) {
          individualCheck.set(getId(val) ?? '', false);
          update = true;
        }
      }
    }

    if (update) {
      setIndividualCheck(individualCheck);
    }
  };
  checkPredicate();

  useEffect(() => props.tableCallback && props.tableCallback(table), [table]);

  const incrementPage = () => {
    table.nextPage();
    if ((pageIndex + 1) * pageSize > numLoadedEntities - 1)
      props.fetchMore && props.fetchMore(pageIndex, pageSize, true);
  };

  const decrementPage = () => {
    table.previousPage();
  };

  const setPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const size = Number(e.target.value);
    table.setPageSize(size);
    // Fetch more if the amount of entities to be loaded is greater than the amount of entities that are already loaded,
    // and if the total count of entities is greater than what is already loaded
    if ((pageIndex + 1) * size > numLoadedEntities && (props.totalCount as number) > numLoadedEntities)
      props.fetchMore && props.fetchMore(pageIndex, size, false);
  };

  const dropdownActions: RSDropdownMenuItem[] = !props.batchOperationActions
    ? []
    : props.batchOperationActions.map((x) => {
        return {
          title: x.title,
          permission: x.permission,
          disabled: !allChecked && Array.from(individualCheck.values()).every((value) => !value),
          action: () => {
            const jobs: string[] = [];
            for (const item of Array.from(individualCheck.keys())) {
              // Process each item here
              if (individualCheck.get(item) != allChecked) {
                jobs.push(item);
              }
            }

            x.action({
              selectAll: allChecked,
              data: jobs as Array<string>,
            });
          },
        };
      });

  return (
    <>
      <div className={`rs-table${props.className ? ` ${props.className}` : ''}`}>
        {props.isLoading ? (
          <BubbleLoader />
        ) : props.data.length > 0 ? (
          <table className="w-full">
            {/* Headers */}
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {props.isChecked && (
                    <th align="left" style={{ width: '50px' }} className="py-3 pl-2.5">
                      <RSStatelessCheckBox checked={allChecked} onChangeHandler={onGlobalCheck} />
                    </th>
                  )}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      align="left"
                      className="pl-2 py-3"
                      {...{
                        colSpan: header.colSpan,
                        style: {
                          width: header.getSize(),
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                  {props.isChecked && (
                    <th style={{ width: '50px' }}>
                      <RSDropdownMenu items={dropdownActions} />
                    </th>
                  )}
                </tr>
              ))}
            </thead>
            {/* Body */}
            <tbody className="rs-table-body">
              {table.getRowModel().rows.map((row) => {
                return (
                  <RSTableRow
                    key={row.id}
                    row={row}
                    disabled={props.batchOperationActions?.every((x) =>
                      x.predicate ? x.predicate(row.original) : false,
                    )}
                    disabledMessage={props.batchOperationActions?.find((x) => x.predicateMessage)?.predicateMessage}
                    onClick={props.onClick}
                    toggleSelect={props.toggleSelect}
                    setChecked={props.isChecked}
                    isChecked={isChecked(getId(row))}
                    onCheck={() => {
                      const id = getId(row);
                      if (!id) return;

                      onIndividualCheck(id);
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="h-full flex flex-row flex-grow justify-center items-center">None found.</div>
        )}
      </div>

      {isPaginated ? PaginationOptions<T>(table, setPageSize, decrementPage, incrementPage) : null}
    </>
  );
}
