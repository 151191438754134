import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { SortEnumType } from '../../../graphql/generated';
import { camelCaseToText } from '../../utilities/TextUtilities';
import DropDownMenu, { DropDownOption } from './DropDownMenu';

export interface SortSelection {
  fieldName: string;
  ascending: boolean;
}

interface JobSearchBarProps<TSortInput> {
  onChangeSearch: (value: string) => void;
  onChangeSort: (value: SortSelection) => void;
  searchString: string;
  // TODO add support for filtering nested fields?
  sortOptions: Array<Extract<keyof TSortInput, string>>;
  initialOption?: { [x: string]: SortEnumType } | undefined;
}

export default function SearchSortFilterBar<TSortInput>(props: JobSearchBarProps<TSortInput>) {
  const dropDownOptions = useMemo(() => {
    const dropDownOptions: Array<DropDownOption<SortSelection>> = [];
    props.sortOptions.forEach((x) => {
      const text = camelCaseToText(x);
      dropDownOptions.push({
        value: {
          fieldName: x,
          ascending: true,
        },
        displayValue: `${text} (ASC)`,
      });
      dropDownOptions.push({
        value: {
          fieldName: x,
          ascending: false,
        },
        displayValue: `${text} (DESC)`,
      });
    });
    return dropDownOptions;
  }, []);

  const initialOption = props.initialOption
    ? dropDownOptions.find((x) => {
        const firstEntry = Object.entries(props.initialOption ?? {})[0];
        if (firstEntry) {
          const [fieldName, sortEnum] = firstEntry;
          return x.value.fieldName === fieldName && x.value.ascending === (sortEnum === SortEnumType.Asc);
        }
        return false;
      })
    : undefined;

  return (
    <div className="py-2 px-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-3">
      <div className="rs-form-icon-container w-full items-center">
        <FontAwesomeIcon className="w-5 h-10" icon={faSearch} />
        <DebounceInput
          className="rs-form-input w-full bg-input-gray text-lg"
          onChange={(e) => props.onChangeSearch(e.target.value)}
          placeholder="Search"
          value={props.searchString}
          debounceTimeout={500}
        />
      </div>
      <div className="hidden lg:block" />
      <div className="flex flex-row items-center w-full">
        <label>Sort By</label>
        <DropDownMenu
          onChange={props.onChangeSort}
          className="w-full pl-3"
          options={dropDownOptions}
          initialSelection={initialOption}
        />
      </div>
    </div>
  );
}
