interface FormLabelProps {
  label?: string;
  required?: boolean;
}

export default function FormLabel({ label, required }: FormLabelProps) {
  if (label || required) {
    return (
      <label className="rs-label">
        <span>{label}</span>
        <span className="text-red-600">{required && '*'}</span>
      </label>
    );
  }
  return <></>;
}
