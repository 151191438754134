import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  FirmwareLockStatus,
  Organisation,
  OrganisationFilterInput,
  OrganisationSortInput,
  useAdminGetOrganisationsQuery,
} from '../../../graphql/generated';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import { RSTag } from '../../../shared/components/tags/RSTag';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { BatchOperation, BatchSelection, RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import { enabledToTailwindClassName, enabledToText } from '../../../shared/utilities/EnabledValueUtilities';
import BatchOrganisationFirmwareFormDialogue from '../components/BatchOrganisationFirmwareFormDialog';
import OrganisationFormDialog from '../components/OrganisationFormDialog';

export default function OrganisationManagementPage() {
  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<OrganisationFilterInput>({
    searchableFieldNames: ['name', 'description'],
  });

  const { data, loading, fetchMore } = useAdminGetOrganisationsQuery({
    variables: {
      where: where,
      order: order,
    },
  });

  const [batchSelection, setBatchSelection] = useState<BatchSelection | null>(null);
  const [showFirmwareUpdate, setShowFirmwareUpdate] = useState<boolean>(false);

  const [showAdd, setShowAdd] = useState<boolean>(false);

  const cols = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
    {
      title: 'Users',
      id: 'users',
      accessor: (x) => x.users.length,
    },
    {
      title: 'Devices',
      id: 'devices',
      accessor: (x) => x.devices.length,
    },
    {
      title: 'Enabled',
      id: 'enabled',
      accessor: (x) => <RSTag title={enabledToText(x.enabled)} className={enabledToTailwindClassName(x.enabled)} />,
    },
  ] as Array<RSTableColumnDefinition<Organisation>>;

  const batchOperationActions: BatchOperation<Organisation>[] = [
    {
      title: 'Update Firmware',
      permission: AuthPolicy.OrganisationManagement,
      action: (selection) => {
        setBatchSelection(selection);
        setShowFirmwareUpdate(true);
      },
      predicate: (organisation) => {
        if (!organisation.organisationFirmware) {
          return false;
        }

        return organisation.organisationFirmware.lockStatus == FirmwareLockStatus.Locked;
      },
      predicateMessage: `Organisation Firmware version is locked`,
    },
  ];

  return (
    <>
      {/* Organisation Table */}
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Organisations'}
          actions={
            <RSButton onClick={() => setShowAdd(true)}>
              <FontAwesomeIcon icon={faPlus} />
              Add
            </RSButton>
          }
        >
          <SearchSortFilterBar<OrganisationSortInput>
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            searchString={searchString}
            sortOptions={['name', 'description']}
          />
          <RSTable<Organisation>
            isLoading={loading}
            isChecked={true}
            batchOperationActions={batchOperationActions}
            columns={cols}
            data={(data?.organisations?.edges?.map((e) => e.node) as Organisation[]) ?? []}
            totalCount={data?.organisations?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.organisations?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      {/* Add User Modal */}
      <OrganisationFormDialog isOpen={showAdd} onClose={() => setShowAdd(false)} />
      {batchSelection && (
        <BatchOrganisationFirmwareFormDialogue
          isOpen={showFirmwareUpdate}
          onClose={() => {
            setShowFirmwareUpdate(false);
          }}
          selection={batchSelection}
        />
      )}
    </>
  );
}
