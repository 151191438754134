import { faEnvelope, faIdCard, faLandmark, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik, FormikValues } from 'formik';
import { toast } from 'react-toastify';
import { AnyObject } from 'yup/lib/object';
import {
  Organisation,
  OrganisationFilterInput,
  OrganisationSearchQuery,
  OrganisationSearchQueryVariables,
  useOrganisationSearchQuery,
} from '../../../graphql/generated';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { MaximumNameLength } from '../../../shared/constants/ValidationConstants';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { ServerErrorCode, getErrorType } from '../../../shared/utilities/ApolloErrorUtils';
import { getUserRoleOptions, globalRoles, userRolesToDropdownItems } from '../utilities/UserRoleUtilities';

type Props<T extends FormikValues> = {
  initialValues?: T;
  isOpen: boolean;
  validator: AnyObject;
  onClose: () => void;
  onSubmit: (values: T) => Promise<boolean>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
  isOrganisational: boolean;
  isSuperAdmin: boolean;
  isCurrentUser: boolean;
};

// Abstracted user form allowing for the same structure to be used for editing and creating a user
export default function UserFormContent<T extends FormikValues>({
  isOrganisational,
  isEdit,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
  isSuperAdmin,
  isCurrentUser,
}: Props<T>) {
  const userRole = initialValues?.role;
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  return (
    <Formik<T>
      initialValues={initialValues ?? (validator.cast({}) as T)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values) => {
        try {
          await onSubmit(values);
        } catch (ex) {
          console.log(ex);
          if (getErrorType(ex) === ServerErrorCode.RESOURCE_ALREADY_EXISTS) {
            toast(
              `The request could not be fulfilled because a user with the requested email address (${values.email}) already exists!`,
              {
                type: 'error',
              },
            );
          } else {
            toast(
              `An error occurred whilst trying to ${
                initialValues ? 'update' : 'create'
              } the user, please try again in two minutes or contact Rapid Spray support.`,
              {
                type: 'error',
              },
            );
          }
        }
        onClose();
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title={isEdit ? 'Edit User' : 'Invite User'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={() => {
                    formik.resetForm();
                    onClose();
                  }}
                />
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  {isEdit ? 'Update' : 'Add'}
                </RSButton>
              </>
            }
          >
            <Form>
              <FormTextInput
                required
                label="First Name"
                name="firstName"
                icon={faPencil}
                placeholder="First name of user"
                maxLength={MaximumNameLength}
              />
              <FormTextInput
                required
                label="Last Name"
                name="lastName"
                icon={faPencil}
                placeholder="Last name of user"
                maxLength={MaximumNameLength}
              />
              {!isEdit && (
                // Only display the email field when creating as it can't be updated
                <FormTextInput required label="Email" name="email" icon={faEnvelope} placeholder="User Email" />
              )}
              {!isCurrentUser && (
                <FormSelectInput
                  required
                  label="Role"
                  name="role"
                  icon={faIdCard}
                  options={userRolesToDropdownItems(
                    getUserRoleOptions(isSuperAdmin, isEdit, userRole, isOrganisational),
                  )}
                />
              )}
              {
                // Conditionally render organisation id field based off of role set
                isSuperAdmin && !isEdit && !globalRoles.includes(formik.values.role) && (
                  <FormComboBoxWithQuery<
                    Organisation,
                    OrganisationSearchQuery,
                    OrganisationSearchQueryVariables,
                    OrganisationFilterInput
                  >
                    placeholder="Organisation"
                    label="Organisation"
                    required
                    icon={<FontAwesomeIcon icon={faLandmark} />}
                    name="organisationId"
                    dataAccessorCallback={(data) =>
                      data?.organisations?.nodes?.map((org) => ({
                        value: org.id,
                        displayValue: org.name,
                      }))
                    }
                    searchFieldNames={['name']}
                    query={useOrganisationSearchQuery}
                  />
                )
              }
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
