import { toast } from 'react-toastify';
import { chemicalValidator, chemicalUpdateValidator } from '../validation/ChemicalValidator';
import {
  CreateChemicalInput,
  Chemical,
  UpdateChemicalInput,
  useCreateChemicalMutation,
  useUpdateChemicalMutation,
  GetChemicalsByOrganisationIdDocument,
} from '../../../graphql/generated';
import ChemicalFormContent from './ChemicalFormContent';
import useCreateNavigate from '../../../shared/components/UseCreateNavigate';

type Props = {
  currentOrganisation: string;
  initialValues?: Chemical;
  isOpen: boolean;
  onClose: () => void;
};

export default function ChemicalFormDialog({ currentOrganisation, initialValues, isOpen, onClose }: Props) {
  const [createChemical] = useCreateChemicalMutation();
  const [updateChemical] = useUpdateChemicalMutation();
  const navigateToChemical = useCreateNavigate();
  if (initialValues) {
    return (
      <ChemicalFormContent<UpdateChemicalInput>
        isEdit
        initialValues={
          chemicalUpdateValidator.cast(
            { ...initialValues, chemicalId: initialValues.id },
            { stripUnknown: true },
          ) as UpdateChemicalInput
        }
        validator={chemicalUpdateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await updateChemical({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A chemical with name - ${data.updateChemical.name} has been successfully updated!`, {
                type: 'success',
              });
            },
          });
        }}
      />
    );
  } else {
    return (
      <ChemicalFormContent<CreateChemicalInput>
        initialValues={
          chemicalValidator.cast({
            organisationId: currentOrganisation,
          }) as CreateChemicalInput
        }
        validator={chemicalValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await createChemical({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A new chemical with name - ${data.createChemical.name} has been successfully created!`, {
                type: 'success',
              });
              navigateToChemical(data.createChemical.id);
            },
            refetchQueries: [GetChemicalsByOrganisationIdDocument],
          });
        }}
      />
    );
  }
}
