import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Device,
  DeviceFilterInput,
  DeviceSortInput,
  useGetDevicesByOrganisationIdQuery,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import DeviceActivationFormDialog from '../components/DeviceActivationFormDialog';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';

export default function DevicesPage() {
  const currentOrganisation = useOrganisationId();
  const [showActivate, setShowActivate] = useState<boolean>(false);

  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<DeviceFilterInput>({
    searchableFieldNames: ['alias', 'id'],
  });

  const { data, loading, fetchMore } = useGetDevicesByOrganisationIdQuery({
    variables: {
      id: currentOrganisation as string,
      where: where,
      order: order,
    },
  });

  const deviceTableColumns = [
    {
      title: 'ID',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.id,
      id: 'id,',
    },
    {
      title: 'Alias',
      accessor: (x) => x.alias,
      id: 'alias',
    },
    {
      title: 'Activation Date',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => (x.activationDate ? new Date(x.activationDate).toLocaleString() : 'N/A'),
      id: 'activationDate',
    },
  ] as Array<RSTableColumnDefinition<Device>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Devices'}
          actions={
            <PermissionGuard policy={AuthPolicy.DeviceManagement}>
              <RSButton onClick={() => setShowActivate(true)}>
                <FontAwesomeIcon icon={faPlus} />
                Activate Device
              </RSButton>
            </PermissionGuard>
          }
        >
          <SearchSortFilterBar<DeviceSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['alias', 'id', 'registrationDate']}
          />
          <RSTable<Device>
            isLoading={loading}
            columns={deviceTableColumns}
            data={(data?.devicesByOrganisationId?.edges?.map((e) => e.node) as Device[]) ?? []}
            totalCount={data?.devicesByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.devicesByOrganisationId?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.DeviceManagement} isVisible={showActivate}>
        <DeviceActivationFormDialog isOpen={true} onClose={() => setShowActivate(false)} isOrganisational={true} />
      </PermissionGuard>
    </>
  );
}
