import { toast } from 'react-toastify';
import {
  AdminGetAllFirmwareDocument,
  CreateFirmwareOutput,
  useCreateFirmwareMutation,
} from '../../../graphql/generated';

import { firmwareValidator } from '../pages/FirmwareValidator';
import CreateFirmwareFormContent from './CreateFirmwareFormContent';
import useCreateNavigate from '../../../shared/components/UseCreateNavigate';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function CreateFirmwareFormDialog({ isOpen, onClose }: Props) {
  const [createFirmware] = useCreateFirmwareMutation();
  const navigateToFirmware = useCreateNavigate();

  return (
    <CreateFirmwareFormContent
      validator={firmwareValidator}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={async (value) => {
        return (
          await createFirmware({
            variables: {
              input: {
                description: value.description,
                version: value.version,
              },
            },
            onCompleted(data) {
              toast(`A new firmware with version - ${data.createFirmware.version} has been succesfully created.`, {
                type: 'success',
              });
              //Navigates to created firmwares page
              navigateToFirmware(data.createFirmware.id);
            },
            refetchQueries: [AdminGetAllFirmwareDocument],
          })
        ).data?.createFirmware as CreateFirmwareOutput;
      }}
    />
  );
}
