import { useMemo, useState } from 'react';
import { SortEnumType } from '../../graphql/generated';
import { SortSelection } from '../components/input/SearchSortFilterBar';

// Types to get typed strings for searchable fields
type ExcludedFieldNames =
  | 'and'
  | 'or'
  | 'eq'
  | 'neq'
  | 'startsWith'
  | 'endsWith'
  | 'contains'
  | 'ncontains'
  | 'in'
  | 'nendsWith'
  | 'nin'
  | 'nstartsWith';
export type Leaves<T, D extends number = 10, E extends string = ''> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof Omit<T, E>]-?: Join<K, Leaves<T[K], Prev[D], E>> }[keyof Omit<T, E>]
  : '';
type Join<K, P> = K extends string ? (P extends string ? `${K}${'' extends P ? '' : '.'}${P}` : never) : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

export type SearchFieldNames<TFilterInput> = Array<Leaves<TFilterInput, 2, ExcludedFieldNames> & string>;

interface UseSearchAndSortProps<TFilterInput> {
  initialSearch?: string;
  initialSort?: SortSelection;
  searchableFieldNames: SearchFieldNames<TFilterInput>;
}

export default function useSearchSortFilter<TFilterInput>(props: UseSearchAndSortProps<TFilterInput>) {
  const [sorter, setSort] = useState<SortSelection | undefined>(props.initialSort);
  const [searchString, setSearchString] = useState<string>(props.initialSearch ?? '');

  // TODO determine whether or not it's possible to optimise by generating a JSON string based on searchableFieldNames
  // and interpolate the search key value when it changes
  const where = useMemo(() => {
    // If the user has not searched for anything don't return a where
    if (searchString === '' || props.searchableFieldNames.length == 0) {
      return undefined;
    }

    // If there's only one searchable field don't nest in 'or'
    if (props.searchableFieldNames.length == 1) {
      return {
        [props.searchableFieldNames[0]]: {
          contains: searchString,
        },
      };
    }
    return {
      or: props.searchableFieldNames.map((field) => {
        // If the field is nested it will have a '.' in the name
        const separator = field.indexOf('.');
        if (separator == -1) {
          return {
            [field]: {
              contains: searchString,
            },
          };
        }
        // Nest a nested field
        // TODO add support for having multiple nested fields in the same field or does this already support it?
        else {
          return {
            [field.substring(0, separator)]: {
              [field.substring(separator + 1)]: {
                contains: searchString,
              },
            },
          };
        }
      }),
    };
  }, [searchString, props.searchableFieldNames]);

  const order = useMemo(() => {
    if (!sorter) {
      return undefined;
    }

    return {
      [sorter.fieldName]: sorter.ascending ? SortEnumType.Asc : SortEnumType.Desc,
    };
  }, [sorter]);

  return {
    searchString,
    where,
    setSearchString,
    order,
    setSort,
  };
}
