import env from '@beam-australia/react-env';
import { faBook, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
  Webhook,
  WebhookFilterInput,
  WebhookSortInput,
  useWebhooksByOrganisationIdQuery,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import { CreateWebhookDialog } from '../components/CreateWebhookDialog';
import { WebhookInfrastructureStatusTag } from '../components/WebhookInfrastructureStatusTag';
import { WebhookStatusDefinitions } from '../WebhookStatusUtils';

export function WebhooksPage() {
  const [showCreateWebhook, setShowCreateWebhook] = useState<boolean>(false);

  const {
    searchString: searchString,
    where: where,
    setSearchString: setSearchString,
    order: order,
    setSort: setSort,
  } = useSearchSortFilter<WebhookFilterInput>({
    searchableFieldNames: ['name', 'status', 'url', 'description'],
  });

  const organisationId = useOrganisationId();

  const {
    data: data,
    loading: loading,
    fetchMore: fetchMore,
    startPolling,
    stopPolling,
  } = useWebhooksByOrganisationIdQuery({
    variables: {
      organisationId: organisationId,
      where: where,
      order: order,
    },
  });

  useEffect(() => {
    startPolling(10000);
    return () => {
      stopPolling();
    };
  });

  const cols = [
    {
      title: 'Name',
      id: 'name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: ({ name }) => name,
    },
    {
      title: 'State',
      id: 'state',
      accessor: ({ infrastructureStatus }) => <WebhookInfrastructureStatusTag status={infrastructureStatus} />,
    },
    {
      title: 'Status',
      id: 'status',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: ({ status }) => WebhookStatusDefinitions[status].displayName,
    },
    {
      title: 'Description',
      id: 'description',
      accessor: ({ description }) => {
        if (!description) {
          return '';
        }

        return description;
      },
    },
    {
      title: 'Url',
      id: 'url',
      accessor: ({ url }) => <a href={url}>{url}</a>,
    },
  ] as Array<RSTableColumnDefinition<Webhook>>;

  const maxWebhookCount = parseInt(env('MAX_WEBHOOK_INSTANCE'));
  const webhookCount = data?.webhooksByOrganisationId?.totalCount;
  const shouldCreateWebhook = !!webhookCount && webhookCount < maxWebhookCount ? 'primary' : 'disabled';

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Webhooks'}
          actions={
            <>
              <a href={env('DOCS_URL')} target="_blank" rel="noreferrer">
                <RSButton>
                  <FontAwesomeIcon icon={faBook} />
                  Docs
                </RSButton>
              </a>
              <PermissionGuard
                policy={AuthPolicy.WebhookCreate}
                deps={[maxWebhookCount, webhookCount, shouldCreateWebhook]}
              >
                <RSButton
                  onClick={() => setShowCreateWebhook(true)}
                  variant={shouldCreateWebhook ? 'primary' : 'disabled'}
                  title={shouldCreateWebhook ? '' : `You cannot create more than ${maxWebhookCount} webhooks`}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Create Webhook
                </RSButton>
              </PermissionGuard>
            </>
          }
        >
          <SearchSortFilterBar<WebhookSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['name', 'url']}
          />
          <RSTable<Webhook>
            isLoading={loading}
            columns={cols}
            data={(data?.webhooksByOrganisationId?.edges?.map((e) => e.node) as Webhook[]) ?? []}
            totalCount={data?.webhooksByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.webhooksByOrganisationId?.pageInfo.endCursor as string) : undefined,
                  order: order,
                  where: where,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.WebhookCreate} isVisible={showCreateWebhook}>
        <CreateWebhookDialog isOpen={true} onClose={() => setShowCreateWebhook(false)} />
      </PermissionGuard>
    </>
  );
}
