export const localRoutingConstants = {
  auth: { login: '/login' },
  business: { root: '/business' },
  manageUsers: {
    root: '/manage-users',
    viewUser: '/manage-users/:userId',
  },
  manageDevices: {
    root: '/manage-devices',
    viewDevice: '/manage-devices/:deviceId',
  },
  devices: {
    root: '/devices',
    viewDevice: '/devices/:deviceId',
  },
  organisations: {
    root: '/organisations',
    viewOrganisation: '/organisations/:organisationId',
  },
  users: {
    root: '/users',
    viewUser: '/users/:userId',
  },
  manufacturing: {
    root: '/manufacturing',
    viewDevice: '/manufacturing/:deviceId',
  },
  recipes: {
    root: '/recipes',
    viewRecipe: '/recipes/:recipeId',
  },
  chemicals: {
    root: '/chemicals',
    viewChemical: '/chemicals/:chemicalId',
  },
  weeds: {
    root: '/weeds',
    viewWeed: '/weeds/:weedId',
  },
  jobs: {
    root: '/jobs',
    viewJob: '/jobs/:jobId',
  },
  myJobs: {
    root: '/my-jobs',
    viewUserJob: '/my-jobs/:jobId',
  },
  sharedJobs: {
    root: '/jobs/shared',
    viewSharedJob: '/jobs/shared/:jobId',
  },
  weedMap: '/weed-map',
  firmware: {
    root: '/firmware',
    viewFirmware: '/firmware/:firmwareId',
  },
  webhooks: {
    root: '/webhooks',
    viewWebhook: '/webhooks/:webhookId',
  },
};
