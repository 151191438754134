import { useMemo } from 'react';
import WebGLLayer from '../../../../shared/components/maps/Map/Layers/WebGLLayer';
import { WeedLayerPair } from './JobMap';

interface WeedLayersProps {
  weeds: Array<WeedLayerPair>;
}

export default function WeedLayers({ weeds }: WeedLayersProps) {
  return useMemo(
    () => (
      <>
        {weeds.map((weed, index) => {
          return <WebGLLayer key={`deviceAssignmentLayer.${index}`} layer={weed.layer} />;
        })}
      </>
    ),
    [weeds],
  );
}
