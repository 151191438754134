import { useContext, useEffect } from 'react';
import { Zoom } from 'ol/control';
import MapContext from '../MapContext';
import { Map } from 'ol';
import { Extent } from 'ol/extent';

export default function ZoomControl() {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const zoomControl = new Zoom({});

    map.addControl(zoomControl);

    return () => {
      map.removeControl(zoomControl);
    };
  }, [map]);

  return null;
}

export function calculateInitialZoom(map: Map | undefined, defZoom: number, extentFunc: () => Extent) {
  // Constants
  const zoomPadding = 1 + 1 / Math.E;
  if (!map) return defZoom;

  const zoom = map.getView().getZoomForResolution(map.getView().getResolutionForExtent(extentFunc()) * zoomPadding);
  if (!zoom) return defZoom;

  return Math.floor(zoom);
}
