import ReactECharts from 'echarts-for-react';
import { Formik } from 'formik';
import moment from 'moment';
import { useMemo } from 'react';
import { Chemical, Recipe, UnitMeasurement, useGetRecipeUsageQuery } from '../../../graphql/generated';
import FormDateInput from '../../../shared/components/forms/form-date-input/FormDateInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import GridTile from '../../../shared/layout/tiles/GridTile';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';
import {
  CalculateAmountUsed,
  concentrationUnitUsageForChemicalType,
  ConcentrationYAxis,
} from '../utilities/ConcentrationUtilities';

interface RecipeUsageGraphProps {
  recipe: Recipe;
}

interface FormValues {
  monthOf: Date;
}

export default function RecipeUsageGraph({ recipe }: RecipeUsageGraphProps) {
  const monthOf = useMemo(() => {
    // if it has been deleted cap/set the usage search to when it was deleted
    return recipe.deleteInformation?.deletedAt ? moment(recipe.deleteInformation?.deletedAt) : moment();
  }, []);
  const { data, refetch } = useGetRecipeUsageQuery({
    variables: {
      input: {
        recipeId: recipe.id as string,
        monthOf: monthOf,
      },
    },
  });

  const { unitSystem } = useSelectedOrganisationIdStore();

  const { dates, series, chemicals } = useMemo(() => {
    const dates: Array<string> = [];
    const unitsPerDay: Array<UnitMeasurement> = [];
    const chemicals: Chemical[] = [];

    // Get the dates to display
    data?.recipeUsage?.usage?.forEach((e) => {
      if (e.value.value == 0) return;
      dates.push(moment(e.key).format('ddd D/MM/y'));
      unitsPerDay.push(e.value);
    });

    // Get the data for each chemical in the recipe to display
    //TODO - This is actually incorrect, one should used the concentration stored on the job.
    const series: echarts.BarSeriesOption[] = recipe.concentrations.map((concentration) => {
      const seriesData: Array<number> = [];
      chemicals.push(concentration.chemical);
      unitsPerDay.forEach((volume) => {
        seriesData.push(CalculateAmountUsed(volume, concentration.amount) ?? 0);
      });

      return {
        name: concentration.chemical.name,
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: (ctx) => {
            const val = ctx.value as number;
            return val !== 0
              ? `${val.toFixed(2)} ${GetUnitSymbol(
                  concentrationUnitUsageForChemicalType[concentration.chemical.type][unitSystem],
                )}`
              : '';
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: seriesData,
      } as echarts.BarSeriesOption;
    });
    return { dates, series, chemicals };
  }, [data]);

  const options: echarts.EChartsOption = {
    tooltip: {
      show: true,
      formatter: (params) => {
        const seriesIndex = (params as { seriesIndex: number }).seriesIndex;
        const total = ((series[seriesIndex]?.data ?? []) as number[]).reduce((p, c) => (p += c), 0);
        const chemical = chemicals[seriesIndex];
        return `Total: ${total.toFixed(2)} ${GetUnitSymbol(
          concentrationUnitUsageForChemicalType[chemical.type][unitSystem],
        )}`;
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: [
      {
        type: 'value',
        name: `${ConcentrationYAxis(unitSystem)}`,
        nameRotate: 90,
        nameLocation: 'middle',
      },
    ],
    xAxis: {
      type: 'category',
      data: dates,
    },
    series: series,
  };

  return (
    <GridTile
      className="col-span-3 md:col-span-6"
      title={'Usage'}
      actions={
        <>
          <Formik<FormValues>
            initialValues={{
              monthOf: monthOf.toDate(),
            }}
            onSubmit={function (values: FormValues) {
              refetch({
                input: {
                  recipeId: recipe.id,
                  monthOf: values.monthOf,
                },
              });
            }}
          >
            {(formik) => {
              return (
                <>
                  <FormDateInput
                    name={'monthOf'}
                    label={'Month Of'}
                    inLineLabel
                    notLocal
                    max={monthOf.format('yyy-MM-DD')}
                  />
                  <RSButton
                    variant="primary"
                    onClick={async () => {
                      await formik.submitForm();
                    }}
                  >
                    Search
                  </RSButton>
                </>
              );
            }}
          </Formik>
        </>
      }
    >
      <ReactECharts option={options} />
    </GridTile>
  );
}
