type ColumnHeaders<T> = Partial<{
  [x in keyof T & string]: string;
}>;

interface CreateCSVProps<T> {
  columns?: ColumnHeaders<T>;
  showHeaders?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class CSVGenerator<T extends { [x: string]: any }> {
  rows: Array<string> = [];
  columns?: ColumnHeaders<T>;
  showHeaders: boolean;

  constructor({ columns, showHeaders }: CreateCSVProps<T>) {
    this.showHeaders = showHeaders ?? true;

    // If columns were provided and headers should be shown create the headers
    if (columns && Object.keys(columns).length > 0 && this.showHeaders) {
      this.columns = columns;
      this.rows.push(this.sanitise(Object.values(columns).map((e) => `${e}`)).join(','));
    }
  }

  addRow(item: T): void {
    // If columns aren't provided and headers are shown get the headers from the first item
    if (this.rows.length == 0 && !this.columns && this.showHeaders) {
      this.rows.push(Object.keys(item).join(','));

      // create the columns
      this.columns = {};
      for (const key in item) {
        this.columns[key] = key;
      }
    }

    const row: string[] = [];

    for (const key in this.columns) {
      row.push(`${item[key]}`);
    }

    this.rows.push(this.sanitise(row).join(','));
  }

  private sanitise(items: Array<string>) {
    return items.map(this.sanitiseString);
  }

  private sanitiseString(item: string) {
    //Replace illegal characters
    let string = item.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ');

    // Escape commas and quotes
    if (string.includes(',')) {
      if (string.includes('"')) {
        string = string.replace(/"/g, '""');
      }
      string = `"${string}"`;
    }

    return string;
  }

  toString() {
    return this.rows.join('\n');
  }
}
