import { toast } from 'react-toastify';
import {
  ActivateDeviceInput,
  GetDevicesByOrganisationIdDocument,
  useActivateDeviceMutation,
} from '../../../graphql/generated';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { deviceActivationValidator } from '../validation/DeviceActivationValidator';
import DeviceActivationFormContent from './DeviceActivationFormContent';
import useCreateNavigate from '../../../shared/components/UseCreateNavigate';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isOrganisational: boolean;
};

export default function DeviceActivationFormDialog({ isOrganisational, isOpen, onClose }: Props) {
  const [activateDevice] = useActivateDeviceMutation();
  const currentOrganisation = useOrganisationId();
  const navigateToDevice = useCreateNavigate();

  return (
    <DeviceActivationFormContent<ActivateDeviceInput>
      initialValues={
        deviceActivationValidator.cast({
          organisationId: currentOrganisation,
        }) as ActivateDeviceInput
      }
      isOrganisational={isOrganisational}
      validator={deviceActivationValidator}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={async (value) => {
        await activateDevice({
          variables: {
            input: value,
          },
          onCompleted(data) {
            toast(`A device with id - ${data.activateDevice.id} has been successfully activated!`, {
              type: 'success',
            });
            navigateToDevice(data.activateDevice.id);
          },
          refetchQueries: [GetDevicesByOrganisationIdDocument],
        });
      }}
    />
  );
}
