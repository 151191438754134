import { useField } from 'formik';
import {
  Firmware,
  FirmwareFilterInput,
  FirmwareSearchQuery,
  FirmwareSearchQueryVariables,
  useFirmwareSearchQuery,
} from '../../../graphql/generated';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import { ComboBoxItem } from '../../../shared/components/input/combo-box/ComboBox';

type FirmwareComboboxInputType = {
  label: string;
  name: string;
  initialValue?: ComboBoxItem;
};

export default function FirmwareComboboxInput({ name, label, initialValue }: FirmwareComboboxInputType) {
  const [_, meta, helpers] = useField({
    name: name,
  });

  return (
    <FormComboBoxWithQuery<Firmware, FirmwareSearchQuery, FirmwareSearchQueryVariables, FirmwareFilterInput>
      initialValue={{
        value: initialValue?.value ?? '',
        displayValue: initialValue?.displayValue ?? '',
      }}
      label="Firmware"
      name={`firmwareId`}
      dataAccessorCallback={(data) =>
        data.enabledFirmware?.nodes?.map((firmware) => ({
          displayValue: firmware.version,
          value: firmware.id,
        })) ?? []
      }
      searchFieldNames={['version']}
      query={useFirmwareSearchQuery}
    />
  );
}
