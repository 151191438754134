import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Device,
  DeviceFilterInput,
  DeviceSortInput,
  Organisation,
  useAdminGetDevicesQuery,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import OrganisationTag from '../../../shared/components/tags/OrganisationTag';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import DeviceActivationFormDialog from '../components/DeviceActivationFormDialog';

export default function DeviceManagementPage() {
  const [showActivate, setShowActivate] = useState<boolean>(false);
  const navigate = useNavigate();

  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<DeviceFilterInput>({
    searchableFieldNames: ['alias', 'id'],
  });

  const { data, loading, fetchMore } = useAdminGetDevicesQuery({
    variables: {
      where: where,
      order: order,
    },
  });

  const columns = [
    {
      title: 'ID',
      accessor: (x) => x.id,
      id: 'id,',
    },
    {
      title: 'Alias',
      accessor: (x) => x.alias,
      id: 'alias',
    },
    {
      title: 'Organisation',
      accessor: (x) => <OrganisationTag organisation={x.organisation as Organisation} navigate={navigate} />,
      id: 'organisation',
    },
    {
      title: 'Registration Date',
      accessor: (x) => new Date(x.registrationDate).toLocaleString(),
      id: 'registrationDate',
    },

    {
      title: 'Activation Date',
      accessor: (x) => (x.activationDate ? new Date(x.activationDate).toLocaleString() : 'N/A'),
      id: 'activationDate',
    },
  ] as Array<RSTableColumnDefinition<Device>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Devices'}
          actions={
            <RSButton onClick={() => setShowActivate(true)}>
              <FontAwesomeIcon icon={faPlus} />
              Activate Device
            </RSButton>
          }
        >
          <SearchSortFilterBar<DeviceSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['alias', 'id', 'registrationDate']}
          />
          <RSTable<Device>
            isLoading={loading}
            columns={columns}
            data={(data?.devices?.edges?.map((e) => e.node) as Device[]) ?? []}
            totalCount={data?.devices?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.devices?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <DeviceActivationFormDialog
        isOpen={showActivate}
        onClose={() => setShowActivate(false)}
        isOrganisational={false}
      />
    </>
  );
}
