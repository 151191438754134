import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { Job, JobsByOrganisationIdDocument, useDeleteJobMutation } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { useNavigate } from 'react-router-dom';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';

interface DeleteJobDialogProps {
  currentOrganisation: string;
  job: Job;
  isOpen: boolean;
  onClose: VoidFunction;
}

export default function DeleteJobDialog({ currentOrganisation, job, isOpen, onClose }: DeleteJobDialogProps) {
  const [deleteJob] = useDeleteJobMutation();
  const navigate = useNavigate();

  const errors: string[] = useMemo(() => {
    const errors: string[] = [];
    if (job.deviceAssignments.length > 0) {
      errors.push('The job contains device assignments');
    }
    return errors;
  }, [job]);

  return (
    <RSConfirmationDialog
      isOpen={isOpen}
      title={'Delete Job?'}
      type={errors.length == 0 ? 'primary' : 'disabled'}
      confirmTitle={'Delete'}
      onConfirm={async () => {
        await deleteJob({
          variables: {
            input: {
              id: job.id,
            },
          },
          onCompleted() {
            toast(`${job.name} has been successfully deleted!`, {
              type: 'success',
            });
            onClose();
            navigate(localRoutingConstants.jobs.root);
          },
          onError(error) {
            toast(`Error: ${error.message}`, {
              type: 'error',
            });
          },
          refetchQueries: [{ query: JobsByOrganisationIdDocument, variables: { id: currentOrganisation } }],
        });
      }}
      closeTitle="Close"
      onClose={onClose}
    >
      <>
        <span>Deleting the job is an irreversible action, all input data will be lost.</span>
        {errors.length > 0 && (
          <>
            <br />
            <br />
            The following item(s) prevent this job from being deleted:
            <ul>
              {errors.map((error, index) => {
                return (
                  <li key={`error.${index}`} className="mx-5 list-disc">
                    {error}
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </>
    </RSConfirmationDialog>
  );
}
