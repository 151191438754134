import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import { CreateWebhookResponse } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';

type PostWebhookCreationCsvExportButtonProps = {
  response: CreateWebhookResponse;
};

export function PostWebhookCreationCsvExportButton({ response }: PostWebhookCreationCsvExportButtonProps) {
  const csvData = [
    ['id', 'api_key_header_name', 'api_key_value'],
    [response.webhook.id, response.apiKeyHeader, response.apiKey],
  ];

  return (
    <RSButton variant="primary">
      <FontAwesomeIcon icon={faDownload} />
      <span>
        <CSVLink filename={'export_webhook_' + response.webhook.id} data={csvData}>
          Export to CSV
        </CSVLink>
      </span>
    </RSButton>
  );
}
