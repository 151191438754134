import { Layer } from 'ol/layer';
import WebGLVectorLayerRenderer, { VectorStyle } from 'ol/renderer/webgl/VectorLayer.js';
import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import { PostProcessesOptions } from 'ol/webgl/Helper';

interface WebGLVectorLayerProps {
  key: React.Key;
  operatorPath: OperatorPathWebGLLayer;
}

export function OperatorPathLayer({ operatorPath }: WebGLVectorLayerProps) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.addLayer(operatorPath);

    return () => {
      if (map) {
        map.removeLayer(operatorPath);
      }
    };
  }, [map]);

  return null;
}

type Options = {
  className?: string | undefined;
  style: VectorStyle | Array<VectorStyle>;
  postProcesses?: PostProcessesOptions[] | undefined;
};

export class OperatorPathWebGLLayer extends Layer {
  createRenderer(): WebGLVectorLayerRenderer {
    const options: Options = {
      style: {
        'stroke-width': 1.5,
        'stroke-color': '#00788A',
      },
    };
    return new WebGLVectorLayerRenderer(this, options);
  }
}
