import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  Job,
  Organisation,
  OrganisationShare,
  ShareStatus,
  useRevokeOrgShareMutation,
  useShareJobWithOrganisationMutation,
} from '../../../graphql/generated';
import FormStringListInput, { KeyString } from '../../../shared/components/forms/form-list-input/FormStringListInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';
import RSBadge from '../../../shared/components/tags/RSBadge';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import { shareStatusToBadgeType, shareStatusToText } from '../utilities/ShareStatusUtilities';

export interface ShareJobDialogProps {
  isOpen: boolean;
  onClose: () => void;
  job: Job;
}

const validator = Yup.object({
  emails: Yup.array(
    Yup.object({
      value: Yup.string().required().email(),
    }),
  ),
});

export default function ShareJobDialog(props: ShareJobDialogProps) {
  const [shareJobMutation] = useShareJobWithOrganisationMutation();
  const [revokeOrgShareMutation] = useRevokeOrgShareMutation();

  const [revokeOrganisation, setRevokeOrganisation] = useState<Organisation | null>(null);

  //this dialog is never available for a shared job so this is ok
  const organisationName = props.job.organisation.name;

  const cols = useMemo(
    () =>
      [
        {
          id: 'organisation.name',
          title: 'Organisation',
          accessor: (ctx) => {
            return `${ctx.organisation.name} ${ctx.organisation.id == props.job.organisation.id ? ' (You)' : ''}`;
          },
        },
        {
          id: 'status',
          title: 'Status',
          accessor: (ctx) => {
            return <RSBadge type={shareStatusToBadgeType[ctx.status]} title={shareStatusToText[ctx.status]} />;
          },
        },
        {
          id: 'actions',
          title: 'Actions',
          accessor: (ctx) => {
            if (ctx.organisation.id == props.job.organisation.id) return '';

            return (
              <RSButton
                variant={'danger'}
                onClick={() => {
                  setRevokeOrganisation(ctx.organisation);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </RSButton>
            );
          },
        },
      ] as RSTableColumnDefinition<OrganisationShare>[],
    [],
  );

  const shares = props.job.sharedOrganisations ? [...props.job.sharedOrganisations] : [];

  //add the user's org to the top of the list
  shares.unshift({
    organisation: {
      id: props.job.organisation.id,
      name: organisationName,
    } as Organisation,
    status: ShareStatus.Accepted,
    lastShared: null,
    lastDeclined: null,
  });

  return (
    <>
      <Formik
        validationSchema={validator}
        onSubmit={async (values) => {
          try {
            shareJobMutation({
              variables: {
                emails: values.emails.map((x) => x.value),
                jobId: props.job.id,
              },
              onCompleted: (result) => {
                toast(
                  `Successfully sent a request to share the job. An invite should arrive in the recipients emails.`,
                );
                //this will only work if the user is a super-admin but its a nice convience tool for them
                props.job.sharedOrganisations = result.shareJobWithOrganisation
                  .sharedOrganisations as OrganisationShare[];
              },
              onError: () => {
                toast(
                  'An error occurred whilst performing the job share, if the error persists after 5 minutes, please contact support.',
                  {
                    type: 'error',
                  },
                );
              },
            });
            props.onClose();
          } catch (ex) {
            console.log(ex);
            toast(`Error: ${ex}`, {
              type: 'error',
            });
          }
        }}
        initialValues={{
          emails: [] as KeyString[],
        }}
      >
        {(formik) => {
          return (
            <RSDialog
              title={'Share Jobs'}
              isOpen={props.isOpen}
              actions={
                <>
                  <RSButton
                    variant="secondary"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    Close
                  </RSButton>
                </>
              }
            >
              <h3 className="mt-3">Share with organisations</h3>
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <FormStringListInput
                  minItems={1}
                  maxItems={30}
                  items={formik.values.emails}
                  name="emails"
                  outerLabel=""
                  innerLabel="Email"
                  newItem=""
                />
              </Form>
              <div className="flex justify-end w-full m-2 p-3">
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  Send
                </RSButton>
              </div>

              {/** Table showing all of the existing shares */}
              <h3 className="mt-3">Organisations with access</h3>
              <RSTable
                data={shares}
                columns={cols}
                idAccessor={(row) => {
                  return row.organisation.id;
                }}
              />
            </RSDialog>
          );
        }}
      </Formik>
      <RSConfirmationDialog
        isOpen={revokeOrganisation != null}
        title={'Revoke Job Share'}
        type={'primary'}
        confirmTitle={'Revoke'}
        onConfirm={() => {
          revokeOrgShareMutation({
            variables: {
              organisationId: revokeOrganisation?.id ?? '',
              jobId: props.job.id,
            },
            onCompleted: () => {
              //update the state to re-render accordingly
              props.job.sharedOrganisations = props.job.sharedOrganisations?.filter((x) => {
                return x.organisation.id != revokeOrganisation?.id;
              });

              setRevokeOrganisation(null);
            },
            onError: () => {
              toast(
                'An error occurred whilst revoking the job share, if the error persists after 5 minutes, please contact support.',
                {
                  type: 'error',
                },
              );
            },
          });
        }}
        onClose={() => setRevokeOrganisation(null)}
      >
        {`Are you sure you want to stop ${revokeOrganisation?.name} from being able to view this job! You can always send them another invite after doing so`}
      </RSConfirmationDialog>
    </>
  );
}
