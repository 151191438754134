import { flexRender, Row } from '@tanstack/react-table';
import { useRef } from 'react';
import RSStatelessCheckBox from '../../components/input/RSStatelessCheckbox';

interface RSTableRowProps<T> {
  key: React.Key | null | undefined;
  row: Row<T>;
  onClick: ((row: Row<T>) => void) | undefined;
  toggleSelect?: boolean;
  isChecked?: boolean;
  setChecked?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onCheck?: () => void;
}

export default function RSTableRow<T>(props: RSTableRowProps<T>) {
  const tdRef = useRef<HTMLTableCellElement>(null);

  return (
    <tr
      onClick={(event: React.MouseEvent) => {
        //if they click the checkbox then ignore
        if (tdRef && tdRef.current && tdRef.current.contains(event.target as Node)) {
          return;
        }

        //otherwsise perform the click logic
        props.toggleSelect && props.row.toggleSelected();
        props.onClick && props.onClick(props.row);
      }}
      className={GetClassName(props.onClick, props.toggleSelect && props.row.getIsSelected(), props.toggleSelect)}
      // key={props.key}
    >
      {props.setChecked && (
        <td
          ref={tdRef}
          style={{ verticalAlign: 'center', width: '50px' }}
          className="rs-table-cell"
          key={`${props.key?.toString()}-checked`}
        >
          <RSStatelessCheckBox
            disabled={props.disabled}
            title={props.disabled ? props.disabledMessage : undefined}
            onChangeHandler={() => {
              if (props.onCheck) {
                props.onCheck();
              }
            }}
            checked={props.isChecked ?? false}
          />
        </td>
      )}
      {props.row.getVisibleCells().map((cell) => (
        <td style={{ verticalAlign: 'center' }} className="rs-table-cell" key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
      {props.setChecked && (
        <td
          style={{ verticalAlign: 'center', width: '50px' }}
          className="rs-table-cell"
          key={`${props.key?.toString()}-footer`}
        ></td>
      )}
    </tr>
  );
}

function GetClassName<T>(onClick: ((row: Row<T>) => void) | undefined, selected?: boolean, toggleSelect?: boolean) {
  let className = 'rs-table-row';
  if (onClick || toggleSelect) {
    className += ' cursor-pointer';
  }
  if (selected) {
    className += ' toggle-selected';
  }

  return className;
}
