import { toast } from 'react-toastify';
import { FirmwareLockStatus, Organisation, useSetOrganisationFirmwareMutation } from '../../../graphql/generated';
import OrganisationFirmwareFormContent from './OrganisationFirmwareFormContent';
type Props = {
  organisation: Organisation;
  isOpen: boolean;
  onClose: () => void;
};

export default function OrganisationFirmwareFormDialogue({ organisation, isOpen, onClose }: Props) {
  const [setFirmware] = useSetOrganisationFirmwareMutation();

  return (
    <OrganisationFirmwareFormContent
      initialValues={{
        firmwareId: organisation.organisationFirmware?.firmware.id ?? '',
        firmwareVersion: organisation.organisationFirmware?.firmware.version ?? '',
        lockedStatus: organisation.organisationFirmware?.lockStatus ?? FirmwareLockStatus.Unlocked,
      }}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={async (value) => {
        await setFirmware({
          variables: {
            input: {
              firmwareId: value.firmwareId,
              organisationId: organisation.id,
              lockStatus: value.lockedStatus,
            },
          },
          onCompleted() {
            toast('Firmware for the organisation has been updated.', { type: 'success' });
          },
          onError: (error) => {
            toast(
              'An unknown error occurred when updating this organisations firmware. Please try again later in 2 minutes or contact support',
              { type: 'error' },
            );
          },
        });
        onClose();
      }}
    />
  );
}
