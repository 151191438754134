import { faKey, faLandmark, faMobile } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { OptionalObjectSchema } from 'yup/lib/object';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { toast } from 'react-toastify';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useBlocker from '../../../shared/hooks/UseBlocker';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import {
  Organisation,
  OrganisationFilterInput,
  OrganisationSearchQuery,
  OrganisationSearchQueryVariables,
  useOrganisationSearchQuery,
} from '../../../graphql/generated';
import { getErrorType, ServerErrorCode } from '../../../shared/utilities/ApolloErrorUtils';
import { DeviceIdLength } from '../../../shared/constants/ValidationConstants';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props<T extends FormikValues> = {
  initialValues?: T;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: () => void;
  onSubmit: (values: T) => Promise<void>; // Means to provide custom onSubmit functionality
  isOrganisational: boolean;
};

// Abstracted user form allowing for the same structure to be used for editing and creating a user
export default function DeviceActivationFormContent<T extends FormikValues>({
  isOrganisational,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
}: Props<T>) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);
  return (
    <Formik<T>
      initialValues={initialValues ?? (validator.cast({}) as T)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers: FormikHelpers<T>) => {
        try {
          await onSubmit(values);
          helpers.resetForm();
        } catch (ex) {
          console.log(ex);
          if (getErrorType(ex) == ServerErrorCode.RESOURCE_NOT_FOUND) {
            toast(`A device with id '${values.deviceId}' could not be found!`, {
              type: 'error',
            });
          } else {
            toast(`An unexpected error occurred whilst trying to activate the device`, {
              type: 'error',
            });
          }
        }
        onClose();
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title="Activate Device"
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={() => {
                    formik.resetForm();
                    onClose();
                  }}
                />
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  Activate
                </RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Device ID"
                name="deviceId"
                icon={faMobile}
                placeholder="Device ID"
                maxLength={DeviceIdLength}
              />
              {!isOrganisational && (
                <FormComboBoxWithQuery<
                  Organisation,
                  OrganisationSearchQuery,
                  OrganisationSearchQueryVariables,
                  OrganisationFilterInput
                >
                  placeholder="Organisation"
                  label="Organisation"
                  required
                  icon={<FontAwesomeIcon icon={faLandmark} />}
                  name="organisationId"
                  dataAccessorCallback={(data) =>
                    data?.organisations?.nodes?.map((org) => ({
                      value: org.id,
                      displayValue: org.name,
                    }))
                  }
                  searchFieldNames={['name']}
                  query={useOrganisationSearchQuery}
                />
              )}
              <FormTextInput
                required
                label="Activation Token"
                name="activationToken"
                icon={faKey}
                placeholder="Activation Token"
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
