import { toast } from 'react-toastify';
import { Chemical, GetChemicalsByOrganisationIdDocument, useDeleteChemicalMutation } from '../../../graphql/generated';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';

interface ChemicalDeleteConfirmationProps {
  currentOrganisation: string;
  chemical: Chemical;
  isOpen: boolean;
  onClose: () => unknown;
}

export default function ChemicalDeleteConfirmation({
  currentOrganisation,
  chemical,
  isOpen,
  onClose,
}: ChemicalDeleteConfirmationProps) {
  const [deleteChemical] = useDeleteChemicalMutation();

  return (
    <RSConfirmationDialog
      type="danger"
      isOpen={isOpen}
      onClose={onClose}
      title={'Delete Chemical'}
      onConfirm={async () => {
        await deleteChemical({
          variables: {
            id: chemical.id,
          },
          refetchQueries: [{ query: GetChemicalsByOrganisationIdDocument, variables: { id: currentOrganisation } }],
          onCompleted() {
            onClose();
            toast(`You have successfully deleted the chemical '${chemical.name}'.`, {
              type: 'success',
            });
          },
          onError(error) {
            toast(error.message, {
              type: 'error',
            });
          },
        });
      }}
    >
      {'Selecting confirm will delete this chemical, stopping it from being edited and being used for new recipes.'}
    </RSConfirmationDialog>
  );
}
