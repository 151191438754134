import { faChevronDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Transition } from '@headlessui/react';
import { ReactNode, useState } from 'react';
import { disclosureAnimation } from '../../constants/HeadlessAnimationConstants';

type Props = {
  title: string;
  children: ReactNode;
  icon?: IconDefinition;
  defaultOpen?: boolean;
};

export default function RSDisclosure(props: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Disclosure defaultOpen={props.defaultOpen}>
      <Disclosure.Button
        onClick={() => setOpen(!open)}
        className="rounded-t-lg flex w-full justify-between px-4 py-2 text-left text-xl font-medium hover:bg-gray-200"
      >
        <span>
          {props.icon && <FontAwesomeIcon className="pr-3 text-gray-400" icon={props.icon}></FontAwesomeIcon>}
          <b>{props.title}</b>
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-400 pt-1`}
        />
      </Disclosure.Button>
      <Transition {...disclosureAnimation}>
        <Disclosure.Panel className="px-4 pt-2 pb-2 text-md text-gray-500">{props.children}</Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
}
