import { ReactNode, useState } from 'react';
import RSStatelessCheckBox from './RSStatelessCheckbox';

interface RSCheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  key: string;
  label: ReactNode;
  name?: string;
  initialValue: boolean;
  disabled?: boolean;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => boolean;
}

export default function RSCheckBox({ key, label, name, initialValue, disabled, onChangeHandler }: RSCheckBoxProps) {
  const [isChecked, setIsChecked] = useState<boolean>(initialValue);

  return (
    <div className="flex flex-row">
      <RSStatelessCheckBox
        id={key}
        name={name}
        checked={isChecked}
        disabled={disabled}
        onChangeHandler={(event) => {
          setIsChecked(onChangeHandler(event, isChecked));
        }}
      />
      <label htmlFor={key} className="pl-2 items-center">
        {label}
      </label>
    </div>
  );
}
