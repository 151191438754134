import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useBatchExportJobsMutation } from '../../../graphql/generated';
import FormStringListInput, {
  KeyString,
  toKeyString,
} from '../../../shared/components/forms/form-list-input/FormStringListInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import useCurrentUserStore from '../../../shared/hooks/stores/UseCurrentUserStore';
import { BatchSelection } from '../../../shared/layout/table/RSTable';

export interface BatchJobExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectAll: boolean;
  selection: BatchSelection;
  organizationId: string;
}

const validator = Yup.object({
  emails: Yup.array(
    Yup.object({
      value: Yup.string().required().email(),
    }),
  ),
});

export default function BatchJobExportDialog(props: BatchJobExportDialogProps) {
  const [batchExportJob] = useBatchExportJobsMutation();
  const currentUser = useCurrentUserStore((state) => state.currentUser);

  return (
    <Formik
      validationSchema={validator}
      onSubmit={async (values) => {
        try {
          await batchExportJob({
            variables: {
              emails: values.emails.map((x) => x.value),
              jobIds: props.selection.data,
              selectAll: props.selection.selectAll,
              organizationId: props.organizationId,
            },
            onCompleted: () => {
              toast(`Successfully Performed a batch export. Please check your emails!`);
            },
            onError: () => {
              toast(
                'An error occurred whilst performing the batch export, if the error persists after 5 minutes, please contact support.',
                {
                  type: 'error',
                },
              );
            },
          });
          props.onClose();
        } catch (ex) {
          console.log(ex);
          toast(`Error: ${ex}`, {
            type: 'error',
          });
        }
      }}
      initialValues={{
        emails: [toKeyString(currentUser?.attributes?.email)] as KeyString[],
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title={'Export Jobs'}
            isOpen={props.isOpen}
            actions={
              <>
                <RSButton
                  variant="secondary"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  Close
                </RSButton>
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  Export
                </RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <FormStringListInput
                minItems={1}
                items={formik.values.emails}
                name="emails"
                outerLabel="Enter Emails"
                innerLabel="Email"
                newItem=""
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
