import { Outlet } from 'react-router-dom';
import SideNavigation from './side-navigation/SideNavigation';
import TopNavigation from './top-navigation/TopNavigation';
import { ToastContainer } from 'react-toastify';

export default function AppLayout() {
  return (
    <div className="flex flex-col h-screen">
      {/* Top Navigation Bar */}
      <TopNavigation />
      {/* Body */}
      <div className="flex h-full overflow-hidden">
        {/* Side Navigation Container*/}
        <SideNavigation showSidebar={true} />
        {/* Content */}
        <div className="flex-grow p-4 overflow-y-auto">
          <div className="max-w-screen-2xl mx-auto">
            <Outlet />
          </div>
        </div>
      </div>
      {/* Allow toast on page */}
      <ToastContainer
        style={{
          minWidth: '30%',
        }}
        theme="light"
        position="bottom-center"
        autoClose={5000}
      />
    </div>
  );
}
