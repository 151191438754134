import React, { useState } from 'react';
import SpinLoader from '../../../layout/loading/SpinLoader';

type RSButtonType = 'primary' | 'danger' | 'secondary' | 'disabled';

interface RSButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => Promise<void> | void;
  variant?: RSButtonType;
}

const buttonTypeToClass: { [type in RSButtonType]: string } = {
  danger: 'rs-btn-danger',
  primary: 'rs-btn-primary',
  secondary: 'rs-btn-secondary',
  disabled: 'rs-btn-disabled',
};

export default function RSButton(props: RSButtonProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDisabled = props.variant == 'disabled';

  return (
    <button
      {...props}
      className={`relative transition-all duration-200 h-min ${props.className} ${
        buttonTypeToClass[props.variant ?? 'primary']
      } ${isLoading && 'bg-gray-200'}`}
      disabled={isDisabled || isLoading}
      onClick={async () => {
        setIsLoading(true);
        props.onClick && (await props.onClick());
        setIsLoading(false);
      }}
    >
      {props.children}
      {isLoading && (
        <SpinLoader
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          innerClassName="w-6 h-6"
        />
      )}
    </button>
  );
}
