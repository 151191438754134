import { ApolloError } from '@apollo/client';

export enum ServerErrorCode {
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  RESOURCE_ALREADY_EXISTS = 'RESOURCE_ALREADY_EXISTS',
  INVALID_OPERATION = 'INVALID_OPERATION',
  BAD_REQUEST = 'BAD_REQUEST',
}

export const getErrorType = (ex: unknown): string | undefined => {
  if (ex instanceof ApolloError) {
    if (ex.graphQLErrors.length > 0) {
      const code = ex.graphQLErrors[0].extensions.code as string;
      return ServerErrorCode[code as keyof typeof ServerErrorCode];
    }
    if ((ex.networkError as any).result.errors.length > 0) {
      const code = (ex.networkError as any).result.errors[0].extensions.code as string;
      return ServerErrorCode[code as keyof typeof ServerErrorCode];
    }
  }
};
