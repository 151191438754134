import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Combobox } from '@headlessui/react';
import { ReactNode, useState } from 'react';
import useSearchSortFilter, { SearchFieldNames } from '../../../hooks/UseSearchSortFilter';
import SpinLoader from '../../../layout/loading/SpinLoader';
import { ComboBoxItem } from './ComboBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RSComboBoxInput from './RSComboBoxInput';

export interface SearchableQueryVariables<TFilterInput> {
  where?: TFilterInput;
  first?: number;
}

export interface RSComboboxWithQueryProps<
  TEntity,
  TQuery,
  TQueryVariables extends SearchableQueryVariables<TFilterInput>,
  TFilterInput,
> extends Omit<RSComboBoxProps<TEntity>, 'isLoading' | 'setSearchStringCallback' | 'values'> {
  dataAccessorCallback: (data: TQuery) => ComboBoxItem<TEntity>[] | undefined;
  searchFieldNames: SearchFieldNames<TFilterInput>;
  query: (baseOptions: QueryHookOptions<TQuery, TQueryVariables>) => QueryResult<TQuery, TQueryVariables>;
  first?: number;
  variables?: Omit<TQueryVariables, 'where' | 'first'>;
}

export function RSComboboxWithQuery<
  TEntity,
  TQuery,
  TQueryVariables extends SearchableQueryVariables<TFilterInput>,
  TFilterInput,
>({
  initialValue,
  name,
  dataAccessorCallback,
  searchFieldNames,
  query,
  first,
  variables,
  onChange,
  icon,
  placeholder,
  extraPadding,
  denyDeselect,
}: RSComboboxWithQueryProps<TEntity, TQuery, TQueryVariables, TFilterInput>) {
  const { where, setSearchString } = useSearchSortFilter<TFilterInput>({
    searchableFieldNames: searchFieldNames,
  });

  const { data, loading } = query({
    variables: {
      ...variables,
      where: where,
      first: first ?? 3,
    } as TQueryVariables,
  });

  return (
    <RSComboBox<TEntity>
      initialValue={initialValue}
      name={name}
      isLoading={loading}
      setSearchStringCallback={setSearchString}
      values={data ? dataAccessorCallback(data) : []}
      onChange={onChange}
      icon={icon}
      placeholder={placeholder}
      extraPadding={extraPadding}
      denyDeselect={denyDeselect}
    />
  );
}

interface RSComboBoxProps<T> {
  initialValue?: ComboBoxItem<T>;
  name: string;
  icon?: ReactNode;
  placeholder?: string;
  isLoading: boolean;
  setSearchStringCallback: (searchString: string) => void;
  values: Array<ComboBoxItem<T>> | undefined;
  onChange?: (item: ComboBoxItem<T> | undefined) => void;
  extraPadding?: boolean;
  denyDeselect?: boolean;
}

export default function RSComboBox<T>({
  initialValue,
  name,
  icon,
  placeholder,
  isLoading,
  setSearchStringCallback,
  values,
  onChange,
  extraPadding,
  denyDeselect,
}: RSComboBoxProps<T>) {
  const [selection, setSelection] = useState<ComboBoxItem<T> | undefined>(initialValue);
  const onChangeHandler = (item: ComboBoxItem<T> | undefined) => {
    setSelection(item);
    onChange && onChange(item);
  };

  return (
    <Combobox name={name} value={selection} onChange={onChangeHandler}>
      <div className="flex relative w-full">
        <div className="rs-form-icon-container w-full z-10 ">
          {icon}
          <RSComboBoxInput
            debounceTimeout={500}
            setSelectionCallback={onChangeHandler}
            setSearchStringCallback={setSearchStringCallback}
            placeholder={placeholder}
            icon={icon}
            extraPadding={extraPadding}
            denyDeselect={denyDeselect}
          />
        </div>
        <Combobox.Options className="drop-down-options z-30">
          {/* Loading Option */}
          {isLoading && (
            <Combobox.Option
              key="loading"
              className="group flex flex-row w-full justify-center items-center rounded-md px-2 py-2 text-sm"
              value={undefined}
            >
              <SpinLoader innerClassName=" text-center w-8 h-8 text-lg justify-center" />
            </Combobox.Option>
          )}
          {/* Regular options */}
          {!isLoading &&
            values?.map((option, idx) => (
              <Combobox.Option key={idx} value={option}>
                {({ active }) => {
                  // override the normal Combobox selected state because for whatever reason it doesn't work
                  const selected = option.value === selection?.value;
                  return (
                    <div
                      className={`group flex w-full items-center rounded-md px-2 py-2 text-sm ${
                        active || selected ? 'bg-primary text-white' : 'bg-white text-black'
                      }`}
                    >
                      {selected && <FontAwesomeIcon className="text-lg" icon={faCheck} />}
                      <span className="pl-3 text-md">{option.displayValue}</span>
                    </div>
                  );
                }}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
