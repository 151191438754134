import { useMemo } from 'react';
import { OperatorPathLayer } from '../../../../shared/components/maps/Map/Layers/WebGLVectorLayer';
import { DeviceAssignmentLayerPair } from './JobMap';

type DeviceAssignmentLineLayersProps = {
  deviceAssignments: DeviceAssignmentLayerPair[];
};

export default function OperatorPathLayers({ deviceAssignments }: DeviceAssignmentLineLayersProps) {
  return useMemo(
    () => (
      <>
        {deviceAssignments.map((da, index) => {
          return <OperatorPathLayer key={`deviceAssignmentLineLayer.${index}`} operatorPath={da.operatorPath} />;
        })}
      </>
    ),
    [deviceAssignments],
  );
}
