import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import RapidSprayLogo from '../../../assets/images/rapid-spray-logo.png';
import RSBreadcrumbs from '../../components/breadcrumbs/RSBreadcrumbs';
import useSidebarStore from '../../hooks/stores/UseSidebarStore';
import ProfileMenu from './ProfileMenu';

export default function TopNavigation() {
  const { toggleSidebar } = useSidebarStore();

  return (
    <nav className="flex flex-row shrink-0 items-center bg-white w-full h-20 border-b-2">
      <FontAwesomeIcon
        onClick={toggleSidebar}
        className="xl:hidden cursor-pointer text-gray-500 px-5"
        size="2x"
        icon={faBars}
      />
      <Link to="/dashboard">
        <CenteredLogo />
      </Link>
      <RSBreadcrumbs className="px-9 hidden md:block" path={''} />
      <div className="flex-grow" />
      <ProfileMenu />
    </nav>
  );
}

function CenteredLogo() {
  return (
    <div className="xl:w-60 xl:block">
      <img
        className="mx-auto my-2"
        style={{
          width: '110px',
        }}
        src={RapidSprayLogo}
        alt="The rapid logix company logo"
      />
    </div>
  );
}
