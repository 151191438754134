import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FirmwareLockStatusToDisplayName } from '../../../graphql/firmware/FirmwareUtils';
import { FirmwareInformation, FirmwareInstallStatus, SlotInformation } from '../../../graphql/generated';
import FirmwareTag from '../../firmware/components/FirmwareTag';

interface FirmwarePivotRowProps {
  firmwareInformation: FirmwareInformation;
}

const getNewestFirmware = (firmwareInformation: FirmwareInformation) => {
  const slot1 = firmwareInformation.slot1;
  const slot2 = firmwareInformation.slot2;

  const slot1Installed = slot1.status === FirmwareInstallStatus.Installed;
  const slot2Installed = slot2.status === FirmwareInstallStatus.Installed;

  let installed: SlotInformation;

  if (slot1Installed && !slot2Installed) {
    installed = slot1;
  } else if (!slot1Installed && slot2Installed) {
    installed = slot2;
  } else {
    // the newer firmware will have no reportedAt or a reportedAt that is larger than the other
    installed = !slot1.reportedAt || slot1.reportedAt > slot2.reportedAt ? slot1 : slot2;
  }

  return installed;
};

export default function FirmwarePivotRow({ firmwareInformation }: FirmwarePivotRowProps) {
  const candidate = firmwareInformation.candidate;

  const installed = getNewestFirmware(firmwareInformation);

  const isCandidateInstalled = candidate?.firmware.id === installed.firmware.id;

  const installedTag = <FirmwareTag firmware={installed.firmware} />;

  if (candidate && !isCandidateInstalled) {
    return (
      <div className="flex flex-row gap-x-4 items-center">
        {installedTag}
        <FontAwesomeIcon icon={faArrowRight} />
        <FirmwareTag firmware={candidate.firmware} /> ({FirmwareLockStatusToDisplayName[firmwareInformation.lockStatus]}
        )
      </div>
    );
  } else {
    return installedTag;
  }
}
