import { faChevronDown, faChevronUp, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Control from 'ol/control/Control';
import { useContext, useEffect, useRef, useState } from 'react';
import { UnitMeasurement, UnitType, Weed } from '../../../../graphql/generated';
import MapContext from '../../../../shared/components/maps/Map/MapContext';
import { GetFullUnitDisplay, GetUnitSymbol } from '../../../../shared/utilities/UnitConversionUtilities';
import { weedColours } from './JobMap';

interface LegendProps {
  maxSprayed?: UnitMeasurement;
  weeds: Array<Weed>;
}

export default function Legend({ maxSprayed, weeds }: LegendProps) {
  const map = useContext(MapContext);
  const controlElement = useRef<HTMLDivElement>(null);
  const [control, setControl] = useState<Control>();

  const [minimized, setMinimized] = useState<boolean>(false);

  useEffect(() => {
    if (!map) return;

    const newControl = new Control({
      element: controlElement.current ?? undefined,
    });

    map.addControl(newControl);
    setControl(control);

    return () => {
      map.removeControl(newControl);
    };
  }, [map]);

  const hasSprayed = !!maxSprayed;
  const hasWeedsSet = weeds.some((weed) => weed !== null);
  const isShown = hasSprayed || hasWeedsSet;

  const handleLegendHeaderClick = () => {
    setMinimized(!minimized);
  };

  return (
    <div
      ref={controlElement}
      className={`bg-white flex flex-col absolute right-2 bottom-2 rounded-lg px-2 ${!minimized ? 'pb-2 ' : ''}w-80 ${
        !isShown ? 'hidden' : ''
      }`}
    >
      {isShown && (
        <>
          <div
            className="flex flex-row items-center flex-wrap border-border-gray text-left pb-2 hover:cursor-pointer"
            onClick={handleLegendHeaderClick}
          >
            <div className="flex-1 flex justify-center">
              <p className="text-primary font-bold text-2xl">Legend</p>
            </div>
            <FontAwesomeIcon icon={minimized ? faChevronUp : faChevronDown} />
          </div>
          {!minimized && (
            <div>
              {hasSprayed && (
                <div className="volume-legend">
                  <div className="font-bold">Volume:</div>
                  <div className="h-4 bg-gradient-to-r from-[#00f] via-[#0ff] via-[#0f0] via-[#ff0] to-[#f00]"></div>
                  <div className="flex flex-row flex-grow justify-between">
                    <p>{`0${GetUnitSymbol(maxSprayed)}`}</p>
                    <p>{`>= ${GetFullUnitDisplay(maxSprayed, 2)}`}</p>
                  </div>
                </div>
              )}
              {hasWeedsSet && (
                <div className="weed-legend">
                  <div className="font-bold">Weeds:</div>
                  <div className="flex flex-col">
                    {weeds.map((weed, index) => (
                      <div className="items-center" key={index}>
                        <FontAwesomeIcon icon={faCircle} color={weedColours[index]} /> {weed?.name ?? 'Not Set'}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
