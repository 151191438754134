// DropdownItem.tsx
import { MouseEventHandler, ReactElement } from 'react';

interface DropdownItemProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  children: ReactElement | string;
  disabled?: boolean;
}

const DropdownItem = ({ onClick, children, disabled }: DropdownItemProps) => {
  return (
    <div
      onClick={(event) => {
        if (disabled) return;

        onClick(event);
      }}
      className={`px-4 py-2${!disabled ? ' hover:bg-gray-100 cursor-pointer text-black' : ' text-gray-700'}`}
    >
      {children}
    </div>
  );
};

export default DropdownItem;
