import { DependencyList, ReactNode } from 'react';
import { UserRole } from '../../graphql/generated';
import { AuthPolicy, AuthPolicyRoles } from '../utilities/AuthPolicy';
import useCurrentUserStore from '../hooks/stores/UseCurrentUserStore';
import RenderGuard from './RenderGuard';

export enum PolicyAndRoleCombination {
  And,
  Or,
}

interface PermissionGuardPropsBase {
  children: ReactNode;
  isVisible?: boolean;
  deps?: DependencyList;
}

interface PermissionGuardPropsWithPolicy extends PermissionGuardPropsBase {
  policy: AuthPolicy;
}

interface PermissionGuardPropsWithListPolicy extends PermissionGuardPropsBase {
  anyPolicy: AuthPolicy[];
}

interface PermissionGuardPropsWithRole extends PermissionGuardPropsBase {
  role: UserRole;
}

interface PermissionGuardPropsWithPolicyAndRole extends PermissionGuardPropsBase {
  policy: AuthPolicy;
  role: UserRole;
  combine: PolicyAndRoleCombination;
}

interface PermissionGuardProps extends PermissionGuardPropsBase {
  anyPolicy?: AuthPolicy[];
  policy?: AuthPolicy;
  role?: UserRole;
  combine?: PolicyAndRoleCombination;
}

export default function PermissionGuard(props: PermissionGuardPropsWithPolicy): JSX.Element;
export default function PermissionGuard(props: PermissionGuardPropsWithRole): JSX.Element;
export default function PermissionGuard(props: PermissionGuardPropsWithPolicyAndRole): JSX.Element;
export default function PermissionGuard(props: PermissionGuardPropsWithListPolicy): JSX.Element;

export default function PermissionGuard({
  children,
  policy,
  deps,
  role,
  combine,
  isVisible,
  anyPolicy,
}: PermissionGuardProps) {
  const currentUserRole = useCurrentUserStore((state) => state.getCurrentUserRole()) as UserRole;

  const hasPolicyPermission = policy !== undefined && AuthPolicyRoles[policy].includes(currentUserRole);
  const hasRolePermission = role !== undefined && currentUserRole == role;
  const hasAnyPolicy =
    anyPolicy !== undefined && anyPolicy.some((policy) => AuthPolicyRoles[policy].includes(currentUserRole));

  let hasPermission = false;
  /// Have to check if policy is undefined otherwise index 0 doesn't register as defined
  const policyDefined = policy !== undefined;
  const policiesDefined = anyPolicy !== undefined;

  if (policyDefined && role) {
    switch (combine) {
      case PolicyAndRoleCombination.Or:
        hasPermission = hasPolicyPermission || hasRolePermission;
        break;
      case PolicyAndRoleCombination.And:
      default:
        hasPermission = hasPolicyPermission && hasRolePermission;
        break;
    }
  } else if (policyDefined) {
    hasPermission = hasPolicyPermission;
  } else if (policiesDefined) {
    hasPermission = hasAnyPolicy;
  } else {
    hasPermission = hasRolePermission;
  }

  return (
    <RenderGuard deps={[...(deps ? deps : []), isVisible, hasPermission]} isVisible={isVisible}>
      {hasPermission ? children : undefined}
    </RenderGuard>
  );
}
