import { ApplicationType } from '../../../graphql/generated';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';

export const applicationTypeToText: { [key in ApplicationType]: string } = {
  [ApplicationType.Unspecified]: 'Unspecified',
  [ApplicationType.FoliarLowVolume]: 'Foliar - Low Volume',
  [ApplicationType.FoliarHighVolume]: 'Foliar - High Volume',
  [ApplicationType.SpotTreatment]: 'Spot Treatment',
  [ApplicationType.Broadcast]: 'Broadcast/Boom Spray',
  [ApplicationType.BasalBark]: 'Basal Bark',
  [ApplicationType.SoilInjection]: 'Soil Injection',
};

export const applicationTypeOptions: DropDownOption<string>[] = (function () {
  return Object.entries(applicationTypeToText).map(([key, val]) => ({
    displayValue: val,
    value: key,
  }));
})();
