import { NavigateFunction } from 'react-router';
import { Organisation } from '../../../graphql/generated';
import { localRoutingConstants } from '../../constants/LocalRoutingConstants';
import { RSTag } from './RSTag';

interface Props {
  organisation?: Organisation;
  navigate: NavigateFunction;
}

export default function OrganisationTag({ organisation, navigate }: Props) {
  return (
    <RSTag
      title={organisation?.name ?? 'N/A'}
      className={organisation ? 'bg-gray-300' : ''}
      onClick={
        organisation?.name
          ? (event) => {
              event.stopPropagation();
              navigate(`${localRoutingConstants.organisations.root}/${organisation?.id}`);
            }
          : undefined
      }
    />
  );
}
