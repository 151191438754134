import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldValidator, useField, useFormikContext } from 'formik';
import moment from 'moment';
import { DateInputFormat } from '../../../utilities/TimeUtilities';
import FormErrorText from '../form-error-text/FormErrorText';
import FormLabel from '../FormLabel';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  icon?: IconProp;
  disabled?: boolean;
  value?: string;
  notLocal?: boolean;
  min?: string;
  max?: string;
  inLineLabel?: boolean;
  validate?: FieldValidator;
  onChangeHandler?: (value: string) => void;
};

export default function FormDateInput(props: Props) {
  const [field, meta] = useField(props);

  const form = useFormikContext();

  const wrapperClassName = props.inLineLabel ? 'flex flex-row flex-grow items-baseline' : 'flex flex-col flex-grow';
  return (
    <div className={wrapperClassName}>
      <FormLabel label={props.label} required={props.required} />
      <div className="flex flex-col rs-form-icon-container w-full ">
        {/* Icon */}
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {/* Input */}
        <input
          type={props.notLocal ? 'date' : 'datetime-local'}
          autoComplete="off"
          className={`rs-form-input w-full ${!props.icon && 'pl-4'} ${
            meta.error && meta.touched ? 'border-red-500' : ''
          }`}
          {...field}
          placeholder={props.placeholder}
          value={field.value ? moment(field.value).format(props.notLocal ? 'yyyy-MM-DD' : DateInputFormat) : undefined}
          onChange={(e) => {
            form.setFieldTouched(props.name, true);
            form.setFieldValue(props.name, e.target.value);
            props.onChangeHandler && props.onChangeHandler(e.target.value);
          }}
          min={props.min}
          max={props.max}
        />
        <FormErrorText meta={meta} />
      </div>
    </div>
  );
}
