import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import logo from '../../../../assets/images/rapid-spray-logo-greyscale.png';
import { Concentration, Job, UnitMeasurement, UnitSystem } from '../../../../graphql/generated';
import {
  GetFullUnitDisplay,
  GetLargeAreaDisplay,
  GetUnitSymbol,
} from '../../../../shared/utilities/UnitConversionUtilities';
import {
  CalculateAmountUsed,
  concentrationUnitUsageForChemicalType,
} from '../../../recipes/utilities/ConcentrationUtilities';
import { applicationTypeToText } from '../../utilities/ApplicationTypeUtilities';
import { WeatherInformationUnitSystemUnitTypes } from '../WeatherInformationTab';

type props = {
  job: Job;
  unitSystem: UnitSystem;
  mapImage: string | undefined;
  onRender?: () => void;
};

function DisplayVolumeDispensed(unit: UnitMeasurement | undefined) {
  if (!unit) {
    return 'N/A';
  }

  return GetFullUnitDisplay(unit);
}

function DisplayAreaSprayed(unit: UnitMeasurement | undefined) {
  if (!unit) {
    return 'N/A';
  }

  return `${GetLargeAreaDisplay(unit, 2)} (${GetFullUnitDisplay(unit, 2)})`;
}

function DisplayConcentration(concentration: Concentration) {
  return `${concentration.amount.value.toFixed(2)} ${GetUnitSymbol(concentration.amount.unit)}`;
}

function DisplayTotalChemical(
  unitSystem: UnitSystem,
  concentration: Concentration,
  volume: UnitMeasurement | undefined,
) {
  if (!volume) {
    return 'N/A';
  }

  const amountUsed = CalculateAmountUsed(volume, concentration.amount);
  if (!amountUsed) {
    return 'N/A';
  }
  const unitType = concentrationUnitUsageForChemicalType[concentration.chemical.type][unitSystem];
  const unitSymbol = GetUnitSymbol(unitType);

  return `${amountUsed.toFixed(2)} ${unitSymbol}`;
}

/**
 *
 * @param {Job} job The Job used in the generation of the PDF
 * @param {string | undefined} mapImage DataUrl of the map
 * @param {() => void} onRender Function supplied to the Document onRender parameter
 * @returns A PDF document to be generated using react-pdf
 */
export default function JobPDF({ job, mapImage, onRender, unitSystem }: props) {
  const styles = StyleSheet.create({
    heading1: {
      color: '#00788a',
      fontSize: '20px',
      marginBottom: '20px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'bold',
    },
    heading2: {
      color: '#00788a',
      fontSize: '14px',
      marginTop: '20px',
      marginBottom: '5px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'semibold',
    },
    infoSection1: {
      display: 'flex',
      flexDirection: 'column',
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px dotted black',
      padding: '5px 0px 5px 0px',
    },
    content: {
      margin: '40px 20px 20px 40px',
      fontFamily: 'Source Sans Pro',
    },
    tableHeaders: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '5px 0px 5px 0px',
    },
    tableHeader: {
      color: 'black',
      fontSize: '10px',
      width: '16%',
      fontWeight: 'bold',
    },
    table2Header: {
      color: 'black',
      fontSize: '10px',
      width: '25%',
      fontWeight: 'bold',
    },
    table2HeaderLight: {
      color: 'black',
      fontSize: '10px',
      fontWeight: 'light',
    },
    tableText: {
      color: 'black',
      fontSize: '10px',
    },
    section1Heading: {
      width: '25%',
      color: 'black',
      fontSize: '10px',
      fontWeight: 'semibold',
    },
    section1Data: {
      width: '25%',
      color: 'black',
      fontSize: '10px',
    },
    section1LongData: {
      width: '75%',
      color: 'black',
      fontSize: '10px',
    },
    weatherData: {
      color: 'black',
      fontSize: '10px',
      width: '16%',
    },
    chemicalData: {
      color: 'black',
      fontSize: '10px',
      width: '25%',
    },
    underlinedHeading: {
      borderBottom: '1px solid black',
    },
    footer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      left: '0',
      bottom: '0',
      width: '100%',
    },
    footer2: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    footerImage: {
      margin: '0px 20px 20px 20px',
      width: '15%',
    },
    footerBox: {
      width: '70%',
    },
    footerLine: {
      borderBottom: '1px solid black',
      width: '100%',
    },
    footerTextBox: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '10px',
    },
    footerText: {
      width: '33%',
      textAlign: 'center',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'light',
    },
    operatorSection: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px 0 5px 0',
      borderBottom: '1px dotted black',
      borderTop: '1px dotted black',
      marginBottom: '30px',
    },
    operatorText: {
      width: '100%',
      color: 'black',
      fontSize: '10px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'bold',
    },
    operatorInfoText: {
      width: '100%',
      color: 'black',
      fontSize: '10px',
      fontFamily: 'Source Sans Pro',
    },
    operatorRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    circle: {
      borderRadius: '50%',
      width: '15px',
      height: '15px',
      marginRight: '10px',
    },
    targetSection: {
      width: '40%',
    },
    mapImage: {
      marginTop: '30px',
      width: '100%',
    },
    totalRow: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px dotted black',
    },
    total: {
      width: '25%',
      fontFamily: 'Source Sans Pro',
      fontSize: '10px',
    },
    totalHeading: {
      width: '25%',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'bold',
      fontSize: '10px',
    },
    applicationType: {},
    applicationSection: {
      width: '40%',
      marginLeft: '30px',
    },
    applicationHeading: {},
    targetAndApplication: {
      display: 'flex',
      flexDirection: 'row',
    },
    pageTwo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  });

  const colours = [
    {
      colour: '#FAA42A',
    },
    {
      colour: '#25408F',
    },
    {
      colour: '#41AD49',
    },
  ];

  const timeFormat = 'ddd MMM DD YYYY HH:mm:ss [(GMT]ZZ[)]';
  const now = moment().format('DD/MM/YYYY');
  const weatherUnits = WeatherInformationUnitSystemUnitTypes[unitSystem];

  return (
    <Document onRender={onRender}>
      <Page size="A4">
        <View style={styles.content}>
          <View>
            <Text style={styles.heading1}>Chemical Spray Sheet | Job Details</Text>
          </View>
          <View style={styles.infoSection1}>
            <View style={styles.infoRow}>
              <Text style={styles.section1Heading}>Job Name:</Text>
              <Text style={styles.section1LongData}>{job.name}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.section1Heading}>Application Location:</Text>
              <Text style={styles.section1LongData}>{job.location?.address}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.section1Heading}>Start Time:</Text>
              <Text style={styles.section1Data}>{moment(job.startTime).format(timeFormat)}</Text>
              <Text style={styles.section1Heading}>End Time:</Text>
              <Text style={styles.section1Data}>{moment(job.endTime).format(timeFormat)}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.section1Heading}>Job Description:</Text>
              <Text style={styles.section1LongData}>{job.description}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.section1Heading}>Total Volume:</Text>
              <Text style={styles.section1Data}>{DisplayVolumeDispensed(job.volumeDispensed)}</Text>
              <Text style={styles.section1Heading}>Area Sprayed:</Text>
              <Text style={styles.section1Data}>{DisplayAreaSprayed(job.areaSprayed)}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.heading2}>Weather Report</Text>
          </View>
          <View style={styles.tableHeaders}>
            <Text style={styles.tableHeader}></Text>
            <Text style={styles.tableHeader}>Delta T(&#916;T {GetUnitSymbol(weatherUnits.deltaT)})</Text>
            <Text style={styles.tableHeader}>Temperature ({GetUnitSymbol(weatherUnits.temperature)})</Text>
            <Text style={styles.tableHeader}>Humidity ({GetUnitSymbol(weatherUnits.humidity)})</Text>
            <Text style={styles.tableHeader}>Wind Speed ({GetUnitSymbol(weatherUnits.windSpeed)})</Text>
            <Text style={styles.tableHeader}>Wind Direction({GetUnitSymbol(weatherUnits.windDirection)})</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.weatherData}>Start</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(0)?.deltaT?.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(0)?.temperature?.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(0)?.humidity.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(0)?.windSpeed?.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(0)?.windBearing.value.toFixed(2)}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.weatherData}>End</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(1)?.deltaT?.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(1)?.temperature?.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(1)?.humidity.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(1)?.windSpeed?.value.toFixed(2)}</Text>
            <Text style={styles.weatherData}>{job.weatherInformation.at(1)?.windBearing.value.toFixed(2)}</Text>
          </View>
          <View style={styles.targetAndApplication}>
            <View style={styles.targetSection}>
              <View style={styles.underlinedHeading}>
                <Text style={styles.heading2}>Targets</Text>
              </View>
              {job.weedAssignments.map((target, index) => {
                return (
                  <View style={styles.infoRow} key={index}>
                    <View style={[styles.circle, { backgroundColor: colours.at(index)?.colour }]}></View>
                    <Text style={styles.tableText}>{target?.name ? target.name : 'Not Set'}</Text>
                  </View>
                );
              })}
            </View>
            <View style={styles.applicationSection}>
              <View style={styles.underlinedHeading}>
                <Text style={styles.heading2}>Application Type</Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.tableText}>{applicationTypeToText[job.applicationType]}</Text>
              </View>
            </View>
          </View>
          <View>
            <Image style={styles.mapImage} src={{ uri: mapImage ?? '', body: '', headers: [], method: 'GET' }} />
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerImage}>
            <Image src={logo} source={'Rapid Spray Logo'} />
          </View>
          <View style={styles.footerBox}>
            <View style={styles.footerLine}></View>
            <View style={styles.footerTextBox}>
              <Text style={styles.footerText}>{job.organisation.name}</Text>
              <Text style={styles.footerText}>Page 1 of 2</Text>
              <Text style={styles.footerText}>Document Date: {now}</Text>
            </View>
          </View>
        </View>
      </Page>
      {/* Page 2 - Chemical Mixing */}
      <Page>
        <View style={styles.content}>
          <View style={styles.pageTwo}>
            {/* Chemical Mixing Table */}
            <View>
              <View>
                <Text style={styles.heading2}>Chemical Mixing / Usage: Recipe Name</Text>
              </View>
              <View style={styles.tableHeaders}>
                <Text style={styles.table2Header}>Chemical Name</Text>
                <Text style={styles.table2Header}>Concentrations</Text>
                <Text style={styles.table2Header}>Product Type</Text>
                <Text style={styles.table2Header}>Chemical Usage</Text>
              </View>
              {job.recipe?.concentrations.map((concentration, index) => {
                return (
                  <View style={styles.infoRow} key={index}>
                    <Text style={styles.chemicalData}>{concentration.chemical.name}</Text>
                    <Text style={styles.chemicalData}>{DisplayConcentration(concentration)}</Text>
                    <Text style={styles.chemicalData}>{concentration.chemical.type}</Text>
                    <Text style={styles.chemicalData}>
                      {DisplayTotalChemical(unitSystem, concentration, job.volumeDispensed)}
                    </Text>
                  </View>
                );
              })}
              <View style={styles.totalRow}>
                <Text style={styles.totalHeading}>Total Sprayed</Text>
                <Text style={styles.total}>{DisplayVolumeDispensed(job.volumeDispensed)}</Text>
              </View>
            </View>
          </View>
          {/* Operator Sigantures */}
          <View style={styles.operatorSection}>
            <Text style={styles.operatorText}>Operator Signature:</Text>
            <View style={styles.operatorRow}>
              <Text style={styles.operatorText}>Applicator Name:</Text>
              <Text style={styles.operatorInfoText}>
                {job.deviceAssignments.map((x) => x.user.fullName).join(', ')}
              </Text>
            </View>
          </View>
          {/* Page 2 Footer */}
          <View style={styles.footer2}>
            <View style={styles.footerImage}>
              <Image src={logo} source={'Rapid Spray Logo'} />
            </View>
            <View style={styles.footerBox}>
              <View style={styles.footerLine}></View>
              <View style={styles.footerTextBox}>
                <Text style={styles.footerText}>{job.organisation.name}</Text>
                <Text style={styles.footerText}>Page 2 of 2</Text>
                <Text style={styles.footerText}>Document Date: {now}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
