import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useGetWeedsByOrganisationIdQuery, Weed, WeedFilterInput, WeedSortInput } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import WeedFormDialog from '../components/WeedFormDialog';
import Thumbnail from '../../../shared/components/thumbnail/Thumbnail';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import DefaultThumbnail from '../../../shared/components/thumbnail/DefaultThumbnail';

export default function WeedsPage() {
  const [showCreateWeed, setShowCreateWeed] = useState<boolean>(false);

  const currentOrganisation = useOrganisationId();

  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<WeedFilterInput>({
    searchableFieldNames: ['name', 'species', 'description'],
  });

  const { data, loading, fetchMore } = useGetWeedsByOrganisationIdQuery({
    variables: {
      id: currentOrganisation,
      where: where,
      order: order,
    },
  });

  const tableCols = [
    {
      title: '',
      id: 'thumbnail',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => (
        <div className="flex justify-center w-min">
          {x.imageUrl ? (
            <Thumbnail className="w-24 h-24 mx-4" imageUrl={x.imageUrl} />
          ) : (
            //default thumbnail
            <DefaultThumbnail className="w-24 h-24 mx-4" />
          )}
        </div>
      ),
    },
    {
      title: 'Name',
      id: 'name',
      accessor: (x) => x.name,
    },
    {
      title: 'Species',
      id: 'species',
      accessor: (x) => x.species,
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
  ] as Array<RSTableColumnDefinition<Weed>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Weeds'}
          actions={
            <PermissionGuard policy={AuthPolicy.WeedManagement}>
              <RSButton onClick={() => setShowCreateWeed(true)}>
                <FontAwesomeIcon icon={faPlus} />
                Add Weed
              </RSButton>
            </PermissionGuard>
          }
        >
          <SearchSortFilterBar<WeedSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['name', 'species', 'description']}
          />
          <RSTable<Weed>
            isLoading={loading}
            columns={tableCols}
            data={(data?.weedsByOrganisationId?.edges?.map((e) => e.node) as Weed[]) ?? []}
            totalCount={data?.weedsByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.weedsByOrganisationId?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.WeedManagement} isVisible={showCreateWeed}>
        <WeedFormDialog
          currentOrganisation={currentOrganisation}
          isOpen={true}
          onClose={() => setShowCreateWeed(false)}
        />
      </PermissionGuard>
    </>
  );
}
