import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { menuPopOutAnimation } from '../../constants/HeadlessAnimationConstants';

export interface DropDownOption<T> {
  value: T;
  displayValue?: string;
  onClick?: (arg0: T) => void;
}

export interface DropDownMenuProps<T> {
  initialSelection?: DropDownOption<T>;
  options: Array<DropDownOption<T>> | undefined;
  includeNullSelection?: boolean;
  isActive?: boolean;
  icon?: IconProp;
  className?: string;
  onChange?: (value: T) => void;
}

const emptyOption = { value: undefined, displayValue: 'Select One' };
export default function DropDownMenu<T>({
  initialSelection = emptyOption as any,
  includeNullSelection = initialSelection == null,
  isActive,
  options,
  icon,
  className,
  onChange,
}: DropDownMenuProps<T>) {
  const [selection, setSelection] = useState<DropDownOption<T>>(initialSelection);
  function optionToText(option: DropDownOption<T>) {
    return <>{option.displayValue ?? option.value}</>;
  }

  return (
    <Listbox
      value={selection.value}
      onChange={(e: any) => {
        setSelection(e);
        onChange && onChange(e.value);
      }}
    >
      <div className="flex relative w-full">
        <Listbox.Button type="button" className={`${!icon && 'pl-4'} drop-down-button text-icon-gray  ${className}`}>
          {icon && (
            <FontAwesomeIcon icon={icon} id="icon-left" className={`text-lg mx-3  ${isActive && 'text-primary'}`} />
          )}
          <div className={`drop-down-text`}>{optionToText(selection)}</div>
          <FontAwesomeIcon id="icon-chevron" icon={faChevronDown} className={`mx-3 ${isActive && 'text-primary'}`} />
        </Listbox.Button>
        <Transition as={Fragment} {...menuPopOutAnimation}>
          <Listbox.Options className="drop-down-options group z-30">
            {includeNullSelection && (
              <Listbox.Option key="None" value={emptyOption}>
                {({ active }) => (
                  <button
                    type="button"
                    className={`${
                      active ? 'bg-primary text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Select One
                  </button>
                )}
              </Listbox.Option>
            )}
            {options?.map((option, id) => {
              return (
                <Listbox.Option key={id} value={option}>
                  {({ active, selected }) => (
                    <button
                      type="button"
                      className={`${active || selected ? 'bg-primary text-white' : 'text-gray-900'} drop-down-option`}
                      onClick={() => option.onClick?.(option.value)}
                    >
                      {optionToText(option)}
                    </button>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
