import moment from 'moment';
import { Overlay } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import { useContext, useEffect, useRef, useState } from 'react';
import { SprayLogDto, Weed } from '../../../../graphql/generated';
import MapContext from '../../../../shared/components/maps/Map/MapContext';
import { RSTag } from '../../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../../shared/constants/LocalRoutingConstants';
import { SprayLogPropertyName, WeedPropertyName } from './JobMap';
import './SprayLogOverlay.scss';
import { GetFullUnitDisplay } from '../../../../shared/utilities/UnitConversionUtilities';

interface LogOverlayProps {
  feature?: FeatureLike;
}

export default function LogOverlay({ feature }: LogOverlayProps) {
  const map = useContext(MapContext);
  const overlayElement = useRef<HTMLDivElement>(null);
  const [overlay, setOverlay] = useState<Overlay>();

  useEffect(() => {
    if (!map) return;

    const overlay = new Overlay({
      element: overlayElement.current ?? undefined,
      positioning: 'bottom-center',
      offset: [0, -7.5],
    });

    map.addOverlay(overlay);
    setOverlay(overlay);

    return () => {
      if (map) {
        map.removeOverlay(overlay);
        setOverlay(undefined);
      }
    };
  }, [map]);

  // Update the position if the feature changes
  useEffect(() => {
    feature && overlay?.setPosition((feature.getGeometry() as Point).getCoordinates());
  }, [feature?.getGeometry()]);

  const sprayLog = feature ? (feature.get(SprayLogPropertyName) as SprayLogDto) : undefined;
  const weed = feature ? (feature.get(WeedPropertyName) as Weed | undefined) : undefined;

  return (
    <div className={`spraylog-overlay${sprayLog ? '' : ' hidden'}`} ref={overlayElement}>
      {sprayLog && (
        <div className="flex flex-col flex-grow">
          <p>
            <span className="font-bold">Time:</span> {moment(sprayLog.t).toLocaleString()}
          </p>
          <p>
            <span className="font-bold">Volume:</span> {GetFullUnitDisplay(sprayLog.v, 2)}
          </p>
          {weed && (
            <p>
              <span className="font-bold">Weed: </span>
              <RSTag
                title={weed.name}
                className="bg-gray-300"
                onClick={() => window.open(`${localRoutingConstants.weeds.root}/${weed.id}`)}
              />
            </p>
          )}
        </div>
      )}
    </div>
  );
}
