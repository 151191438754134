import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { faCheckCircle, faCircleExclamation, IconDefinition } from '@fortawesome/free-solid-svg-icons';

type RSNotificationType = 'primary' | 'danger';

type Props = {
  variant?: RSNotificationType;
  title?: string;
  children?: Array<ReactNode> | ReactNode;
};

const notificationTypeToClass: { [type in RSNotificationType]: string } = {
  danger: 'bg-red-100 border-2 rounded-md text-red-900 px-4 py-3 shadow-md',
  primary: 'bg-green-100 border-2 rounded-md text-green-900 px-4 py-3 shadow-md',
};

const notificationTypeToIcon: { [type in RSNotificationType]: IconDefinition } = {
  danger: faCircleExclamation,
  primary: faCheckCircle,
};

const notificationTypeToIconClass: { [type in RSNotificationType]: string } = {
  danger: 'fill-current h-5 w-5 text-red-500 mr-4',
  primary: 'fill-current h-5 w-5 text-green-500 mr-4',
};

export default function RSNotification(props: Props) {
  return (
    <div className={notificationTypeToClass[props.variant ?? 'primary']} role="alert">
      <div className="flex">
        <div className="py-1">
          <FontAwesomeIcon
            icon={notificationTypeToIcon[props.variant ?? 'primary']}
            className={notificationTypeToIconClass[props.variant ?? 'primary']}
            viewBox="0 0 500 500"
          />
        </div>
        <div>
          <p className="font-bold">{props.title}</p>
          {props.children}
        </div>
      </div>
    </div>
  );
}
