import ReactECharts from 'echarts-for-react';
import { useMemo } from 'react';
import { ChemicalType, Job, UnitMeasurement } from '../../../graphql/generated';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import {
  CalculateAmountUsed,
  concentrationUnitUsageForChemicalType,
  ConcentrationYAxis,
} from '../../recipes/utilities/ConcentrationUtilities';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';

interface RecipeUsageGraphProps {
  job: Job;
  totalVolumeSprayed: UnitMeasurement;
}

export default function JobRecipeUsageGraph({ job, totalVolumeSprayed }: RecipeUsageGraphProps) {
  const { unitSystem } = useSelectedOrganisationIdStore();

  const options = useMemo(() => {
    return {
      xAxis: {
        type: 'category',
        data: job.recipe?.concentrations.map((x) => x?.chemical.name),
        name: 'Chemicals',
      },
      yAxis: {
        type: 'value',
        name: ConcentrationYAxis(unitSystem),
      },
      series: [
        {
          type: 'bar',
          data: job.recipe?.concentrations.map((x) => {
            return CalculateAmountUsed(totalVolumeSprayed, x.amount);
          }),
          label: {
            show: true,
            position: 'inside',
            formatter: (value: { data: number; dataIndex: number }) => {
              const val = value.data as number;
              return val !== 0
                ? `${(value.data as number).toFixed(2)} ${GetUnitSymbol(
                    concentrationUnitUsageForChemicalType[
                      job.recipe?.concentrations[value.dataIndex].chemical.type ?? ChemicalType.Liquid
                    ][unitSystem],
                  )}`
                : '';
            },
          },
        },
      ],
    };
  }, [job.recipe]);

  return totalVolumeSprayed && job.recipe ? (
    <ReactECharts option={options} />
  ) : (
    <div className="flex flex-row justify-center h-full w-full p-8">
      Chemical Usage unavailable - no spray data found and/or no recipe set.
    </div>
  );
}
