import { ReactElement, useMemo, useState } from 'react';
import RSButton from '../../components/input/rs-button/RSButton';
import GridTile from '../tiles/GridTile';

interface TabItem {
  label: string;
  view: ReactElement;
}

interface RSTabViewProps {
  items: Array<TabItem>;
  defaultItem: number;
  colSpan?: number;
  title: string;
  className?: string;
}

export default function RSTabView(props: RSTabViewProps) {
  const [selectedTab, setSelectedTab] = useState<number>(props.defaultItem);

  const actions = useMemo(
    () =>
      props.items.map((item, index) => (
        <RSButton
          key={`action.${index}`}
          variant={selectedTab == index ? 'primary' : 'secondary'}
          onClick={() => setSelectedTab(index)}
        >
          {item.label}
        </RSButton>
      )),
    [props.items, selectedTab],
  );

  return (
    <GridTile colSpan={props.colSpan ?? 6} title={props.title} actions={actions} className={props.className}>
      {props.items[selectedTab].view}
    </GridTile>
  );
}
