import moment from 'moment';
import Control from 'ol/control/Control';
import { useContext, useEffect, useRef, useState } from 'react';
import { Job, JobStatus } from '../../../../graphql/generated';
import RSButton from '../../../../shared/components/input/rs-button/RSButton';
import MapContext from '../../../../shared/components/maps/Map/MapContext';
import CSVGenerator from '../../../../shared/CSVUtilities';
import { weedValueToButtonsPressed } from '../../utilities/WeedButtonUtilities';

interface ExportControlProps {
  job: Job;
}

export const DeviceAssignmentLayersType = 'DeviceAssignment';

interface JobDataExport {
  jobId: string;
  organisationId: string;
  organisationName: string;
  name: string;
  description: string;
  status: JobStatus;
  startTime: string;
  endTime: string;
  address: string;
  latitude: number;
  longitude: number;
  volumeDispensed: number;
  createdAt: string;
  lastUpdatedAt: string;
  completedAt: string;
  recipeId: string;
  recipeName: string;
}

interface WeatherDataExport {
  timestamp: string;
  deltaT: number;
  temperature: number;
  humidity: number;
  windSpeed: number;
  windBearing: number;
}

interface LogDataExport {
  deviceId: string;
  deviceAlias: string;
  operatorId: string;
  operatorName: string;
  volume: number;
  time: string;
  latitude: number;
  longitude: number;
  weed1: boolean;
  weed2: boolean;
  weed3: boolean;
}

export default function ExportControl({ job }: ExportControlProps) {
  const map = useContext(MapContext);
  const controlElement = useRef<HTMLDivElement>(null);
  const [control, setControl] = useState<Control>();

  useEffect(() => {
    if (!map) return;

    const newControl = new Control({
      element: controlElement.current ?? undefined,
    });

    map.addControl(newControl);
    setControl(control);

    return () => {
      map.removeControl(newControl);
    };
  }, [map]);

  const getJobData = () => {
    const generator = new CSVGenerator<JobDataExport>({
      columns: {
        jobId: 'Job Id',
        organisationId: 'Organisation Id',
        organisationName: 'Organisation Name',
        name: 'Name',
        description: 'Description',
        status: 'Status',
        startTime: 'Start Time',
        endTime: 'End Time',
        address: 'Address',
        latitude: 'Latitude',
        longitude: 'Longitude',
        volumeDispensed: 'Volume Dispensed',
        createdAt: 'Created At',
        lastUpdatedAt: 'Last Updated At',
        completedAt: 'Completed At',
        recipeId: 'Recipe Id',
        recipeName: 'Recipe Name',
      },
    });
    generator.addRow({
      jobId: job.id,
      organisationId: job.organisation.id,
      organisationName: job.organisation.name,
      name: job.name,
      description: job.description,
      status: job.status,
      startTime: job.startTime,
      endTime: job.endTime,
      address: job.location?.address ?? 'Not Set',
      latitude: job.location?.latitude ?? NaN,
      longitude: job.location?.longitude ?? NaN,
      volumeDispensed: job.volumeDispensed?.value ?? NaN,
      createdAt: job.createdAt,
      lastUpdatedAt: job.lastUpdatedAt,
      completedAt: job.completedAt,
      recipeId: job.recipe?.id ?? 'Not Set',
      recipeName: job.recipe?.name ?? 'Not Set',
    });
    return generator.toString();
  };

  const getWeatherInformation = () => {
    const generator = new CSVGenerator<WeatherDataExport>({
      columns: {
        timestamp: 'Time',
        deltaT: 'Delta T',
        temperature: 'Temperature',
        humidity: 'Humidity',
        windSpeed: 'Wind Speed',
        windBearing: 'Wind Direction',
      },
    });
    job.weatherInformation.forEach((value) => {
      generator.addRow({
        timestamp: value.timestamp,
        deltaT: value.deltaT.value,
        temperature: value.temperature.value,
        humidity: value.humidity.value,
        windSpeed: value.windSpeed.value,
        windBearing: value.windBearing.value,
      });
    });
    return generator.toString();
  };

  // Get data from visible device assignment layers to create the csv
  const getLogData = () => {
    const generator = new CSVGenerator<LogDataExport>({
      columns: {
        deviceId: 'Device Id',
        deviceAlias: 'Device Alias',
        operatorId: 'Operator Id',
        operatorName: 'Operator Name',
        volume: 'Volume',
        time: 'Time',
        latitude: 'Latitude',
        longitude: 'Longitude',
        weed1: getWeedHeading(0),
        weed2: getWeedHeading(1),
        weed3: getWeedHeading(2),
      },
    });

    map?.getAllLayers().forEach((layer) => {
      // Filter layers that aren't visible and don't have spray logs
      if (!layer.getVisible() || layer.get('type') !== DeviceAssignmentLayersType) return;

      // Get the device assignment from the layer
      const deviceAssignmentIndex = layer.get('index') as number;
      const deviceAssignment = job.deviceAssignments[deviceAssignmentIndex];

      // Construct the csv data
      console.log(deviceAssignment);

      deviceAssignment.sprayLogs.forEach((sprayLog) => {
        const weedsFound = weedValueToButtonsPressed[sprayLog.w];
        generator.addRow({
          deviceId: deviceAssignment.device.id,
          deviceAlias: deviceAssignment.device.alias,
          operatorId: deviceAssignment.user.id,
          operatorName: deviceAssignment.user.fullName,
          volume: sprayLog.v.value,
          time: sprayLog.t,
          latitude: sprayLog.c.y,
          longitude: sprayLog.c.x,
          weed1: weedsFound.includes(1),
          weed2: weedsFound.includes(2),
          weed3: weedsFound.includes(3),
        });
      });
    });

    return generator.toString();
  };

  const getWeedHeading = (index: number) => `Weed ${index + 1} - ${job.weedAssignments[index]?.name ?? 'Not Set'}`;

  return (
    <div ref={controlElement} className="right-2 top-2 absolute">
      <RSButton
        onClick={() => {
          // Download the csv
          const blob = new Blob(
            [
              [
                'Job Overview',
                getJobData(),
                '',
                'Weather Information',
                getWeatherInformation(),
                '',
                'Device Assignments',
                getLogData(),
              ].join('\n'),
            ],
            {
              type: 'text/csv',
            },
          );
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('href', url);
          a.setAttribute('download', `${job.name}-${moment().toLocaleString()}.csv`);
          a.click();
        }}
      >
        Export
      </RSButton>
    </div>
  );
}
