import { Form, Formik } from 'formik';
import JSZip from 'jszip';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { OptionalObjectSchema } from 'yup/lib/object';
import { CreateFirmwareInput, CreateFirmwareOutput } from '../../../graphql/generated';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FileUploader from '../../../shared/components/forms/form-file-uploader/FileUploader';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { MaximumDescriptionLength, MaximumNameLength } from '../../../shared/constants/ValidationConstants';
import useBlocker from '../../../shared/hooks/UseBlocker';
import UseUploadFile from '../../../shared/hooks/UseFileUpload';
import { ServerErrorCode, getErrorType } from '../../../shared/utilities/ApolloErrorUtils';
import UploadFileProgressCard from '../../weeds/components/WeedUploadProgressCard';

type Props = {
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: () => void;
  onSubmit: (value: CreateFirmwareInput) => Promise<CreateFirmwareOutput>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
};

export type CreateFirmwareForm = {
  description: string;
  version: string;
  firmwareUploadSlot1?: boolean;
  firmwareUploadSlot2?: boolean;
};

export default function CreateFirmwareFormContent({ validator, onClose, onSubmit, isOpen }: Props) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  const [uploadSlot1, setUploadSlot1] = useState<File>();
  const [uploadSlot2, setUploadSlot2] = useState<File>();
  const [uploadError, setUploadError] = useState<string>('');

  const { uploadProgress, uploadFile } = UseUploadFile({
    onError: (msg) => {
      setUploadError(msg);
    },
  });

  if (uploadProgress && !uploadProgress.complete) {
    return (
      <RSDialog title={''} isOpen={true}>
        <UploadFileProgressCard
          error={uploadError}
          loaded={uploadProgress.loaded}
          total={uploadProgress.total}
          fileName={uploadSlot1?.name as string}
        />
      </RSDialog>
    );
  }

  return (
    <Formik<CreateFirmwareForm>
      initialValues={{
        description: '',
        version: '',
        firmwareUploadSlot1: undefined,
        firmwareUploadSlot2: undefined,
      }}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers) => {
        try {
          const zip = JSZip();

          const firmware = await onSubmit(values);

          if (uploadSlot1 && uploadSlot2) {
            zip.file('slot1', uploadSlot1);
            zip.file('slot2', uploadSlot2);
            const zipped = await zip.generateAsync({
              type: 'blob',
              compression: 'DEFLATE',
              compressionOptions: { level: 8 },
            });
            const zippedFiles = new File([zipped], 'upload');
            await uploadFile(zippedFiles, firmware.uploadUrl);
            helpers.resetForm();

            setUploadSlot1(undefined);
            setUploadSlot2(undefined);
            onClose();
          }
        } catch (ex) {
          if (getErrorType(ex) === ServerErrorCode.RESOURCE_ALREADY_EXISTS) {
            toast(`The request could not be fulfilled because a firmware with the requested version already exists`, {
              type: 'error',
            });
          } else {
            toast('An unexpected error occurred whilst trying to create the Firmware', {
              type: 'error',
            });
          }
        }
        helpers.resetForm();
        setUploadSlot1(undefined);
        setUploadSlot2(undefined);
        onClose();
      }}
    >
      {(formik) => {
        const onCloseWrapper = () => {
          formik.resetForm();
          setUploadSlot1(undefined);
          setUploadSlot2(undefined);
          onClose();
        };
        return (
          <RSDialog
            title={'Add Firmware'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={onCloseWrapper}
                />
                <RSButton onClick={formik.submitForm}>Add</RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Version"
                name="version"
                placeholder="1.0.0"
                maxLength={MaximumNameLength}
              />
              <FormTextAreaInput
                required
                label="Description"
                name="description"
                placeholder="Patch Notes"
                maxLength={MaximumDescriptionLength}
              />
              <FileUploader
                accept={{
                  'application/octet-stream': ['.bin'],
                }}
                name="firmwareUploadSlot1"
                label="Slot 1 Binary"
                required
                onFileSelected={setUploadSlot1}
                selectedFile={uploadSlot1}
                onFileRemove={() => {
                  setUploadSlot1(undefined);
                }}
                messageSuffix=" a binary file (bin)"
              />
              <FileUploader
                accept={{
                  'application/octet-stream': ['.bin'],
                }}
                name="firmwareUploadSlot2"
                label="Slot 2 Binary"
                required
                onFileSelected={setUploadSlot2}
                selectedFile={uploadSlot2}
                onFileRemove={() => {
                  setUploadSlot2(undefined);
                }}
                messageSuffix=" a binary file (bin)"
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
