import moment from 'moment';
import { Overlay } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import { useContext, useEffect, useRef, useState } from 'react';
import { DeviceAssignment, Job, SprayLogSummary } from '../../../graphql/generated';
import MapContext from '../../../shared/components/maps/Map/MapContext';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import { DeviceAssignmentPropertyName, JobPropertyName, LogPropertyName } from '../pages/WeedsMap';
import './WeedOverlay.scss';

interface WeedOverlayProps {
  feature?: FeatureLike;
}

interface WeedFeature {
  job: Job;
  log: SprayLogSummary;
  deviceAssignment: DeviceAssignment;
}

export default function WeedOverlay({ feature }: WeedOverlayProps) {
  const map = useContext(MapContext);
  const overlayElement = useRef<HTMLDivElement>(null);
  const [overlay, setOverlay] = useState<Overlay>();

  useEffect(() => {
    if (!map) return;

    const overlay = new Overlay({
      element: overlayElement.current ?? undefined,
      positioning: 'bottom-center',
      offset: [0, -7.5],
    });

    map.addOverlay(overlay);
    setOverlay(overlay);

    return () => {
      if (map) {
        map.removeOverlay(overlay);
        setOverlay(undefined);
      }
    };
  }, [map]);

  // Update the position if the feature changes
  useEffect(() => {
    feature && overlay?.setPosition((feature.getGeometry() as Point).getCoordinates());
  }, [feature?.getGeometry()]);

  const weedFeature = feature ? getTypeSafeFeature(feature) : undefined;

  return (
    <div className={`weed-overlay${weedFeature ? '' : ' hidden'}`} ref={overlayElement}>
      {weedFeature && (
        <div className="flex flex-col flex-grow">
          <p>
            <span className="font-bold">Coordinates (Lat,Lon): </span>
            {`${weedFeature.log.c.x.toFixed(3)}, ${weedFeature.log.c.y.toFixed(3)}`}
          </p>
          <p>
            <span className="font-bold">Time: </span> {moment(weedFeature.log.t).toLocaleString()}
          </p>
          <p>
            <span className="font-bold">Job: </span>
            <RSTag
              title={weedFeature.job.name}
              className="bg-gray-300"
              onClick={() => window.open(`${localRoutingConstants.jobs.root}/${weedFeature.job.id}`)}
            />
          </p>
          <p>
            <span className="font-bold">Recipe: </span>
            <RSTag
              title={weedFeature.job.recipe?.name ?? ''}
              className="bg-gray-300"
              onClick={() => window.open(`${localRoutingConstants.recipes.root}/${weedFeature.job.recipe?.id}`)}
            />
          </p>
          <p>
            <span className="font-bold">Device: </span>
            <RSTag
              title={weedFeature.deviceAssignment.device.alias ?? ''}
              className="bg-gray-300"
              onClick={() =>
                window.open(`${localRoutingConstants.devices.root}/${weedFeature.deviceAssignment.device.id}`)
              }
            />
          </p>
          <p>
            <span className="font-bold">Operator: </span>
            <RSTag
              title={weedFeature.deviceAssignment.user.fullName ?? ''}
              className="bg-gray-300"
              onClick={() => window.open(`${localRoutingConstants.users.root}/${weedFeature.deviceAssignment.user.id}`)}
            />
          </p>
        </div>
      )}
    </div>
  );
}

function getTypeSafeFeature(feature: FeatureLike) {
  return {
    job: feature.get(JobPropertyName) as Job,
    log: feature.get(LogPropertyName) as SprayLogSummary,
    deviceAssignment: feature.get(DeviceAssignmentPropertyName) as DeviceAssignment,
  } as WeedFeature;
}
