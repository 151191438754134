import Modify, { ModifyEvent, Options } from 'ol/interaction/Modify';
import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';

interface ModifyInteractionProps {
  options: Options;
  onModifyEnd?: (event: ModifyEvent) => void;
}

export default function ModifyInteraction({ options, onModifyEnd }: ModifyInteractionProps) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const interaction = new Modify(options);

    interaction.on(['modifyend'], (event) => onModifyEnd && onModifyEnd(event as ModifyEvent));

    map.addInteraction(interaction);

    return () => {
      if (map) {
        map.removeInteraction(interaction);
      }
    };
  }, [map, options]);

  return null;
}
