import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import UserFormDialog from '../components/UserFormDialog';
import { User, useAdminGetUsersQuery, UserStatus, UserFilterInput, UserSortInput } from '../../../graphql/generated';
import { userRoleToText } from '../utilities/UserRoleUtilities';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { userStatusToText, userStatusToTailwindClassName } from '../utilities/UserStatusUtilities';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import { enabledToTailwindClassName, enabledToText } from '../../../shared/utilities/EnabledValueUtilities';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';

export default function UserManagementPage() {
  const currentOrganisation = useOrganisationId();

  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<UserFilterInput>({
    searchableFieldNames: ['email', 'fullName', 'firstName', 'lastName'],
  });

  const enabledFilter = { enabled: { eq: true } };
  const updatedWhere = where ? { and: [where, enabledFilter] } : enabledFilter; // TODO maybe include logic for this in the hook
  const { data, loading, fetchMore } = useAdminGetUsersQuery({
    variables: {
      id: currentOrganisation as string,
      where: updatedWhere,
      order: order,
    },
  });

  const [showInvite, setShowInvite] = useState<boolean>(false);

  const userTableCols = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => `${x.firstName} ${x.lastName}`,
      id: 'name',
    },
    {
      title: 'Email',
      id: 'email',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.email,
    },
    {
      title: 'Role',
      id: 'role',
      accessor: (x) => userRoleToText[x.role],
    },
    {
      title: 'Enabled',
      id: 'enabled',
      accessor: (x) => <RSTag title={enabledToText(x.enabled)} className={enabledToTailwindClassName(x.enabled)} />,
    },
    {
      title: 'Status',
      id: 'status',
      accessor: (x) => (
        <RSTag
          title={userStatusToText[x.userStatus as UserStatus]}
          className={userStatusToTailwindClassName[x.userStatus as UserStatus]}
        />
      ),
    },
  ] as Array<RSTableColumnDefinition<User>>;

  return (
    <>
      {/* User Table */}
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Users'}
          actions={
            <PermissionGuard policy={AuthPolicy.OrganisationManagement}>
              <RSButton onClick={() => setShowInvite(true)}>
                <FontAwesomeIcon icon={faPlus} />
                Add
              </RSButton>
            </PermissionGuard>
          }
        >
          <SearchSortFilterBar<UserSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['email', 'firstName', 'lastName', 'role', 'userStatus']}
          />
          <RSTable<User>
            isLoading={loading}
            columns={userTableCols}
            data={(data?.usersByOrganisationId?.edges?.map((e) => e.node) as User[]) ?? []}
            totalCount={data?.usersByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.usersByOrganisationId?.pageInfo.endCursor as string) : undefined,
                  where: updatedWhere,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.OrganisationManagement} isVisible={showInvite}>
        <UserFormDialog isOpen={true} onClose={() => setShowInvite(false)} isOrganisational={true} />
      </PermissionGuard>
    </>
  );
}
