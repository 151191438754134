import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { toast } from 'react-toastify';
import { OptionalObjectSchema } from 'yup/lib/object';
import {
  Chemical,
  ChemicalFilterInput,
  ChemicalSearchQuery,
  ChemicalSearchQueryVariables,
  ChemicalType,
  Concentration,
  Recipe,
  UnitType,
  useChemicalSearchQuery,
} from '../../../graphql/generated';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FormListInput from '../../../shared/components/forms/form-list-input/FormListInput';
import FormUnitInput from '../../../shared/components/forms/form-number-input/FormUnitInput';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import { ComboBoxItem } from '../../../shared/components/input/combo-box/ComboBox';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { MaximumDescriptionLength, MaximumLongNameLength } from '../../../shared/constants/ValidationConstants';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { concentrationUnitsForChemicalType } from '../utilities/ConcentrationUtilities';
import { GetDefaultConcentration } from '../validation/RecipeValidator';

type Props<Recipe, TFormInput extends FormikValues> = {
  initialValues?: Recipe;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: () => void;
  onSubmit: (values: TFormInput) => Promise<void>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
  currentOrganisation: string;
  initialValuesMapper: (initialValues: Recipe | undefined) => TFormInput;
};

// Abstracted user form allowing for the same structure to be used for editing and creating a recipe
export default function RecipeFormContent<TFormInput extends FormikValues>({
  currentOrganisation,
  isEdit,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
  initialValuesMapper,
}: Props<Recipe, TFormInput>) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);
  const { unitSystem } = useSelectedOrganisationIdStore();

  return (
    <Formik<TFormInput>
      initialValues={initialValuesMapper(initialValues)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers: FormikHelpers<TFormInput>) => {
        try {
          await onSubmit(values);
          helpers.resetForm();
        } catch (ex) {
          console.log(ex);
          toast(`An unexpected error occurred whilst trying to ${initialValues ? 'update' : 'create'} the recipe`, {
            type: 'error',
          });
        }
        onClose();
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title={isEdit ? 'Edit Recipe' : 'Add Recipe'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={() => {
                    formik.resetForm();
                    onClose();
                  }}
                />
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  {isEdit ? 'Update' : 'Add'}
                </RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Name"
                name="name"
                placeholder="Name of recipe"
                maxLength={MaximumLongNameLength}
              />
              <FormTextAreaInput
                required
                label="Description"
                name="description"
                placeholder="Write a description for the recipe here..."
                maxLength={MaximumDescriptionLength}
              />
              <FormListInput
                required
                minItems={1}
                name={'concentrations'}
                label={'Chemical Concentrations'}
                items={formik.values.concentrations}
                newItem={GetDefaultConcentration(unitSystem)}
                rowChildrenBuilder={(_, index) => {
                  const hasLabel = index == 0;
                  const concentrations = initialValues?.concentrations
                    ? (initialValues?.concentrations as Concentration[])
                    : formik.values.concentrations ?? [];

                  const units: UnitType | undefined = concentrations[index]?.amount?.unit;
                  return (
                    <>
                      <FormComboBoxWithQuery<
                        Chemical,
                        ChemicalSearchQuery,
                        ChemicalSearchQueryVariables,
                        ChemicalFilterInput
                      >
                        initialValue={{
                          value: concentrations[index]?.chemical?.id ?? '',
                          displayValue: concentrations[index]?.chemical?.name ?? '',
                        }}
                        label={hasLabel ? 'Chemical' : undefined}
                        required={hasLabel}
                        name={`concentrations.${index}.chemicalId`}
                        dataAccessorCallback={(data) =>
                          selectedChemicalFilter(
                            data?.chemicalsByOrganisationId?.nodes?.map((chem) => ({
                              displayValue: chem.name,
                              value: chem.id,
                              item: chem,
                            })) ?? [],
                            formik.values.concentrations,
                            index,
                          ) as ComboBoxItem<Chemical>[]
                        }
                        searchFieldNames={['name', 'description', 'manufacturer', 'activeIngredient']}
                        query={useChemicalSearchQuery}
                        variables={{ id: currentOrganisation }}
                        onChangeHandler={(item) => {
                          const value = formik.values.concentrations[index].amount.value;

                          const newUnit =
                            concentrationUnitsForChemicalType[item?.item?.type as ChemicalType][unitSystem];
                          formik.setFieldValue(`concentrations.${index}.amount.unit`, newUnit);
                          formik.setFieldValue(`concentrations.${index}.amount.value`, value);

                          formik.setFieldValue(
                            `concentrations.${index}.units`,
                            concentrationUnitsForChemicalType[item?.item?.type as ChemicalType][unitSystem],
                          );
                        }}
                      />
                      <FormUnitInput
                        required={hasLabel}
                        min={0}
                        name={`concentrations.${index}.amount`}
                        label={hasLabel ? `Concentration` : undefined}
                        defaultUnit={units ?? UnitType.GramsPerHundredLitres}
                      />
                    </>
                  );
                }}
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}

function selectedChemicalFilter(chemicals: ComboBoxItem[], selected: { chemicalId: string }[], index: number) {
  return chemicals.filter(
    (x) => selected[index].chemicalId == x.value || !selected.some((s) => s.chemicalId == x.value),
  );
}
