import { ReactNode } from 'react';
import { Dialog } from '@headlessui/react';

type Props = {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  actions?: ReactNode | ReactNode[];
  children?: Array<ReactNode> | ReactNode;
};

//TODO: take render props providing access to close callback
export default function RSDialog({ title, isOpen, onClose, children, actions }: Props) {
  // Don't render anything if the dialog isn't open
  if (!isOpen) {
    return <> </>;
  }

  return (
    <Dialog open={isOpen} onClose={onClose ? onClose : () => {}} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-scroll">
        {/* Container to center the panel */}
        <div className="flex flex-row border-border-gray border-b py-4 px-4 min-h-full items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="rs-modal">
            <Dialog.Title>{title}</Dialog.Title>
            {children}
            <div className="flex flex-row justify-end gap-x-2 pt-4">{actions}</div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
