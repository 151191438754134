import moment from 'moment';
import * as Yup from 'yup';
import { ApplicationType, UnitType } from '../../../graphql/generated';
import {
  DeviceIdLength,
  MaximumDescriptionLength,
  MaximumNameLength,
  MongoIdLength,
  UserIdLength,
} from '../../../shared/constants/ValidationConstants';
import { UnitGreaterThan, ValidateUnit } from '../../../shared/validations/unitValidation';

export const defaultDeviceAssignment = { deviceId: '', userId: '' };

export const jobValidator = Yup.object({
  organisationId: Yup.string().required().length(MongoIdLength),
  name: Yup.string().required('Name is required.').max(MaximumNameLength).default(''),
  description: Yup.string().required('Description is required').max(MaximumDescriptionLength).default(''),
  startTime: Yup.date(),
  endTime: Yup.date().when('startTime', (startTime: Date) =>
    startTime
      ? Yup.date()
          .min(startTime, 'End Time must be after Start Time')
          .max(moment(startTime).add(1, 'day').add(1, 'second'), 'End Time must be less than one day after Start Time')
      : Yup.date(),
  ),
  deviceAssignments: Yup.array(
    Yup.object({
      deviceId: Yup.string().required('Device must be provided.').length(DeviceIdLength).default(''),
      userId: Yup.string().required('User must be provided.').length(UserIdLength).default(''),
    }),
  ).default([]),
  applicationType: Yup.string().oneOf(Object.values(ApplicationType)).default(ApplicationType.Unspecified),
  weedAssignments: Yup.array(Yup.string().nullable().length(MongoIdLength).default(null)).default([null, null, null]),
  recipeId: Yup.string().length(MongoIdLength),
  location: Yup.object({
    latitude: Yup.number().required().min(-90).max(90),
    longitude: Yup.number().required().min(-180).max(180),
    address: Yup.string().required().max(MaximumDescriptionLength),
  })
    .nullable()
    .default(undefined),
});

export const jobUpdateValidator = jobValidator
  .shape({
    id: Yup.string().required().length(MongoIdLength),
  })
  .omit(['organisationId']);

export const UpdateSprayAreaBounds = {
  nozzleWidth: { min: 0, validationUnit: UnitType.Metre },
};

export const updateSprayAreaValidator = Yup.object({
  nozzleWidth: UnitGreaterThan(
    ValidateUnit(),
    UpdateSprayAreaBounds.nozzleWidth.validationUnit,
    UpdateSprayAreaBounds.nozzleWidth.min,
    0,
  ),
  jobId: Yup.string().length(MongoIdLength).required().default(''),
});
