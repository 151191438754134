import { FirmwareLockStatus, FirmwareStatus } from '../generated';

export const FirmwareLockStatusToDisplayName: { [key in FirmwareLockStatus]: string } = {
  [FirmwareLockStatus.Locked]: 'Locked',
  [FirmwareLockStatus.Unlocked]: 'Unlocked',
};

export const FirmwareStatusToDisplayName: { [key in FirmwareStatus]: string } = {
  [FirmwareStatus.Available]: 'Available',
  [FirmwareStatus.Deprecated]: 'Deprecated',
  [FirmwareStatus.Error]: 'Error',
  [FirmwareStatus.Uploading]: 'Uploading',
};
