import * as Yup from 'yup';
import {
  MaximumLongNameLength,
  MaximumNameLength,
  MongoIdLength,
  MaximumLongDescriptionLength,
} from '../../../shared/constants/ValidationConstants';

export const weedValidator = Yup.object({
  organisationId: Yup.string().required().length(MongoIdLength).default(''),
  name: Yup.string().required().max(MaximumNameLength).default(''),
  species: Yup.string().required().max(MaximumLongNameLength).default(''),
  description: Yup.string().required().max(MaximumLongDescriptionLength).default(''),
  updateImage: Yup.mixed().default(undefined),
});

export const weedUpdateValidator = weedValidator
  .shape({
    weedId: Yup.string().required().length(MongoIdLength),
  })
  .omit(['organisationId', 'updateImage']);
