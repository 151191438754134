import create from 'zustand';

interface SidebarState {
  showSidebar: boolean;
  closeSidebar: () => void;
  openSidebar: () => void;
  toggleSidebar: () => void;
}

const useSidebarStore = create<SidebarState>((set) => ({
  showSidebar: window.innerWidth >= 1280,
  closeSidebar: () => set({ showSidebar: false }),
  openSidebar: () => set({ showSidebar: true }),
  toggleSidebar: () =>
    set((state) => ({
      showSidebar: !state.showSidebar,
    })),
}));

export default useSidebarStore;
