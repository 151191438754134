import { DocumentNode } from '@apollo/client';
import apolloClient from './ApolloClient';
import { camelCaseToPascalCase } from './TextUtilities';

type QueryNameType<TQuery> = Extract<keyof Omit<TQuery, '__typename'>, string>;

interface PageInfo {
  __typename: string;
  startCursor: string;
  endCursor: string;
}

interface Edge<TEntity> {
  node: TEntity;
  __typename: string;
}

interface Model {
  id: string;
}

interface PagedResult<TEntity> {
  edges: Edge<TEntity>[];
  pageInfo: PageInfo;
  totalCount: number;
  __typename: string;
}

export default function updateQuery<TEntity, TQuery, TQueryVariables>(
  entity: TEntity,
  document: DocumentNode,
  cacheEntitiesCallback: (query: TQuery | null) => Array<TEntity>,
  queryName: QueryNameType<TQuery>,
  variables?: TQueryVariables,
) {
  apolloClient.cache.updateQuery<TQuery>(
    { query: document, variables },
    (query) =>
      ({
        [queryName]: cacheEntitiesCallback(query).concat({ ...(entity as TEntity) }),
      } as unknown as TQuery),
  );
}

export function updateQueryWithoutVariables<TEntity, TQuery>(
  entity: TEntity,
  document: DocumentNode,
  cacheEntitiesCallback: (query: TQuery | null) => Array<TEntity>,
  queryName: QueryNameType<TQuery>,
) {
  return updateQuery<TEntity, TQuery, unknown>(entity, document, cacheEntitiesCallback, queryName);
}

export function updatePagedQuery<TEntity, TQuery, TQueryVariables, TEdge>(
  entity: TEntity,
  document: DocumentNode,
  cacheEntitiesCallback: (query: TQuery | null) => Array<TEdge>,
  queryName: QueryNameType<TQuery>,
  variables?: TQueryVariables,
) {
  const edgeTypeName = camelCaseToPascalCase(queryName);
  apolloClient.cache.updateQuery<TQuery>({ query: document, variables }, (query) => {
    if (!query) {
      return;
    }

    // Extract the necessary fields from the query
    const { pageInfo, totalCount, __typename } = query[queryName] as PagedResult<TEntity>;

    // Update the edges and totalCount
    const updatedEdges = cacheEntitiesCallback(query).concat({
      node: { ...entity },
      __typename: edgeTypeName,
    } as TEdge);
    const updatedTotalCount = totalCount + 1;

    return {
      [queryName]: {
        __typename,
        edges: updatedEdges,
        pageInfo, // Ensure pageInfo is included in the updated result
        totalCount: updatedTotalCount,
      },
    } as TQuery;
  });
}

export function deletePagedQuery<TEntity extends Model, TQuery, TQueryVariables, TEdge>(
  entityId: string,
  document: DocumentNode,
  cacheEntitiesCallback: (query: TQuery | null) => Array<TEdge>,
  queryName: QueryNameType<TQuery>,
  variables?: TQueryVariables,
) {
  apolloClient.cache.updateQuery<TQuery>({ query: document, variables }, (query) => {
    if (!query) {
      return;
    }

    // Extract the necessary fields from the query
    const { pageInfo, totalCount, __typename } = query[queryName] as PagedResult<TEntity>;

    // Filter out the entity to be deleted from the edges
    const updatedEdges = cacheEntitiesCallback(query).filter((edge) => (edge as Edge<TEntity>).node.id !== entityId);
    const updatedTotalCount = totalCount - 1;

    return {
      [queryName]: {
        __typename,
        edges: updatedEdges,
        pageInfo, // Ensure pageInfo is included in the updated result
        totalCount: updatedTotalCount,
      },
    } as TQuery;
  });
}
