import { faFingerprint, faKey, faList } from '@fortawesome/free-solid-svg-icons';
import { CreateWebhookResponse } from '../../../graphql/generated';
import RSNotification from '../../../shared/components/rs-notification/RSNotification';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';

type PostCreateWebhookContentProps = {
  response: CreateWebhookResponse;
};

export function PostCreateWebhookContent({ response }: PostCreateWebhookContentProps) {
  const rows: RSPivotRowProps[] = [
    {
      name: 'Id',
      value: response.webhook.id,
      icon: faFingerprint,
    },
    {
      name: 'Api Key Header Name',
      value: response.apiKeyHeader,
      icon: faList,
    },
    {
      name: 'Api Key Value',
      value: response.apiKey,
      icon: faKey,
      textBehavior: 'wrap',
    },
  ];

  return (
    <div className="flex justify-start flex-col gap-2 py-3">
      <RSNotification title="Webhook Successfully Created" variant="primary">
        Please keep note of the webhook credentials below. This is the only time that webhook secret keys can be viewed
        or downloaded.
      </RSNotification>

      <RSPivotTable rows={rows} />
      <div className="w-full justify-center align-middle flex-row flex"></div>
    </div>
  );
}
