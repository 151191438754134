import {
  WeedSearchQuery,
  WeedSearchQueryVariables,
  useWeedSearchQuery,
  Weed,
  WeedFilterInput,
} from '../../../graphql/generated';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import FormLabel from '../../../shared/components/forms/FormLabel';

interface WeedAssignmentsInputProps {
  currentOrganisation: string;
  weedAssignments: Array<Weed> | undefined;
}

export default function WeedAssignmentsInput({ currentOrganisation, weedAssignments }: WeedAssignmentsInputProps) {
  const assignments = weedAssignments ?? [];
  return (
    <div className="flex flex-col">
      <FormLabel label="Weed Assignments" />
      <div className="border rounded-lg">
        <FormComboBoxWithQuery<Weed, WeedSearchQuery, WeedSearchQueryVariables, WeedFilterInput>
          initialValue={{
            value: assignments[0]?.id ?? '',
            displayValue: assignments[0]?.name ?? '',
          }}
          label="Button 1:"
          inLineLabel
          name={'weedAssignments.0'}
          dataAccessorCallback={(data) =>
            data?.weedsByOrganisationId?.nodes?.map((weed) => ({
              displayValue: weed.name,
              value: weed.id,
            })) ?? []
          }
          searchFieldNames={['name']}
          query={useWeedSearchQuery}
          variables={{ id: currentOrganisation }}
        />
        <FormComboBoxWithQuery<Weed, WeedSearchQuery, WeedSearchQueryVariables, WeedFilterInput>
          initialValue={{
            value: assignments[1]?.id ?? '',
            displayValue: assignments[1]?.name ?? '',
          }}
          label="Button 2:"
          inLineLabel
          name={'weedAssignments.1'}
          dataAccessorCallback={(data) =>
            data?.weedsByOrganisationId?.nodes?.map((weed) => ({
              displayValue: weed.name,
              value: weed.id,
            })) ?? []
          }
          searchFieldNames={['name']}
          query={useWeedSearchQuery}
          variables={{ id: currentOrganisation }}
        />
        <FormComboBoxWithQuery<Weed, WeedSearchQuery, WeedSearchQueryVariables, WeedFilterInput>
          initialValue={{
            value: assignments[2]?.id ?? '',
            displayValue: assignments[2]?.name ?? '',
          }}
          label="Button 3:"
          inLineLabel
          name={'weedAssignments.2'}
          dataAccessorCallback={(data) =>
            data?.weedsByOrganisationId?.nodes?.map((weed) => ({
              displayValue: weed.name,
              value: weed.id,
            })) ?? []
          }
          searchFieldNames={['name']}
          query={useWeedSearchQuery}
          variables={{ id: currentOrganisation }}
        />
      </div>
    </div>
  );
}
