import { toast } from 'react-toastify';
import {
  useDeleteWebhookMutation,
  Webhook,
  WebhooksByOrganisationIdDocument,
  WebhooksByOrganisationIdEdge,
  WebhooksByOrganisationIdQuery,
  WebhooksByOrganisationIdQueryVariables,
} from '../../../graphql/generated';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { deletePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import { useNavigate } from 'react-router-dom';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';

interface WebhookDeleteConfirmationProps {
  webhook: Webhook;
  isOpen: boolean;
  onClose: () => unknown;
}

export function WebhookDeleteConfirmation({ webhook, isOpen, onClose }: WebhookDeleteConfirmationProps) {
  const [deleteRecipe] = useDeleteWebhookMutation();
  const organisationId = useOrganisationId();
  const navigate = useNavigate();

  return (
    <RSConfirmationDialog
      type="danger"
      isOpen={isOpen}
      onClose={onClose}
      title={'Delete Webhook'}
      onConfirm={async () => {
        await deleteRecipe({
          variables: {
            input: {
              id: webhook.id,
            },
          },
          onCompleted() {
            deletePagedQuery<
              Webhook,
              WebhooksByOrganisationIdQuery,
              WebhooksByOrganisationIdQueryVariables,
              WebhooksByOrganisationIdEdge
            >(
              webhook.id,
              WebhooksByOrganisationIdDocument,
              (query) => (query?.webhooksByOrganisationId?.edges as WebhooksByOrganisationIdEdge[]) ?? [],
              'webhooksByOrganisationId',
              {
                organisationId: organisationId,
              },
            );

            onClose();
            toast(`You have successfully deleted the webhook '${webhook.name}'.`, {
              type: 'success',
            });
            navigate(localRoutingConstants.webhooks.root);
          },
          onError(error) {
            toast(error.message, {
              type: 'error',
            });
          },
        });
      }}
    >
      {'Selecting confirm will delete this webhook. You cannot undo this action. Are you sure?'}
    </RSConfirmationDialog>
  );
}
