import { faCheckCircle, faFileDownload, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField, useFormikContext } from 'formik';
import { useCallback } from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import { toast } from 'react-toastify';
import '../../../styles/FileUploader.scss';
import RSButton from '../../input/rs-button/RSButton';
import FormLabel from '../FormLabel';
import FormErrorText from '../form-error-text/FormErrorText';

interface FileUploaderProps {
  name: string;
  label?: string;
  required?: boolean;
  onFileSelected: (file: File) => void;
  onFileRemove: () => void;
  selectedFile?: File;
  accept?: Accept;
  messageSuffix?: string;
}

export default function FileUploader(props: FileUploaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(props);

  const form = useFormikContext();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length == 0) {
      toast('Appropriate files were not provided.', {
        type: 'error',
      });
      return;
    }

    // Check file extension
    const selectedFile = acceptedFiles[0];

    props.onFileSelected(selectedFile);
    form.setFieldTouched(props.name, true);
    form.setFieldValue(props.name, selectedFile);
  }, []);

  return (
    <div className="flex flex-col gap-1 w-full">
      {/* Label */}
      <FormLabel label={props.label} required={props.required} />
      {props.selectedFile === undefined ? (
        <div>
          {/* File Dropper */}
          <Dropzone onDrop={onDrop} accept={props.accept}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <section>
                <div {...getRootProps()} className={`file-upload-container ${isDragActive && 'hover:bg-gray-200'}`}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <FontAwesomeIcon className="h-16 text-primary" icon={faFileUpload} />
                  ) : (
                    <FontAwesomeIcon className="h-16 text-icon-gray" icon={faFileDownload} />
                  )}
                  <div className="text-center pt-4">Click here or drag and drop to upload{props.messageSuffix}.</div>
                </div>
              </section>
            )}
          </Dropzone>
          <FormErrorText meta={meta} />
        </div>
      ) : (
        <div>
          <SelectedFilePreview
            onFileRemove={() => {
              props.onFileRemove();
              form.setFieldValue(props.name, undefined);
              form.setFieldTouched(props.name, true);
            }}
            file={props.selectedFile}
          />
          <FormErrorText meta={meta} />
        </div>
      )}
    </div>
  );
}
interface SelectedFilePreviewProps {
  file: File;
  onFileRemove: () => void;
}

function SelectedFilePreview(props: SelectedFilePreviewProps) {
  return (
    <div className="file-preview">
      <FontAwesomeIcon className="text-green-600 mr-2" fontSize="medium" icon={faCheckCircle} />
      <span className="font-bold text-ellipsis truncate">{props.file.name}</span>
      <div className="flex-grow" />
      <div className="btn-group">
        <RSButton onClick={props.onFileRemove}>Remove</RSButton>
      </div>
    </div>
  );
}
