import { IconDefinition, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserRole } from '../../graphql/generated';
import { AuthPolicy } from '../utilities/AuthPolicy';
import PermissionGuard from './PermissionGuard';
import SimpleDropdown from './SimpleDropdown';
import SimpleDropdownItem from './SimpleDropdownItem';

export type RSDropdownMenuItem = {
  icon?: IconDefinition;
  visible?: boolean;
  permission?: AuthPolicy;
  role?: UserRole;
  action: () => void;
  title: string;
  disabled?: boolean;
};

type RSGridMenuProps = {
  items: RSDropdownMenuItem[];
  permission?: AuthPolicy;
};

export default function RSDropdownMenu({ items, permission }: RSGridMenuProps) {
  const dropdownTrigger = (
    <div className="cursor-pointer h-full w-7 text-center">
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </div>
  );

  const dropdownContent = (
    <SimpleDropdown align="right" trigger={dropdownTrigger}>
      {items.map((x, idx) => (
        <RSDropdownMenuItem {...x} key={idx} />
      ))}
    </SimpleDropdown>
  );

  if (permission) {
    return <PermissionGuard policy={permission}>{dropdownContent}</PermissionGuard>;
  }

  return dropdownContent;
}

function RSDropdownMenuItem({ visible = true, icon, permission, role, action, title, disabled }: RSDropdownMenuItem) {
  const dropdownContent = (
    <SimpleDropdownItem onClick={action} disabled={disabled}>
      <div className={`flex flex-row justify-start gap-3 items-center`}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <span>{title}</span>
      </div>
    </SimpleDropdownItem>
  );

  if (!visible) {
    return <></>;
  }

  if (!permission && !role) {
    return dropdownContent;
  }

  if (permission) {
    return (
      <PermissionGuard isVisible={visible} policy={permission}>
        {dropdownContent}
      </PermissionGuard>
    );
  }

  if (!role) {
    return <></>;
  }

  return (
    <PermissionGuard isVisible={visible} role={role}>
      {dropdownContent}
    </PermissionGuard>
  );
}
