import { ApolloError } from '@apollo/client/errors';
import {
  faCalendar,
  faCheck,
  faDroplet,
  faFaucetDrip,
  faFile,
  faFlaskVial,
  faMapMarkerAlt,
  faPencil,
  faRuler,
  faRulerCombined,
  faSeedling,
  faShapes,
  faShareFromSquare,
  faStickyNote,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import * as ol from 'ol';
import { useMemo, useRef, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  DeviceAssignment,
  Job,
  JobStatus,
  UnitSystem,
  UnitType,
  UserRole,
  useGetJobQuery,
} from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import RSDropdownMenu, { RSDropdownMenuItem } from '../../../shared/components/RSDropdownMenu';
import RSBadge from '../../../shared/components/tags/RSBadge';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import useCurrentUserStore from '../../../shared/hooks/stores/UseCurrentUserStore';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';
import { RSTableBase, RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import RSTabView from '../../../shared/layout/tabs/RSTabView';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import {
  GetFullUnitDisplay,
  GetLargeAreaDisplay,
  GetPreferredUnitDisplay,
} from '../../../shared/utilities/UnitConversionUtilities';
import CompleteJobDialog from '../components/CompleteJobDialog';
import CompletionWarning from '../components/CompletionWarning';
import DeleteJobDialog from '../components/DeleteJobDialog';
import JobFormDialog from '../components/JobFormDialog';
import JobMap from '../components/JobMap/JobMap';
import JobPDFModal from '../components/JobPDF/JobPDFModal';
import JobUpdateSprayAreaDialog from '../components/JobUpdateSparyAreaDialog';
import LocationTag from '../components/LocationTag';
import OperatorComparison from '../components/OperatorComparison';
import RecipeTag from '../components/RecipeTag';
import JobRecipeUsageGraph from '../components/JobRecipeUsageGraph';
import ShareJobDialog from '../components/ShareJobModal';
import WeatherInformationTab from '../components/WeatherInformationTab';
import { applicationTypeToText } from '../utilities/ApplicationTypeUtilities';
import { jobStatusToBadgeType, jobStatusToText } from '../utilities/JobStatusUtilities';
import useGenerateMapImage from '../utilities/useGenerateMapImage';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const DefaultVolumeUnitForSystem: { [key in UnitSystem]: UnitType } = {
  [UnitSystem.Imperial]: UnitType.UsGallon,
  [UnitSystem.Metric]: UnitType.Litre,
};

export default function JobPage({}: Props) {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [showSprayArea, setShowUpdateSprayArea] = useState<boolean>(false);
  const [reportModal, setReportModal] = useState<boolean>(false);
  const map = useRef<ol.Map>();

  const { generateMapImage, mapImage } = useGenerateMapImage();
  const { unitSystem } = useSelectedOrganisationIdStore();

  const currentOrganisation = useOrganisationId();
  const { jobId } = useParams();
  const navigate = useNavigate();

  const userOrganisation = useCurrentUserStore((state) => state.getCurrentUserOrganisationId());
  const userRole = useCurrentUserStore((state) => state.getCurrentUserRole());

  const match = useMatch(localRoutingConstants.sharedJobs.viewSharedJob);
  //this can't be trusted anywhere throughout the code, its only used to add it to the query
  //for performance reasons.
  const share = match ? true : false;

  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetJobQuery({
    variables: {
      id: jobId as string,
      shared: share,
    },
  });

  const job = data?.job as Job;

  useSetBreadcrumbs({
    loading: loading,
    entity: job,
    params: {
      jobId: (x) => x.name,
    },
  });

  const totalVolumeSprayed = useMemo(() => {
    if (job?.volumeDispensed) {
      return job.volumeDispensed;
    } else if (!job?.deviceAssignments) {
      return { unit: DefaultVolumeUnitForSystem[unitSystem], value: 0 };
    }

    return job.deviceAssignments.reduce(
      (prevVal, currAssignment) => {
        const volumeSum = (currAssignment?.sprayLogs || []).reduce(
          (prevVol, currLog) => {
            if (!currLog?.v) return prevVol;

            // Convert the volume to the default unit if necessary
            prevVol.value += currLog.v.value;
            prevVol.unit = currLog.v.unit;

            return prevVol;
          },
          { unit: DefaultVolumeUnitForSystem[unitSystem], value: 0 },
        );

        prevVal.value += volumeSum.value;
        return prevVal;
      },
      { unit: DefaultVolumeUnitForSystem[unitSystem], value: 0 },
    );
  }, [job?.deviceAssignments, job?.volumeDispensed, unitSystem]);
  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName="job" apolloError={error as ApolloError} />;
  } else if (job === null && !loading) {
    return <NotFoundPage />;
  }

  const rows: Array<RSPivotRowProps> = [
    {
      name: 'Name',
      value: job.name,
      icon: faPencil,
    },
    {
      name: 'Location',
      value: <LocationTag location={job.location} />,
      icon: faMapMarkerAlt,
    },
    {
      name: 'Start Time',
      value: job.startTime ? moment(job.startTime).toLocaleString() : 'N/A',
      icon: faCalendar,
    },
    {
      name: 'End Time',
      value: job.endTime ? moment(job.endTime).toLocaleString() : 'N/A',
      icon: faCalendar,
    },
    {
      name: 'Description',
      value: job.description,
      icon: faStickyNote,
    },
    {
      name: 'Recipe',
      value: <RecipeTag job={job} navigate={navigate} />,
      icon: faFlaskVial,
      notCopyable: true,
    },
    {
      name: 'Weed Assignments',
      value:
        job.weedAssignments.filter((x) => x !== null).length > 0 ? (
          <div className="flex flex-row gap-x-2 z-10">
            {job.weedAssignments.map(
              (weed, index) =>
                weed && (
                  <RSTag
                    key={`weed.${index}`}
                    title={weed.name}
                    className="bg-gray-300"
                    onClick={() => navigate(`${localRoutingConstants.weeds.root}/${weed.id}`)}
                  />
                ),
            )}
          </div>
        ) : (
          'N/A'
        ),
      icon: faSeedling,
      notCopyable: true,
    },
    {
      name: 'Volume Sprayed',
      value: `${GetFullUnitDisplay(job.volumeDispensed, 2)}`,
      icon: faDroplet,
    },
    {
      name: 'Area Sprayed',
      value: (
        <span title={`${GetFullUnitDisplay(job.areaSprayed, 2)}`}>
          {job.areaSprayed ? <>{GetLargeAreaDisplay(job.areaSprayed, 2)}</> : 'Not Calculated'}
        </span>
      ),
      icon: faRulerCombined,
      expandable: job.areaSprayed ? true : false,
      notCopyable: true,
      expandContent:
        job.areaSprayed && job.nozzleWidth ? (
          <RSPivotTable
            leftBorder={false}
            rows={[
              {
                name: 'Nozzle Width',
                value: GetFullUnitDisplay(job.nozzleWidth),
                icon: faShapes,
              },
              {
                name: 'Distance Sprayed',
                value: GetFullUnitDisplay(
                  { value: job.areaSprayed.value / job.nozzleWidth.value, unit: job.nozzleWidth.unit },
                  2,
                ),
                icon: faRuler,
              },
            ]}
          />
        ) : undefined,
    },
    {
      name: 'Application Type',
      value: applicationTypeToText[job.applicationType],
      icon: faFaucetDrip,
    },
  ];

  const deviceAssignmentCols: Array<RSTableColumnDefinition<DeviceAssignment>> = [
    {
      title: 'User',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => (
        <RSTag
          title={x.user.fullName}
          className="bg-gray-300"
          onClick={() => navigate(`${localRoutingConstants.users.root}/${x.user.id}`)}
        />
      ),
      id: 'user',
    },
    {
      title: 'Device',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => (
        <RSTag
          title={x.device.alias}
          className="bg-gray-300"
          onClick={() => navigate(`${localRoutingConstants.devices.root}/${x.device.id}`)}
        />
      ),
      id: 'device',
    },
  ];

  const jobActions: RSDropdownMenuItem[] = [
    {
      icon: faPencil,
      title: 'Edit Job',
      action: () => setShowEdit(true),
      permission: AuthPolicy.JobManagement,
      visible: job.status == JobStatus.Incomplete,
    },
    {
      icon: faCheck,
      title: 'Complete Job',
      action: () => setShowComplete(true),
      permission: AuthPolicy.JobManagement,
      visible: job.status == JobStatus.Incomplete,
    },
    {
      icon: faTrash,
      title: 'Delete Job',
      action: () => setShowDelete(true),
      permission: AuthPolicy.JobManagement,
      visible: job.status == JobStatus.Incomplete,
    },
    {
      icon: faShareFromSquare,
      title: 'Share Job',
      permission: AuthPolicy.OrganisationManagement,
      action: () => setShowShare(true),
      visible: shareVisible(userOrganisation, userRole, job),
    },
    {
      icon: faRuler,
      title: 'Update Spray Area',
      permission: AuthPolicy.UpdateSprayArea,
      action: () => setShowUpdateSprayArea(true),
      visible: job.status != JobStatus.Incomplete,
    },
    {
      title: 'Generate Report',
      permission: AuthPolicy.OrganisationManagement,
      visible: job.status == JobStatus.Complete,
      icon: faFile,
      action: () => {
        generateMapImage(map.current);
        setReportModal(true);
      },
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        <CompletionWarning job={job} />
        <GridTile
          colSpan={3}
          title={
            <>
              {'Overview'}
              <RSBadge
                title={jobStatusToText[job.status]}
                type={jobStatusToBadgeType[job.status]}
                tooltip={moment(job.completedAt).toLocaleString()}
              />
            </>
          }
          actions={
            <PermissionGuard
              anyPolicy={jobActions.filter((x) => x.permission != null).map((x) => x.permission as AuthPolicy) || []}
              isVisible={jobActions.map((x) => (x.visible ? x.visible : true)).some((x) => x)}
            >
              <RSDropdownMenu items={jobActions} />
            </PermissionGuard>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        <GridTile colSpan={3} title={'Device Assignments'}>
          <RSTableBase<DeviceAssignment>
            data={data?.job?.deviceAssignments as DeviceAssignment[]}
            columns={deviceAssignmentCols}
          />
        </GridTile>
        <GridTile className="col-span-3 md:col-span-6" title="Weather Information">
          <WeatherInformationTab job={job} />
        </GridTile>
        <RSTabView
          className="col-span-3 md:col-span-6"
          items={[
            {
              label: 'Map',
              view: (
                <JobMap
                  job={job}
                  callback={(newMap: ol.Map) => {
                    // setMap(map);
                    map.current = newMap;
                  }}
                />
              ),
            },
            {
              label: 'Chemical Usage',
              view: <JobRecipeUsageGraph job={job} totalVolumeSprayed={totalVolumeSprayed} />,
            },
            {
              label: 'Operator Comparison',
              view: <OperatorComparison job={job} />,
            },
          ]}
          defaultItem={0}
          title="Analytics"
        />
      </RSGrid>
      {/* Edit Job Modal */}
      <JobFormDialog
        initialValues={job}
        isOpen={showEdit}
        onClose={() => setShowEdit(false)}
        currentOrganisation={currentOrganisation}
      />
      <PermissionGuard policy={AuthPolicy.JobManagement} isVisible={showComplete}>
        <CompleteJobDialog job={job} isOpen={true} onClose={() => setShowComplete(false)} />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.JobManagement} isVisible={showDelete}>
        <DeleteJobDialog
          currentOrganisation={currentOrganisation}
          job={job}
          isOpen={true}
          onClose={() => setShowDelete(false)}
        />
      </PermissionGuard>
      <PermissionGuard
        policy={AuthPolicy.OrganisationManagement}
        isVisible={showShare && shareVisible(userOrganisation, userRole, job)}
      >
        {' '}
        <ShareJobDialog job={job} isOpen={true} onClose={() => setShowShare(false)} />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.UpdateSprayArea} isVisible={showSprayArea}>
        <JobUpdateSprayAreaDialog
          initialValues={job.nozzleWidth ? { nozzleWidth: job.nozzleWidth, jobId: job.id } : undefined}
          job={job}
          isOpen={true}
          onClose={() => setShowUpdateSprayArea(false)}
        />
      </PermissionGuard>
      <JobPDFModal job={job} mapImage={mapImage} isOpen={reportModal} onClose={() => setReportModal(false)} />
    </>
  );
}
function shareVisible(userOrganisation: string | undefined, role: UserRole | undefined, job: Job): boolean {
  if (job.status != JobStatus.Complete) return false;
  //if the user is a super admin then we can always share
  if (role === UserRole.SuperAdmin) return true;

  //otherwise only share when the job belongs to that organisation
  return userOrganisation == job.organisation.id;
}
