import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Job, JobStatus } from '../../../graphql/generated';
import GridTile from '../../../shared/layout/tiles/GridTile';

interface CompletionWarningProps {
  job: Job;
}

export default function CompletionWarning({ job }: CompletionWarningProps) {
  // Only display if the job is incomplete and the current time is after the job's endTime
  if (job.endTime && job.status == JobStatus.Incomplete && moment() > moment(job.endTime)) {
    return (
      <GridTile colSpan={6} title={''} className="col-span-3 md:col-span-6 bg-amber-100 p-4">
        <FontAwesomeIcon icon={faWarning} className="pr-4" />
        Job not completed after its end time - you should complete your job to calculate recipe and chemical usage
        statistics.
      </GridTile>
    );
  } else {
    return <></>;
  }
}
