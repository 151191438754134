// NOTE: this is a workaround for the following issue https://github.com/remix-run/react-router/issues/8139
// When react-router V6 is updated to include the useBlocker hook again, this should be migrated

import { useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import type { History, Blocker, Transition } from 'history';

export default function useBlocker(blocker: Blocker, when = true) {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
  return { isBlocking, setIsBlocking };
}
