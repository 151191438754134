import { Popover } from '@headlessui/react';
import { Control } from 'ol/control';
import { useContext, useEffect, useRef, useState } from 'react';
import { DeviceAssignment } from '../../../../graphql/generated';
import RSCheckBox from '../../../../shared/components/input/RSCheckBox';
import RSButton from '../../../../shared/components/input/rs-button/RSButton';
import MapContext from '../../../../shared/components/maps/Map/MapContext';
import RSDisclosure from '../../../../shared/components/rs-disclosure/RSDisclosure';
import { DeviceAssignmentLayerPair, WeedLayerPair } from './JobMap';
import { SliderField } from '@aws-amplify/ui-react';

interface FilterControlProps {
  deviceAssignments: Array<DeviceAssignmentLayerPair>;
  weeds: Array<WeedLayerPair>;
}

export default function FilterControl({ deviceAssignments, weeds }: FilterControlProps) {
  const map = useContext(MapContext);
  const controlElement = useRef<HTMLDivElement>(null);
  const [control, setControl] = useState<Control>();

  const checked = useRef<Map<DeviceAssignment, boolean>>(new global.Map<DeviceAssignment, boolean>());
  const [heatmapChecked, setHeatmapChecked] = useState<boolean>(deviceAssignments[0]?.heatmap.getVisible());
  const [operatorPathChecked, setOperatorChecked] = useState<boolean>(true);

  const [radius, setRadius] = useState<number>(15);

  const isDeviceAssignmentChecked = (deviceAssignment: DeviceAssignment) => {
    const value = checked.current.get(deviceAssignment);
    return value === null || value === undefined || value;
  };

  useEffect(() => {
    if (!map) return;

    const newControl = new Control({
      element: controlElement.current ?? undefined,
    });

    map.addControl(newControl);
    setControl(control);

    return () => {
      map.removeControl(newControl);
    };
  }, [map]);

  const getWeedCheckBoxes = () =>
    weeds.map((weed, index) => (
      <RSCheckBox
        key={`operator.${index}`}
        label={<>{weed.weed?.name ?? 'Not Set'}</>}
        initialValue={weed.layer.getVisible()}
        onChangeHandler={(event) => {
          const checked = event.target.checked;
          weed.layer.setVisible(checked);
          return checked;
        }}
      />
    ));

  const hasDeviceAssignments = deviceAssignments.length > 0;
  const hasWeedsSet = weeds.some((weed) => weed.weed !== null);
  const isShown = hasDeviceAssignments || hasWeedsSet;

  return (
    <Popover ref={controlElement} className="right-2 top-12 absolute">
      {({ open, close }) => {
        return isShown ? (
          <>
            {!open && <Popover.Button className="rs-btn-primary">Filters</Popover.Button>}
            <Popover.Panel className="bg-white rounded-md w-80">
              <div className="flex flex-row flex-wrap justify-between border-border-gray border-b text-left p-2">
                <p className="text-primary font-bold text-2xl">Filters</p>
                <div className="flex flex-row gap-x-2 gap-y-2 flex-wrap items-center">
                  <RSButton onClick={close}>Close</RSButton>
                </div>
              </div>
              <RSDisclosure title={'Options'} defaultOpen={true}>
                <div className="flex flex-col">
                  <RSCheckBox
                    label="Operator Path"
                    onChangeHandler={(_, isChecked) => {
                      isChecked = !isChecked;
                      if (!map) {
                        return isChecked;
                      }

                      for (const assignment of deviceAssignments) {
                        if (!isChecked) {
                          assignment.operatorPath.setVisible(false);
                        } else {
                          //if the layer is being set visible, only do so if the device assignment is checked
                          assignment.operatorPath.setVisible(isDeviceAssignmentChecked(assignment.deviceAssignment));
                        }
                      }

                      setOperatorChecked(isChecked);
                      return isChecked;
                    }}
                    key={'operator-path'}
                    initialValue={operatorPathChecked}
                  />

                  <RSCheckBox
                    label="Heatmap"
                    onChangeHandler={(_, isChecked) => {
                      isChecked = !isChecked;
                      if (!map) {
                        return isChecked;
                      }

                      for (const assignment of deviceAssignments) {
                        if (!isChecked) {
                          assignment.heatmap.setVisible(false);
                        } else {
                          //if the layer is being set visible, only do so if the device assignment is checked
                          const isDaChecked = isDeviceAssignmentChecked(assignment.deviceAssignment);
                          if (isDaChecked) {
                            assignment.heatmap.setVisible(isDaChecked);
                          }
                        }
                      }

                      setHeatmapChecked(isChecked);
                      return isChecked;
                    }}
                    key={'heatmap'}
                    initialValue={heatmapChecked}
                  />
                  {heatmapChecked ? (
                    <SliderField
                      label="Heat map scale"
                      max={30}
                      min={6}
                      defaultValue={radius}
                      onChange={(value) => {
                        for (const assignment of deviceAssignments) {
                          assignment.heatmap.setRadius(value);
                        }
                        setRadius(value);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </RSDisclosure>
              {hasDeviceAssignments && (
                <RSDisclosure title={'Operators'} defaultOpen={true}>
                  <div className="grid grid-cols-2 gap-2">
                    {deviceAssignments.map((da, index) => (
                      <RSCheckBox
                        key={`operator.${index}`}
                        label={da.deviceAssignment.user.fullName}
                        initialValue={isDeviceAssignmentChecked(da.deviceAssignment)}
                        onChangeHandler={(_, isChecked) => {
                          //uncheck the box
                          isChecked = !isChecked;
                          checked.current.set(da.deviceAssignment, isChecked);

                          //switch off the line and device layer
                          if (heatmapChecked) {
                            da.heatmap.setVisible(isChecked);
                          }

                          if (operatorPathChecked) {
                            da.operatorPath.setVisible(isChecked);
                          }

                          return isChecked;
                        }}
                      />
                    ))}
                  </div>
                </RSDisclosure>
              )}

              {hasWeedsSet && (
                <RSDisclosure title={'Weeds'} defaultOpen={true}>
                  <div className="flex flex-col">{getWeedCheckBoxes()}</div>
                </RSDisclosure>
              )}
            </Popover.Panel>
          </>
        ) : (
          <></>
        );
      }}
    </Popover>
  );
}
