import { ApolloError } from '@apollo/client';
import { SprayLog, useGetSprayLogsForDeviceQuery } from '../../../graphql/generated';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import { RSTableBase, RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';

interface Props {
  deviceId: string;
}

export default function SprayLogTable(props: Props) {
  const {
    data: data,
    loading: loading,
    error: error,
    fetchMore,
  } = useGetSprayLogsForDeviceQuery({
    variables: {
      deviceId: props.deviceId as string,
    },
  });

  const columns = [
    {
      title: 'Timestamp',
      id: 'timestamp',
      accessor: (x) => new Date(x?.timestamp).toLocaleString(),
    },
    {
      title: 'Coordinates',
      id: 'coordinates',
      accessor: (x) => `(${x.coordinates.latitude}, ${x.coordinates.longitude})`,
    },
    {
      title: 'Volume Dispensed',
      id: 'volumeDispensed',
      accessor: (x) => x.volumeDispensed?.value,
    },
    {
      title: 'Weeds',
      id: 'weeds',
      accessor: (x) => x.weeds,
    },
    {
      title: 'Temperature',
      id: 'temperature',
      accessor: (x) => x.temperature?.value,
    },
    {
      title: 'Spray Mode',
      id: 'sprayMode',
      accessor: (x) => x.sprayMode,
    },

    {
      title: 'Dropped',
      id: 'dropped',
      accessor: (x) => `${x.wasDropped}`,
    },
  ] as Array<RSTableColumnDefinition<SprayLog>>;

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName={`device '${props.deviceId}'`} apolloError={error as ApolloError} />;
  }

  return (
    <RSTableBase
      columns={columns}
      data={(data?.sprayLogsForDevice?.edges?.map((e) => e.node) as SprayLog[]) ?? []}
      totalCount={data?.sprayLogsForDevice?.totalCount as number}
      fetchMore={async (_, pageSize, increment) => {
        await fetchMore({
          variables: {
            first: pageSize,
            after: increment ? (data?.sprayLogsForDevice?.pageInfo.endCursor as string) : undefined,
          },
        });
      }}
    />
  );
}
