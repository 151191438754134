import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLayout from './shared/layout/AppLayout';
import NotFoundPage from './shared/layout/errors/NotFoundPage';
import { Amplify } from 'aws-amplify';
import { Authenticator, useTheme, View, Image } from '@aws-amplify/ui-react';
import { AuthConfig } from './features/auth/AuthConfig';
import '@aws-amplify/ui-react/styles.css';
import logo from './assets/images/rapid-spray-logo.png';
import apolloClient from './shared/utilities/ApolloClient';
import useCurrentUserStore from './shared/hooks/stores/UseCurrentUserStore';
import { routes } from './shared/routes/Routes';
import RSBrand from './shared/components/rs-brand/RSBrand';
import DefaultRoutes from './shared/routes/DefaultRoutes';
import useSelectedOrganisationIdStore from './shared/hooks/stores/UseSelectedOrganisationIdStore';
import { useEffect } from 'react';
import {
  GetOrganisationQuery,
  GetOrganisationQueryVariables,
  UserRole,
  OrganisationSearchDocument,
  Organisation,
  GetOrganisationDocument,
} from './graphql/generated';
import { toast } from 'react-toastify';
Amplify.configure(AuthConfig);

//TODO: Handle server errors with special page or alert ?
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <>
        <View textAlign="center" padding={tokens.space.xxxl}>
          <Image alt="Rapid Logix logo" src={logo} className="visible md:hidden" />
        </View>
        <div className="text-primary font-bold text-5xl pb-8">Sign In</div>
      </>
    );
  },
};

function App() {
  const setCurrentUser = useCurrentUserStore((state) => state.setCurrentUser);
  const currentUser = useCurrentUserStore((state) => state.currentUser);
  const currentUserRole = useCurrentUserStore((state) => state.getCurrentUserRole);
  const currentOrganisation = useCurrentUserStore((state) => state.getCurrentUserOrganisationId)();
  const { selectedOrganisation, setSelectedOrganisation } = useSelectedOrganisationIdStore();

  useEffect(() => {
    if (!currentOrganisation) {
      return;
    }

    if (selectedOrganisation) {
      return;
    }

    if (currentUserRole() === UserRole.SuperAdmin) {
      return;
    }

    const fetchOrganisation = async () => {
      try {
        const { data } = await apolloClient.query<GetOrganisationQuery, GetOrganisationQueryVariables>({
          query: GetOrganisationDocument,
          variables: { id: currentOrganisation },
        });
        setSelectedOrganisation(data.organisation as Organisation);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrganisation();
  }, [currentOrganisation]);

  return (
    <>
      <div className="grid grid-cols-3">
        {currentUser ? null : <RSBrand />}
        <div className={`flex flex-col justify-center ${currentUser ? 'col-span-3' : 'p-4 col-span-3 md:col-span-2'}`}>
          <Authenticator loginMechanisms={['email']} hideSignUp={true} components={components}>
            {({ user }) => {
              setCurrentUser(user);
              return (
                <ApolloProvider client={apolloClient}>
                  <BrowserRouter>
                    {/* Unauthenticated Routes */}
                    <Routes>
                      {/* Authenticated Routes */}
                      <Route element={<AppLayout />}>
                        {Array.from(routes).map(([, routes]) => {
                          return routes.onSidebar.concat(routes.otherRoutes).map((route, idx) => {
                            return (
                              <>
                                <Route index element={DefaultRoutes(currentUserRole())} />
                                <Route key={idx} path={route.path} element={route.element} />
                              </>
                            );
                          });
                        })}
                        {/* Not Found Page */}
                        <Route path="*" element={<NotFoundPage />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </ApolloProvider>
              );
            }}
          </Authenticator>
        </div>
      </div>
    </>
  );
}

export default App;
