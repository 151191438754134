import { Combobox } from '@headlessui/react';
import { ReactNode, useState } from 'react';
import { ComboBoxItem } from './ComboBox';
import debounce from 'lodash.debounce';

interface RSComboBoxInputProps<T> {
  debounceTimeout: number;
  setSelectionCallback: (item: ComboBoxItem<T> | undefined) => void;
  setSearchStringCallback: (searchString: string) => void;
  placeholder?: string;
  icon?: ReactNode;
  extraPadding?: boolean;
  denyDeselect?: boolean;
}

export default function RSComboBoxInput<T>({
  debounceTimeout,
  setSelectionCallback,
  setSearchStringCallback,
  placeholder,
  icon,
  extraPadding,
  denyDeselect,
}: RSComboBoxInputProps<T>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const [_, setIsDebouncing] = useState<any>(); //Unfortunately this is typed as any because lodash doesn't export the type...

  return (
    <Combobox.Button className="w-full">
      <Combobox.Input
        placeholder={placeholder}
        className={`rs-form-input w-full ${!icon ? 'pl-4' : extraPadding ? `pl-12` : 'pl-10'}`}
        displayValue={(item: ComboBoxItem<T> | undefined) => item?.displayValue ?? ''}
        onChange={(event) => {
          setIsDebouncing(
            debounce(() => {
              setIsDebouncing(undefined);
              setSearchStringCallback(event.target.value);
              if (!denyDeselect && event.target.value === '') {
                setSelectionCallback(undefined);
              }
            }, debounceTimeout),
          );
        }}
      />
    </Combobox.Button>
  );
}
