import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faCircleCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useEffect, useState } from 'react';
import './RSPivotTable.scss';

export type TextBehavior = 'wrap' | 'ellipsis';

export interface RSPivotRowProps {
  name: string;
  value: any;
  icon?: IconProp;
  notCopyable?: boolean;
  expandable?: boolean;
  expandContent?: ReactNode;
  className?: string;
  textBehavior?: TextBehavior;
}

type RSPivotTableProps = {
  rows: Array<RSPivotRowProps>;
  leftBorder?: boolean;
};

export default function RSPivotTable({ rows: rows, leftBorder = true }: RSPivotTableProps) {
  return (
    <div className="rs-pivot">
      {rows.map((row, idx) => {
        // eslint-disable-next-line react/jsx-key
        return <RSPivotRow className={leftBorder ? 'rs-pivot-row-borderable' : undefined} key={idx} {...row} />;
      })}
    </div>
  );
}

function RSPivotRow({
  className: className,
  name: key,
  value,
  icon,
  notCopyable,
  expandable = false,
  expandContent,
  textBehavior = 'ellipsis',
}: RSPivotRowProps) {
  const [clicked, setClicked] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (clicked === true) {
      setTimeout(() => {
        setClicked(false);
      }, 2000);
    }
  }, [clicked]);

  notCopyable = notCopyable || typeof value === 'object';
  notCopyable = expandable ? true : notCopyable;

  return (
    <>
      <div
        className={`rs-pivot-row ${expandable ? 'cursor-pointer' : ''}${className ? ` ${className}` : ''}${
          expanded ? ' rs-pivot-row-expanded-border' : ''
        }`}
        onClick={expandable ? () => setExpanded(!expanded) : undefined}
      >
        <div className="rs-pivot-key">
          {icon && <FontAwesomeIcon icon={icon} className="hidden px-3 w-5 text-lg sm:block " />}
          <div className="font-semibold text-lg">{key}</div>
        </div>
        <div className="col-span-2">
          <div className="flex flex-row justify-between">
            {textBehavior === 'ellipsis' ? (
              <div className="text-ellipsis overflow-hidden">{value}</div>
            ) : (
              <div className="break-words overflow-hidden">{value}</div>
            )}
            <div className="col-span-widgets">
              {expandable && (
                <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className="text-gray-300 px-3 text-lg" />
              )}
              {notCopyable ? (
                ''
              ) : (
                <FontAwesomeIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(value);
                    setClicked(true);
                  }}
                  icon={clicked ? faCircleCheck : faCopy}
                  className="hover:text-primary text-gray-300 px-3 text-lg transition-all duration-150"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {expanded && (
        <div className="rs-pivot-row-expanded">
          <div className="expandable-content-wrapper">{expandContent}</div>
        </div>
      )}
    </>
  );
}
