import { Font, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useState } from 'react';
import sansProBold from '../../../../assets/fonts/source-sans-pro/SourceSansPro-Bold.ttf';
import sansProLight from '../../../../assets/fonts/source-sans-pro/SourceSansPro-Light.ttf';
import sansPro from '../../../../assets/fonts/source-sans-pro/SourceSansPro-Regular.ttf';
import sansProSemiBold from '../../../../assets/fonts/source-sans-pro/SourceSansPro-SemiBold.ttf';
import { Job } from '../../../../graphql/generated';
import RSButton from '../../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../../shared/components/rs-dialog/RSDialog';
import BubbleLoader from '../../../../shared/layout/loading/BubbleLoader';
import JobPDF from './JobPDF';
import useSelectedOrganisationIdStore from '../../../../shared/hooks/stores/UseSelectedOrganisationIdStore';

export interface JobPDFModal {
  job: Job;
  mapImage: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * This component creates a modal holding a preview of the generated PDF as well as allowing the user to download the PDF file
 * @param {Job} job The Job used in the generation of the PDF
 * @param {string | undefined} mapImage DataUrl of the map
 * @param {boolean} isOpen Boolean used to decide whether the modal should be displayed or not
 * @param {() => void} onClose Function to be called when the modal's close button is selected
 */
export default function JobPDFModal({ job, mapImage, isOpen, onClose }: JobPDFModal) {
  Font.register({
    family: 'Source Sans Pro',
    fonts: [
      { src: sansPro, fontStyle: 'normal', fontWeight: 'normal' },
      { src: sansProBold, fontStyle: 'normal', fontWeight: 'bold' },
      { src: sansProSemiBold, fontStyle: 'normal', fontWeight: 'semibold' },
      { src: sansProLight, fontStyle: 'normal', fontWeight: 'light' },
    ],
  });

  const [loading, setLoading] = useState<boolean>(true);
  const { unitSystem } = useSelectedOrganisationIdStore();

  return (
    <RSDialog
      title={'Generate Report'}
      isOpen={isOpen}
      actions={
        <>
          <RSButton
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </RSButton>
          <PDFDownloadLink
            document={<JobPDF unitSystem={unitSystem} job={job} mapImage={mapImage} />}
            fileName={job.name}
          >
            {({ blob }) => (blob === null ? null : <RSButton>{'Download'}</RSButton>)}
          </PDFDownloadLink>
        </>
      }
    >
      <>
        {loading ? (
          <div style={{ height: '20px' }}>
            <BubbleLoader />
          </div>
        ) : null}
        <PDFViewer
          style={{
            height: '35rem',
            width: '100%',
            paddingTop: '1rem',
          }}
        >
          <JobPDF job={job} unitSystem={unitSystem} mapImage={mapImage} onRender={() => setLoading(false)} />
        </PDFViewer>
      </>
    </RSDialog>
  );
}
