import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';
import BaseFormNumberInput from './BaseFormNumberInput';

type Props = {
  name: string;
  label?: string;
  suffix?: ReactNode;
  placeholder?: string;
  required?: boolean;
  icon?: IconProp;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number | string | undefined;
  onChangeHandler?: (value: number) => void;
};

export default function FormNumberInput(props: Props) {
  return BaseFormNumberInput<number>({
    ...props,
    getDisplayValue: (x) => {
      if (!x) {
        return undefined;
      }

      return x;
    },
    getFieldValue: (x) => x,
  });
}
