import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FirmwareStatusToDisplayName } from '../../../graphql/firmware/FirmwareUtils';
import {
  Firmware,
  FirmwareFilterInput,
  FirmwareSortInput,
  useAdminGetAllFirmwareQuery,
} from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import CreateFirmwareFormDialog from '../components/CreateFirmwareFormDialog';

export default function FirmwaresPage() {
  const [showCreateFirmware, setShowCreateFirmware] = useState<boolean>(false);

  const {
    searchString: searchString,
    where: where,
    setSearchString: setSearchString,
    order: order,
    setSort: setSort,
  } = useSearchSortFilter<FirmwareFilterInput>({
    searchableFieldNames: ['version', 'description'],
  });

  const {
    data: data,
    loading: loading,
    fetchMore: fetchMore,
  } = useAdminGetAllFirmwareQuery({
    variables: {
      where: where,
      order: order,
    },
  });

  const cols = [
    {
      title: 'Version',
      id: 'version',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.version,
    },
    {
      title: 'Status',
      id: 'status',
      accessor: (x) => FirmwareStatusToDisplayName[x.status],
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
    {
      title: 'Created Date',
      id: 'createdDate',
      accessor: (x) => x.createInformation.createdAt,
    },
    {
      title: 'Created By',
      id: 'createdBy',
      accessor: (x) => x.user.fullName,
    },
  ] as Array<RSTableColumnDefinition<Firmware>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Firmware'}
          actions={
            <PermissionGuard policy={AuthPolicy.FirmwareList}>
              <RSButton onClick={() => setShowCreateFirmware(true)}>
                <FontAwesomeIcon icon={faPlus} />
                Add Firmware
              </RSButton>
            </PermissionGuard>
          }
        >
          <SearchSortFilterBar<FirmwareSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['version', 'description']}
          />
          <RSTable<Firmware>
            isLoading={loading}
            columns={cols}
            data={(data?.firmware?.edges?.map((e) => e.node) as Firmware[]) ?? []}
            totalCount={data?.firmware?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.firmware?.pageInfo.endCursor as string) : undefined,
                  order: order,
                  where: where,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.FirmwareList} isVisible={showCreateFirmware}>
        <CreateFirmwareFormDialog isOpen={true} onClose={() => setShowCreateFirmware(false)} />
      </PermissionGuard>
    </>
  );
}
