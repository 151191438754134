import { Location } from '../../../graphql/generated';
import { RSTag } from '../../../shared/components/tags/RSTag';

interface LocationTagProps {
  location: Location | undefined;
}

export default function LocationTag({ location }: LocationTagProps) {
  if (location) {
    return (
      <RSTag
        title={location.address}
        className="bg-gray-300"
        tooltip={`${location.latitude.toFixed(3)}, ${location.longitude.toFixed(3)}`}
        onClick={(e) => {
          e.stopPropagation();
          return window.open('https://maps.google.com?q=' + location?.latitude + ',' + location?.longitude);
        }}
      />
    );
  } else {
    return <p>N/A</p>;
  }
}
