import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { WebhookEventKind, WebhookStatus } from '../../../graphql/generated';
import FormErrorText from '../../../shared/components/forms/form-error-text/FormErrorText';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import FormLabel from '../../../shared/components/forms/FormLabel';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';
import RSFieldCheckbox from '../../../shared/components/input/RSFieldCheckbox';
import { MaximumDescriptionLength, MaximumNameLength } from '../../../shared/constants/ValidationConstants';
import { webhookFormValidator } from '../validation/WebhookFormValidator';
import { WebhookEventDefinitions } from '../WebhookEventUtils';
import { WebhookStatusDefinitions } from '../WebhookStatusUtils';
import React from 'react';

type WebhookFormContentProps = {
  initialValues?: WebhookFormType;
  onSubmit: (values: WebhookFormType, helpers: FormikHelpers<WebhookFormType>) => Promise<void>;
  onChange: () => void;
  formikRef?: React.RefObject<FormikProps<WebhookFormType>>;
};

export type WebhookFormType = {
  name: string;
  description?: string;
  url: string;
  events: WebhookEventKindCheckBox[];
  status: WebhookStatus;
};

export type WebhookEventKindCheckBox = {
  event: WebhookEventKind;
  checked: boolean;
};

const defaultValue: WebhookFormType = {
  name: '',
  url: '',
  events: Object.keys(WebhookEventDefinitions).map((x) => {
    const eventCheck: WebhookEventKindCheckBox = {
      checked: false,
      event: x as WebhookEventKind,
    };

    return eventCheck;
  }),
  status: WebhookStatus.Enabled,
};

export default function WebhookFormContent({ onSubmit, initialValues, onChange, formikRef }: WebhookFormContentProps) {
  const formInitialValues = initialValues ?? defaultValue;
  const statusOptions: DropDownOption<string>[] = Object.entries(WebhookStatusDefinitions).map(
    ([status, definition]) => {
      return {
        value: status as WebhookStatus,
        displayValue: definition.displayName,
      };
    },
  );

  return (
    <Formik<WebhookFormType>
      initialValues={formInitialValues}
      validationSchema={webhookFormValidator}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {(formik) => (
        <Form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          onChange={onChange}
        >
          <FormTextInput required label="Name" name="name" placeholder="My Webhook" maxLength={MaximumNameLength} />
          <FormTextInput required label="URL" name="url" placeholder="https://my-endpoint.net" />
          <FormTextAreaInput
            label="Description"
            name="description"
            placeholder="Pushing Job Completion"
            maxLength={MaximumDescriptionLength}
          />
          <FormSelectInput required label="Status" name="status" options={statusOptions} />
          <FormLabel label="Events" />
          {formik.values.events.map((e, index) => {
            const { event } = e;
            const definition = WebhookEventDefinitions[event];
            return (
              <>
                <hr />
                <div className="p-2">
                  <RSFieldCheckbox
                    key={event}
                    label={definition.displayName}
                    name={`events.${index}.checked`}
                    initialValue={null}
                  />
                </div>
              </>
            );
          })}
          <div className="px-2">
            <FormErrorText meta={formik.getFieldMeta('events')} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
