import { ApolloError } from '@apollo/client';
import {
  faBolt,
  faFlag,
  faLink,
  faPencil,
  faSignal,
  faStickyNote,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWebhookByIdQuery, Webhook, WebhookInfrastructureStatus } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps, TextBehavior } from '../../../shared/layout/table/RSPivotTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import { UpdateWebhookDialog } from '../components/UpdateWebhookDialog';
import { WebhookEventKindTag } from '../components/WebhookEventKindTag';
import { WebhookInfrastructureStatusTag } from '../components/WebhookInfrastructureStatusTag';
import { WebhookStatusDefinitions } from '../WebhookStatusUtils';
import { WebhookDeleteConfirmation } from '../components/WebhookDeleteConfirmation';
export default function WebhookPage() {
  const { webhookId } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const {
    data: data,
    loading: loading,
    error: error,
    startPolling,
    stopPolling,
  } = useWebhookByIdQuery({
    variables: {
      id: webhookId ?? '',
    },
  });

  useEffect(() => {
    startPolling(10000);
    return () => {
      stopPolling();
    };
  });

  const webhook = data?.webhookById as Webhook;
  useSetBreadcrumbs({
    loading: loading,
    entity: webhook,
    params: {
      webhookId: (x) => x.name,
    },
  });

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName="webhook" apolloError={error as ApolloError} />;
  } else if (webhook === null && !loading) {
    return <NotFoundPage />;
  }

  const rows: RSPivotRowProps[] = [
    {
      name: 'Name',
      value: webhook.name,
      icon: faPencil,
    },
    {
      name: 'Description',
      value: webhook.description,
      icon: faStickyNote,
    },
    {
      name: 'Url',
      value: webhook.url,
      icon: faLink,
    },
    {
      name: 'Status',
      value: WebhookStatusDefinitions[webhook.status].displayName,
      icon: faFlag,
    },
    {
      name: 'State',
      value: <WebhookInfrastructureStatusTag status={webhook.infrastructureStatus} />,
      icon: faSignal,
    },
    ...(webhook.infrastructureStatus.detail &&
    webhook.infrastructureStatus.status == WebhookInfrastructureStatus.DeploymentFailed
      ? [
          {
            name: 'Failure Reason',
            value: webhook.infrastructureStatus.detail,
            textBehavior: 'wrap' as TextBehavior,
            icon: faTriangleExclamation,
          },
        ]
      : []),
    {
      name: 'Events',
      value: webhook.events.map((x) => <WebhookEventKindTag event={x} key={x} />),
      icon: faBolt,
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <>
              <PermissionGuard policy={AuthPolicy.WebhookDelete} isVisible={true}>
                <RSButton variant="danger" onClick={() => setShowDelete(true)}>
                  Delete
                </RSButton>
              </PermissionGuard>
              <PermissionGuard policy={AuthPolicy.WebhookUpdate} isVisible={true}>
                <RSButton variant="primary" onClick={() => setShowEdit(true)}>
                  <FontAwesomeIcon icon={faPencil} />
                  Edit
                </RSButton>
              </PermissionGuard>
            </>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
      </RSGrid>
      {/* Edit Webhook Modal */}
      <PermissionGuard policy={AuthPolicy.WebhookUpdate} isVisible={showEdit}>
        <UpdateWebhookDialog webhook={webhook} isOpen={showEdit} onClose={() => setShowEdit(false)} />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.WebhookDelete} isVisible={showDelete}>
        <WebhookDeleteConfirmation webhook={webhook} isOpen={showDelete} onClose={() => setShowDelete(false)} />
      </PermissionGuard>
    </>
  );
}
