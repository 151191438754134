import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, AutocompleteProps, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import FormLabel from '../../../shared/components/forms/FormLabel';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import env from '@beam-australia/react-env';

type Libraries = 'places'[];
const libraries: Libraries = ['places'];

interface AutoCompleteWrapperProps extends Omit<AutocompleteProps, 'children'> {
  label?: string;
  required?: boolean;
  icon?: IconProp;
  placeHolder?: string;
  onPlaceChangedCallback: (autocomplete: google.maps.places.Autocomplete) => void;
}

export default function AutoCompleteWrapper(props: AutoCompleteWrapperProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env('GOOGLE_MAP_KEY') ?? '',
    libraries: libraries,
  });

  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();

  const [value, setValue] = useState<string>();

  // Reset the value of the field to undefined so that it can be edited
  useEffect(() => {
    if (value === '') {
      setValue(undefined);
    }
  }, [value]);

  return isLoaded ? (
    <div className="flex flex-col flex-grow">
      <FormLabel label={props.label} required={props.required} />
      <div className="rs-form-icon-container w-full ">
        {/* Icon */}
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {/* Input */}
        <Autocomplete
          fields={['geometry.location', 'name', 'formatted_address']}
          onLoad={setAutocomplete}
          onPlaceChanged={() => {
            //If a place has been selected clear the search
            setValue('');
            return autocomplete && props.onPlaceChangedCallback(autocomplete);
          }}
        >
          <input
            className={`rs-form-input w-full ${!props.icon && 'pl-4'}`}
            type="text"
            placeholder={props.placeHolder}
            value={value}
            onChange={() => {}}
          />
        </Autocomplete>
      </div>
    </div>
  ) : (
    <BubbleLoader />
  );
}
