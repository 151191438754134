import {
  faBed,
  faClock,
  faClockRotateLeft,
  faDroplet,
  faLock,
  faMobile,
  faPencil,
  faSatellite,
} from '@fortawesome/free-solid-svg-icons';
import { Form, FormikProps, FormikValues } from 'formik';
import { useState } from 'react';
import { FirmwareLockStatus, UnitSystem, UnitType } from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FormNumberInput from '../../../shared/components/forms/form-number-input/FormNumberInput';
import FormUnitInput from '../../../shared/components/forms/form-number-input/FormUnitInput';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';
import { DeviceIdLength, MaximumNameLength } from '../../../shared/constants/ValidationConstants';
import useBlocker from '../../../shared/hooks/UseBlocker';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import FirmwareComboboxInput from '../../organisations/components/FirmwareComboboxInput';
import { DeviceBounds, UnitSystemToDefaultDeviceUnit } from '../validation/DeviceValidator';

type Props<T extends FormikValues> = {
  isEdit?: boolean;
  onClose: () => void;
  formik: FormikProps<T>;
  isOpen: boolean;
  isSuperAdmin: boolean;
  orgLock: string | undefined;
};

export default function DeviceRegistrationFormContent<T extends FormikValues>({
  isEdit,
  onClose,
  formik,
  isOpen,
  isSuperAdmin,
  orgLock,
}: Props<T>) {
  const [showOrgLockWarning, setShowOrgLockWarning] = useState<boolean>(false);

  const handleOrgLock = () => {
    if (orgLock && formik.values.firmwareId != orgLock && formik.values.firmwareId != formik.initialValues.firmwareId) {
      setShowOrgLockWarning(true);
      return false;
    } else {
      return true;
    }
  };

  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);
  const { unitSystem } = useSelectedOrganisationIdStore();

  const lockedOptions: DropDownOption<string>[] = [
    {
      value: FirmwareLockStatus.Unlocked,
      displayValue: 'Unlocked',
    },
    {
      value: FirmwareLockStatus.Locked,
      displayValue: 'Locked',
    },
  ];

  return (
    <>
      <RSDialog
        title={isEdit ? 'Edit Device' : 'Register Device'}
        isOpen={isOpen}
        actions={
          <>
            <FormConfirmCloseButton
              isBlocking={isBlocking}
              formik={formik}
              setIsBlocking={setIsBlocking}
              onClose={onClose}
            />
            <RSButton
              variant="primary"
              onClick={async () => {
                if (handleOrgLock()) {
                  setIsBlocking(false);
                  await formik.submitForm();
                }
              }}
            >
              {isEdit ? 'Update' : 'Add'}
            </RSButton>
          </>
        }
      >
        <Form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          onChange={() => {
            setIsBlocking(true);
          }}
        >
          <div className="grid sm:grid-cols-2 gap-x-4">
            {!isEdit && (
              // TODO update to only allow numeric input
              <FormTextInput
                required
                label="IMEI"
                name="imei"
                icon={faMobile}
                placeholder="Device IMEI number"
                maxLength={DeviceIdLength}
              />
            )}
            <FormTextInput
              required
              label="Alias"
              name="alias"
              icon={faPencil}
              placeholder="Friendly device name"
              maxLength={MaximumNameLength}
            />
            {isSuperAdmin && (
              <>
                <FormUnitInput
                  required
                  targetUnit={DeviceBounds.calibrationVolume.validationUnit}
                  defaultUnit={UnitSystemToDefaultDeviceUnit[unitSystem].calibrationVolume}
                  label="Calibration Volume"
                  name="calibrationVolume"
                  icon={faDroplet}
                  placeholder="The volume dispensed when calibrating the device."
                />
                <FormNumberInput
                  required
                  min={DeviceBounds.calibrationTimeout.min}
                  max={DeviceBounds.calibrationTimeout.max}
                  suffix="s"
                  label="Calibration Timeout"
                  name="calibrationTimeout"
                  icon={faClock}
                  placeholder="The maximum time spent calibrating the device."
                />
              </>
            )}
            <FormUnitInput
              required
              targetUnit={DeviceBounds.rinseVolume.validationUnit}
              defaultUnit={UnitSystemToDefaultDeviceUnit[unitSystem].rinseVolume}
              label="Rinse Volume"
              name="rinseVolume"
              icon={faDroplet}
              placeholder="The volume dispensed when rinsing the device."
            />
            <FormNumberInput
              required
              min={DeviceBounds.rinseTimeout.min}
              max={DeviceBounds.rinseTimeout.max}
              suffix={'s'}
              label="Rinse Timeout"
              name="rinseTimeout"
              icon={faClock}
              placeholder="The maximum time spent rinsing the device."
            />
            {isSuperAdmin && (
              <FormNumberInput
                required
                min={DeviceBounds.loggingPeriod.min}
                max={DeviceBounds.loggingPeriod.max}
                suffix={'s'}
                label="Logging Period"
                name="loggingPeriod"
                icon={faClockRotateLeft}
                placeholder="Period between device logs - seconds between 1-300."
              />
            )}
            <FormNumberInput
              required
              min={DeviceBounds.sleepTimeout.min}
              max={DeviceBounds.sleepTimeout.max}
              suffix={'min'}
              label="Sleep Timeout"
              name="sleepTimeout"
              icon={faBed}
              placeholder="Time the device is inactive before it is put in sleep mode."
            />
            <div className="col-span-2">
              <FormUnitInput
                required
                targetUnit={DeviceBounds.gpsAccuracy.validationUnit}
                defaultUnit={UnitSystemToDefaultDeviceUnit[unitSystem].gpsAccuracy}
                label="GPS Accuracy"
                name="gpsAccuracy"
                icon={faSatellite}
                placeholder="The accuracy of GPS coordinates reported between 1 and 100."
              />
            </div>

            <PermissionGuard policy={AuthPolicy.DeviceManufacturing}>
              <FirmwareComboboxInput
                label="Firmware"
                name="firmwareId"
                initialValue={{
                  value:
                    formik.initialValues.firmwareInformation?.candidate?.firmware.id ??
                    formik.initialValues.firmwareInformation?.slot1?.firmware.id,
                  displayValue:
                    formik.initialValues.firmwareInformation?.candidate?.firmware.version ??
                    formik.initialValues.firmwareInformation?.slot1?.firmware.version,
                }}
              />
              <FormSelectInput label="Locked" options={lockedOptions} name="firmwareLockStatus" icon={faLock} />
            </PermissionGuard>
          </div>
        </Form>
        <RSConfirmationDialog
          type="danger"
          onClose={() => setShowOrgLockWarning(false)}
          title={'Override Organisation Lock'}
          isOpen={showOrgLockWarning}
          onConfirm={async () => {
            setShowOrgLockWarning(false);
            await formik.submitForm();
          }}
        >
          {
            'This device is part of an organisation that has a locked firmware version. Selecting confirm will override the lock for this device.'
          }
        </RSConfirmationDialog>
      </RSDialog>
    </>
  );
}
