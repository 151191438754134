import { UserRole } from '../../graphql/generated';

export enum AuthPolicy {
  DeviceManagement,
  DeviceManufacturing,
  DeviceViewing,
  JobManagement,
  UpdateSprayArea,
  JobViewing,
  OrganisationManagement,
  OrganisationViewing,
  RecipeManagement,
  RecipeViewing,
  WeedManagement,
  WeedViewing,
  OrganisationMember,

  //Firmware
  FirmwareList,
  FirmwareManagement,

  //Webhook
  WebhookCreate,
  WebhookUpdate,
  WebhookDelete,
  WebhookViewing,
  WebhookList,
}
type AuthPolicyRolesInterface = {
  [key in AuthPolicy]: UserRole[];
};

const DefaultManagementRoles: Array<UserRole> = [
  UserRole.SuperAdmin,
  UserRole.OrganisationAdmin,
  UserRole.SkilledOperator,
];

const DefaultViewingRoles: Array<UserRole> = [
  UserRole.SuperAdmin,
  UserRole.OrganisationAdmin,
  UserRole.SkilledOperator,
  UserRole.Operator,
];

export const AuthPolicyRoles: AuthPolicyRolesInterface = {
  [AuthPolicy.DeviceManagement]: DefaultManagementRoles,
  [AuthPolicy.DeviceManufacturing]: [UserRole.SuperAdmin, UserRole.Manufacturer],
  [AuthPolicy.DeviceViewing]: DefaultViewingRoles,
  [AuthPolicy.JobManagement]: DefaultManagementRoles,
  [AuthPolicy.UpdateSprayArea]: DefaultManagementRoles,
  [AuthPolicy.JobViewing]: DefaultViewingRoles,
  [AuthPolicy.OrganisationManagement]: [UserRole.SuperAdmin, UserRole.OrganisationAdmin],
  [AuthPolicy.OrganisationViewing]: DefaultViewingRoles,
  [AuthPolicy.RecipeManagement]: DefaultManagementRoles,
  [AuthPolicy.RecipeViewing]: DefaultViewingRoles,
  [AuthPolicy.WeedManagement]: DefaultManagementRoles,
  [AuthPolicy.WeedViewing]: DefaultViewingRoles,
  [AuthPolicy.OrganisationMember]: [UserRole.OrganisationAdmin, UserRole.SkilledOperator, UserRole.Operator],
  [AuthPolicy.FirmwareList]: [UserRole.SuperAdmin, UserRole.Manufacturer],
  [AuthPolicy.FirmwareManagement]: [UserRole.SuperAdmin],
  [AuthPolicy.WebhookCreate]: [UserRole.OrganisationAdmin, UserRole.SuperAdmin],
  [AuthPolicy.WebhookViewing]: [UserRole.OrganisationAdmin, UserRole.SuperAdmin],
  [AuthPolicy.WebhookUpdate]: [UserRole.OrganisationAdmin, UserRole.SuperAdmin],
  [AuthPolicy.WebhookDelete]: [UserRole.OrganisationAdmin, UserRole.SuperAdmin],
  [AuthPolicy.WebhookList]: [UserRole.OrganisationAdmin, UserRole.SuperAdmin],
};
