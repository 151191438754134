import FormTextInput from '../form-text-input/FormTextInput';
import FormListInput from './FormListInput';

interface FormStringListInputProps {
  items: Array<KeyString>;
  name: string;
  outerLabel: string;
  innerLabel?: string;
  required?: boolean;
  newItem: string;
  maxItems?: number;
  minItems?: number;
}

export interface KeyString {
  key: string;
  value: string;
}

export default function FormStringListInput(props: FormStringListInputProps) {
  return (
    <FormListInput
      minItems={props.minItems}
      items={props.items}
      name={props.name}
      label={props.outerLabel}
      rowChildrenBuilder={(item, index) => (
        <div className="flex-grow m-2">
          <FormTextInput
            value={item.value}
            key={index}
            name={`${props.name}[${index}].value`}
            label={index == 0 ? props.innerLabel ?? '' : ''}
          />
        </div>
      )}
      newItem={toKeyString(props.newItem)}
    />
  );
}

export function toKeyString(value?: string) {
  return { key: generateRandomString(32), value: value };
}

function generateRandomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
