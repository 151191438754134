import { useNavigate } from 'react-router-dom';

export default function useCreateNavigate() {
  const navigate = useNavigate();

  return (id: string) => {
    //Navigates to created item's page
    //Needs to use setTimeout otherwise navigation does not work.
    setTimeout(() => {
      navigate(id);
    }, 0);
  };
}
