import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import ActiveJobsTable from '../components/ActiveJobsTable';
import RecipeUsageSummaryGraph from '../components/RecipeUsageSummaryGraph';
import TopSprayersTable from '../components/TopSprayersTable';

export default function DashboardPage() {
  const organisationId = useOrganisationId();

  return (
    <>
      <RSGrid>
        <RecipeUsageSummaryGraph organisationId={organisationId} />
        <TopSprayersTable organisationId={organisationId} />
        <ActiveJobsTable organisationId={organisationId} />
      </RSGrid>
    </>
  );
}
