import { Table } from '@tanstack/react-table';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RSButton from '../../components/input/rs-button/RSButton';

export default function PaginationOptions<T>(
  table: Table<T>,
  setPageSize: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  decrementPage: () => void,
  incrementPage: () => void,
) {
  return (
    <div className="flex items-center gap-2 justify-end mt-2 mr-2 pb-2">
      <span className="flex items-center gap-1">
        <div>Page</div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </span>
      <select className="border rounded-lg" value={table.getState().pagination.pageSize} onChange={setPageSize}>
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <RSButton variant={!table.getCanPreviousPage() ? 'disabled' : 'primary'} onClick={decrementPage}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </RSButton>
      <RSButton variant={!table.getCanNextPage() ? 'disabled' : 'primary'} onClick={incrementPage}>
        <FontAwesomeIcon icon={faChevronRight} />
      </RSButton>
    </div>
  );
}
