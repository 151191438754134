import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, useField } from 'formik';
import { ReactNode, useState } from 'react';
import RSButton from '../../input/rs-button/RSButton';
import FormLabel from '../FormLabel';
import { makeUniqueId } from '@apollo/client/utilities';
import FormErrorText from '../form-error-text/FormErrorText';

interface Props<T> {
  items: Array<T>;
  name: string;
  label: string;
  required?: boolean;
  rowChildrenBuilder: (item: T, index: number) => Array<ReactNode> | ReactNode;
  newItem: T;
  maxItems?: number;
  minItems?: number;
}

export default function FormListInput<T>(props: Props<T>) {
  const [, meta] = useField({ name: props.name });

  const [idList, setIdList] = useState<string[]>(
    Array.from({ length: props.items.length }, (_, __) => makeUniqueId('')),
  );

  const addToIdList = () => {
    const ids = idList;
    ids.push(makeUniqueId(''));
    setIdList(ids);
  };

  const removeIdFromList = (index: number) => {
    const ids = idList.filter((x) => x !== idList.at(index));
    setIdList(ids);
  };

  return (
    <div className="flex flex-col my-1">
      <FormLabel label={props.label} required={props.required} />
      <FieldArray
        name={props.name}
        render={(arrayHelpers) => {
          return (
            <div className="border rounded-lg p-2">
              {props.items && props.items.length > 0
                ? props.items.map((item: T, index: number) => {
                    return (
                      <div
                        className={`flex flex-row gap-x-4 ${index == 0 ? 'items-center' : 'items-start'}`}
                        key={idList.at(index)}
                      >
                        {props.rowChildrenBuilder(item, index)}
                        <RSButton
                          className={`py-2 ${index === 0 ? 'mt-6' : 'mt-1'}`}
                          variant={props.items && props.items.length <= (props.minItems ?? 0) ? 'disabled' : 'danger'}
                          onClick={() => {
                            arrayHelpers.remove(index);
                            arrayHelpers.form.setFieldTouched(props.name, true);
                            removeIdFromList(index);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </RSButton>
                      </div>
                    );
                  })
                : ''}
              <div className="flex flex-row justify-end gap-x-2">
                <RSButton
                  variant={props.maxItems && props.maxItems == (props?.items?.length ?? 0) ? 'disabled' : 'primary'}
                  className="py-2"
                  onClick={() => {
                    arrayHelpers.insert(props.items.length + 1, props.newItem);
                    addToIdList();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </RSButton>
              </div>
            </div>
          );
        }}
      />
      <FormErrorText meta={meta} />
    </div>
  );
}
