import { Job, useExistingJobsQuery } from '../../../graphql/generated';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Combobox } from '@headlessui/react';
import SpinLoader from '../../../shared/layout/loading/SpinLoader';
import { useState } from 'react';
import FormLabel from '../../../shared/components/forms/FormLabel';

interface ExistingJobSearch {
  setSelectedJob: (selection: Job) => void;
}

export default function ExistingJobSearch(props: ExistingJobSearch) {
  const [selectedOption, setSelectedOption] = useState<Job>();
  const organisationId = useOrganisationId();
  const first = 3;

  const { data, loading, refetch } = useExistingJobsQuery({
    variables: {
      id: organisationId,
      searchString: '',
      first: first,
    },
  });

  const jobs = data?.existingJobs?.edges?.map((e) => e.node as Job) ?? [];

  return (
    <Combobox
      value={selectedOption?.name}
      onChange={(val: string) => {
        const job = val as unknown as Job;
        props.setSelectedJob(job);
        setSelectedOption(job);
      }}
    >
      <div className="flex flex-col relative w-full">
        <FormLabel label="Job Search" />
        <div className="rs-form-icon-container w-full items-center">
          <FontAwesomeIcon className="w-5 h-10" icon={faSearch} />
          <DebounceInput
            className="rs-form-input w-full bg-input-gray text-lg"
            onChange={(e) => {
              refetch({
                id: organisationId,
                searchString: e.target.value,
                first: first,
              });
            }}
            placeholder="Search"
            value={''}
            debounceTimeout={500}
            element={Combobox.Input}
          />
        </div>
        <Combobox.Options className="drop-down-options z-30">
          {/* Loading Option */}
          {loading && (
            <Combobox.Option
              key="loading"
              className="group flex flex-row w-full justify-center items-center rounded-md px-2 py-2 text-sm"
              value={undefined}
            >
              <SpinLoader innerClassName=" text-center w-8 h-8 text-lg justify-center" />
            </Combobox.Option>
          )}
          {/* Regular options */}
          {!loading &&
            jobs.map((option, idx) => {
              return (
                <Combobox.Option key={idx} value={option}>
                  {({ active, selected }) => (
                    <div
                      className={`group flex w-full items-center rounded-md px-2 py-2 text-sm ${
                        active || selected ? 'bg-primary text-white' : 'bg-white text-black'
                      }`}
                    >
                      {selected && <FontAwesomeIcon className="text-lg" icon={faCheck} />}
                      <span className="font-bold">{option.name}</span>
                      <span>{`- ${option.description}`}</span>
                    </div>
                  )}
                </Combobox.Option>
              );
            })}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
