import { Children, ReactElement } from 'react';
import RSButton from '../../input/rs-button/RSButton';
import RSDialog from '../RSDialog';

type ConfirmationType = 'primary' | 'danger' | 'disabled';

interface Props {
  isOpen: boolean;
  title: string;
  type: ConfirmationType;
  closeTitle?: string;
  confirmTitle?: string;
  onConfirm: () => any;
  onClose: () => any;
  //move text to children to better genericise this component
  children: ReactElement | string | undefined;
}

export default function RSConfirmationDialog(props: Props) {
  const confirmTitle = props.confirmTitle ? props.confirmTitle : 'Confirm';
  const closeTitle = props.closeTitle ? props.closeTitle : 'Go Back';

  return (
    <RSDialog
      title={props.title}
      isOpen={props.isOpen}
      actions={
        <>
          <RSButton variant="secondary" onClick={props.onClose}>
            {closeTitle}
          </RSButton>
          <RSButton variant={props.type} onClick={props.onConfirm}>
            {confirmTitle}
          </RSButton>
        </>
      }
    >
      {props.children}
    </RSDialog>
  );
}
