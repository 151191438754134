import { Chemical, useGetChemicalUsageQuery } from '../../../graphql/generated';
import ReactECharts from 'echarts-for-react';
import { useMemo } from 'react';
import moment from 'moment';
import GridTile from '../../../shared/layout/tiles/GridTile';
import { Formik } from 'formik';
import FormDateInput from '../../../shared/components/forms/form-date-input/FormDateInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import { chemicalTypeAxisLabels } from '../utilities/ChemicalTypeUtilities';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import { concentrationUnitUsageForChemicalType } from '../../recipes/utilities/ConcentrationUtilities';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';

interface ChemicalUsageGraphProps {
  chemical: Chemical;
}

interface FormValues {
  monthOf: Date;
}

export default function ChemicalUsageGraph({ chemical }: ChemicalUsageGraphProps) {
  const { unitSystem } = useSelectedOrganisationIdStore();

  const monthOf = useMemo(() => {
    // if it has been deleted cap/set the usage search to when it was deleted
    return chemical.deleteInformation?.deletedAt ? moment(chemical.deleteInformation?.deletedAt) : moment();
  }, []);
  const { data, refetch } = useGetChemicalUsageQuery({
    variables: {
      input: {
        chemicalId: chemical.id as string,
        monthOf: monthOf,
      },
    },
  });

  const { dates, series, total } = useMemo(() => {
    const dates: Array<string> = [];
    const seriesData: Array<number> = [];
    let total = 0;

    // Get the dates to display
    data?.chemicalUsage?.usage?.forEach((e) => {
      if (e.value.value == 0) return;
      dates.push(moment(e.key).format('ddd D/MM/y'));
      seriesData.push(e.value.value);
      total += e.value.value;
    });

    console.log(data);

    const series: echarts.BarSeriesOption = {
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        formatter: (ctx) => {
          const val = ctx.value as number;
          return val !== 0
            ? `${(ctx.value as number).toFixed(2)} ${GetUnitSymbol(
                concentrationUnitUsageForChemicalType[chemical.type][unitSystem],
              )}`
            : '';
        },
      },
      emphasis: {
        focus: 'series',
      },
      data: seriesData,
    };

    return { dates, series, total };
  }, [data]);

  const options: echarts.EChartsOption = {
    tooltip: {
      show: true,
      formatter: () => {
        return `Total: ${total.toFixed(2)} ${GetUnitSymbol(
          concentrationUnitUsageForChemicalType[chemical.type][unitSystem],
        )}`;
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      name: `${chemicalTypeAxisLabels[chemical.type]} (${GetUnitSymbol(
        concentrationUnitUsageForChemicalType[chemical.type][unitSystem],
      )})`,
      nameRotate: 90,
      nameLocation: 'middle',
    },
    xAxis: {
      type: 'category',
      data: dates,
    },
    series: series,
  };

  return (
    <GridTile
      className="col-span-3 md:col-span-6"
      title={'Usage'}
      actions={
        <>
          <Formik<FormValues>
            initialValues={{
              monthOf: monthOf.toDate(),
            }}
            onSubmit={function (values: FormValues) {
              refetch({
                input: {
                  chemicalId: chemical.id,
                  monthOf: values.monthOf,
                },
              });
            }}
          >
            {(formik) => {
              return (
                <>
                  <FormDateInput
                    name={'monthOf'}
                    label={'Month Of'}
                    inLineLabel
                    notLocal
                    max={monthOf.format('yyy-MM-DD')}
                  />
                  <RSButton
                    variant="primary"
                    onClick={async () => {
                      await formik.submitForm();
                    }}
                  >
                    Search
                  </RSButton>
                </>
              );
            }}
          </Formik>
        </>
      }
    >
      <ReactECharts option={options} />{' '}
    </GridTile>
  );
}
