import { useMemo } from 'react';
import useCurrentUserStore from './stores/UseCurrentUserStore';
import useSelectedOrganisationIdStore from './stores/UseSelectedOrganisationIdStore';

export default function useOrganisationId() {
  const currentUserOrganisationId = useCurrentUserStore((state) => state.getCurrentUserOrganisationId());
  const { selectedOrganisation } = useSelectedOrganisationIdStore();

  return useMemo(
    () => (selectedOrganisation?.id ?? currentUserOrganisationId) as string,
    [currentUserOrganisationId, selectedOrganisation],
  );
}
