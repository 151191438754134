import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo } from 'react';
import { Job, JobStatus, UnitSystem, UnitType, WeatherInformationDto } from '../../../graphql/generated';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import RSTableRow from '../../../shared/layout/table/RSTableRow';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';
import { BearingToDirectionText } from '../utilities/WindBearingUtilities';

interface WeatherInformationProps {
  job: Job;
}

export const WeatherInformationUnitSystemUnitTypes: {
  [key in UnitSystem]: {
    deltaT: UnitType;
    temperature: UnitType;
    windSpeed: UnitType;
    humidity: UnitType;
    windDirection: UnitType;
  };
} = {
  [UnitSystem.Imperial]: {
    deltaT: UnitType.Fahrenheit,
    temperature: UnitType.Fahrenheit,
    windSpeed: UnitType.MilesPerHour,
    humidity: UnitType.Percent,
    windDirection: UnitType.Degree,
  },
  [UnitSystem.Metric]: {
    deltaT: UnitType.Celsius,
    temperature: UnitType.Celsius,
    windSpeed: UnitType.MetresPerSecond,
    humidity: UnitType.Percent,
    windDirection: UnitType.Degree,
  },
};

export default function WeatherInformationTab({ job }: WeatherInformationProps) {
  if (job.status == JobStatus.Incomplete) {
    return (
      <div className="flex flex-row justify-center h-full w-full p-8 overflow-x-auto">
        Weather information unavailable - you must first complete the job.
      </div>
    );
  } else {
    const { unitSystem, selectedOrganisation } = useSelectedOrganisationIdStore();
    const units = WeatherInformationUnitSystemUnitTypes[unitSystem];

    const columns = useMemo(
      () =>
        [
          {
            id: 'time',
            header: 'Time',
            cell: (ctx) => moment(ctx.row.original.timestamp).toLocaleString(),
          },
          {
            id: 'deltaT',
            header: `Delta T (ΔT ${GetUnitSymbol(units.deltaT)})`,
            cell: (ctx) => ctx.row.original.deltaT.value.toFixed(2),
          },
          {
            id: 'temperature',
            header: `Temperature (${GetUnitSymbol(units.temperature)})`,
            cell: (ctx) => ctx.row.original.temperature.value.toFixed(2),
          },
          {
            id: 'humidity',
            header: `Humidity (${GetUnitSymbol(units.humidity)})`,
            cell: (ctx) => ctx.row.original.humidity.value.toFixed(2),
          },
          {
            id: 'windSpeed',
            header: `Wind Speed (${GetUnitSymbol(units.windSpeed)})`,
            cell: (ctx) => ctx.row.original.windSpeed.value.toFixed(2),
          },
          {
            id: 'windDirection',
            header: `Wind Direction (${GetUnitSymbol(units.windDirection)})`,
            cell: (ctx) =>
              `${ctx.row.original.windBearing.value} (${BearingToDirectionText(ctx.row.original.windBearing.value)})`,
          },
        ] as ColumnDef<WeatherInformationDto>[],
      [],
    );

    const table = useReactTable({
      data: job.weatherInformation,
      getCoreRowModel: getCoreRowModel(),
      columns: columns,
    });

    return (
      <div className="flex flex-row justify-around h-full w-full overflow-x-auto">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="pl-2 py-3" align="left" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="rs-table-body">
            {table.getRowModel().rows.map((row) => {
              return <RSTableRow key={row.id} row={row} onClick={() => {}} toggleSelect={false} />;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
