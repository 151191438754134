import { WebhookInfrastructureStatusDetails } from '../../../graphql/generated';
import RSBadge from '../../../shared/components/tags/RSBadge';
import { WebhookInfrastructureStatusDefinitions } from '../WebhookInfrastructureStatusUtils';

type WebhookInfrastructureStatusTagProps = {
  status: WebhookInfrastructureStatusDetails;
};

export function WebhookInfrastructureStatusTag({ status }: WebhookInfrastructureStatusTagProps) {
  const definition = WebhookInfrastructureStatusDefinitions[status.status];
  return <RSBadge title={definition.displayName} type={definition.badgeVariant} />;
}
