import * as Yup from 'yup';
import {
  MaximumDescriptionLength,
  MaximumLongNameLength,
  MongoIdLength,
  MaximumChemicalActiveIngredientLength,
} from '../../../shared/constants/ValidationConstants';
import { ChemicalType } from '../../../graphql/generated';

export const chemicalValidator = Yup.object({
  organisationId: Yup.string().required().length(MongoIdLength),
  name: Yup.string().required().max(MaximumLongNameLength).default(''),
  activeIngredient: Yup.string().required().max(MaximumChemicalActiveIngredientLength).default(''),
  manufacturer: Yup.string().required().max(MaximumLongNameLength).default(''),
  description: Yup.string().required().max(MaximumDescriptionLength).default(''),
  type: Yup.string().oneOf(Object.values(ChemicalType)).default(ChemicalType.Liquid),
});

export const chemicalUpdateValidator = chemicalValidator
  .shape({
    chemicalId: Yup.string().required().length(MongoIdLength),
  })
  .omit(['organisationId']);
