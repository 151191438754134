import { Table } from '@tanstack/react-table';
import { Formik, FormikValues } from 'formik';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Job, DeviceUnassignedSprayLogSummary, useGetUnassignedSprayLogsQuery } from '../../../graphql/generated';
import FormDateInput from '../../../shared/components/forms/form-date-input/FormDateInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { RSTableBase, RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import { DateInputFormat, DateTimeWithTimezone } from '../../../shared/utilities/TimeUtilities';
import AddLogsToJobDialog from './AddLogsToJobDialog';
import { GetFullUnitDisplay } from '../../../shared/utilities/UnitConversionUtilities';

interface UnassignedDeviceLogsProps {
  setJob: (job: Partial<Job>) => void;
}

export default function UnassignedDeviceLogs(props: UnassignedDeviceLogsProps) {
  const currentOrganisation = useOrganisationId();

  const [defaultStartTime, defaultEndTime] = useMemo(() => [moment().startOf('d'), moment().endOf('d')], []);
  const [table, setTable] = useState<Table<DeviceUnassignedSprayLogSummary>>();
  const [numSelectedRows, setNumSelectedRows] = useState<number>(0);
  const [showAddToJobDialog, setShowAddToJobDialog] = useState<boolean>(false);

  const { data, refetch } = useGetUnassignedSprayLogsQuery({
    variables: {
      input: {
        organisationId: currentOrganisation,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
    },
    onError(error) {
      toast(`${error.message}`, {
        type: 'error',
      });
    },
  });

  const cols = useMemo(
    () =>
      [
        {
          title: 'Device ID',
          id: 'deviceId',
          accessor: (x) => x.device.id,
        },
        {
          title: 'Device Alias',
          id: 'deviceAlias',
          accessor: (x) => x.device.alias,
        },
        {
          title: 'Earliest Log',
          id: 'startTime',
          accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
        },
        {
          title: 'Latest Log',
          id: 'endTime',
          accessor: (x) => (x.endTime ? moment(x.endTime).toLocaleString() : 'N/A'),
        },

        {
          title: 'Volume Dispensed',
          id: 'volumeDispensed',
          accessor: (x) => `${GetFullUnitDisplay(x.volumeDispensed)}`,
        },
      ] as Array<RSTableColumnDefinition<DeviceUnassignedSprayLogSummary>>,
    [],
  );

  interface FormValues extends FormikValues {
    startTime?: Date;
    endTime?: Date;
  }

  return (
    <>
      <GridTile
        colSpan={6}
        title={'Unassigned Device Logs'}
        actions={
          <>
            <Formik<FormValues>
              initialValues={{
                startTime: defaultStartTime.toDate(),
                endTime: defaultEndTime.toDate(),
              }}
              onSubmit={function (values: FormValues) {
                setNumSelectedRows(0);
                refetch({
                  input: {
                    organisationId: currentOrganisation,
                    startTime: moment(values.startTime).format(DateTimeWithTimezone),
                    endTime: moment(values.endTime).format(DateTimeWithTimezone),
                  },
                });
              }}
            >
              {(formik) => {
                const maxEndTime = useMemo(
                  () => moment(formik.values.startTime).add(1, 'd').subtract(1, 'm'),
                  [formik.values.startTime],
                );
                return (
                  <>
                    <FormDateInput name={'startTime'} label={'From'} inLineLabel />
                    <FormDateInput
                      name={'endTime'}
                      label={'To'}
                      inLineLabel
                      max={maxEndTime.format(DateInputFormat)}
                      validate={(value) => {
                        const current = moment(value);
                        if (maxEndTime < current && current > moment(formik.values.startTime)) {
                          return 'Range should be less than a day.';
                        }
                      }}
                    />
                    <RSButton
                      variant="primary"
                      onClick={async () => {
                        await formik.submitForm();
                      }}
                    >
                      Search
                    </RSButton>
                  </>
                );
              }}
            </Formik>
            <RSButton
              variant={numSelectedRows > 0 ? 'primary' : 'disabled'}
              onClick={() => {
                setShowAddToJobDialog(true);
              }}
            >
              Create Job
            </RSButton>
          </>
        }
      >
        <RSTableBase<DeviceUnassignedSprayLogSummary>
          data={(data?.unassignedSprayLogs as DeviceUnassignedSprayLogSummary[]) ?? []}
          columns={cols}
          toggleSelect
          tableCallback={setTable}
          onClick={(row) => setNumSelectedRows(numSelectedRows + (row.getIsSelected() ? -1 : 1))}
        />
      </GridTile>
      {showAddToJobDialog && (
        <AddLogsToJobDialog
          isOpen={showAddToJobDialog}
          onClose={() => setShowAddToJobDialog(false)}
          selectedRows={numSelectedRows > 0 ? table?.getSelectedRowModel() : undefined}
          setJob={(job) => {
            props.setJob(job);
            setShowAddToJobDialog(false);
          }}
        />
      )}
    </>
  );
}
