import { useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { faCalendar, faEdit, faEnvelope, faIdCard, faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AdminGetUserDocument,
  Device,
  GetUserDocument,
  Job,
  Organisation,
  Recipe,
  useAdminDisableUserMutation,
  useAdminEnableUserMutation,
  useAdminResendInviteMutation,
  User,
  UserRole,
  UserStatus,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';
import OrganisationTag from '../../../shared/components/tags/OrganisationTag';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import useCurrentUserStore from '../../../shared/hooks/stores/UseCurrentUserStore';
import { millisecondsInDay } from '../../../shared/utilities/TimeUtilities';
import UserFormDialog from '../components/UserFormDialog';
import { userRoleToText } from '../utilities/UserRoleUtilities';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default function UserPage({}: Props) {
  const currentUserRole = useCurrentUserStore((state) => state.getCurrentUserRole()) as UserRole;
  const currentUserId = useCurrentUserStore((state) => state.currentUser?.username) as string;

  const [disableUser] = useAdminDisableUserMutation();
  const [enableUser] = useAdminEnableUserMutation();
  const [resendInvite] = useAdminResendInviteMutation();

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowEnableToggle] = useState<boolean>(false);
  const { userId } = useParams();

  const navigate = useNavigate();

  const {
    data: data,
    loading: loading,
    error: error,
    fetchMore,
  } = useQuery(
    [UserRole.SuperAdmin, UserRole.OrganisationAdmin].includes(currentUserRole)
      ? AdminGetUserDocument
      : GetUserDocument,
    {
      variables: {
        id: userId as string,
      },
    },
  );

  const user = data?.user as User;

  // Select appropriate function for enabling/disabling the user
  const toggleFunction = user?.enabled ? disableUser : enableUser;

  useSetBreadcrumbs({
    loading: loading,
    entity: user,
    params: {
      userId: (x) => `${x.firstName} ${x.lastName}`,
    },
  });

  const rows = useMemo(() => {
    const rows: RSPivotRowProps[] = [
      {
        name: 'Name',
        value: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
        icon: faEdit,
      },
      {
        name: 'Email',
        value: user?.email ?? '',
        icon: faEnvelope,
      },
      {
        name: 'Role',
        value: user?.role ? userRoleToText[user?.role] : '',
        icon: faIdCard,
      },
    ];

    if (currentUserRole === UserRole.SuperAdmin) {
      rows.push({
        name: 'Organisation',
        value: <OrganisationTag organisation={user?.organisation as Organisation} navigate={navigate} />,
        icon: faCalendar,
      });
    }

    return rows;
  }, [currentUserRole, data]);

  const jobCols: Array<RSTableColumnDefinition<Job>> = [
    {
      title: 'Name',
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Start Time',
      accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
      id: 'startTime',
    },
    {
      title: 'Device',
      accessor: (x) => {
        const device = x.deviceAssignments.find((x) => x?.user.id == user.id)?.device as Device;
        return (
          <RSTag
            className="bg-gray-300"
            title={device?.alias}
            onClick={(event) => {
              event.stopPropagation();
              return navigate(`${localRoutingConstants.devices.root}/${device.id}`);
            }}
          />
        );
      },
      id: 'device',
    },
    {
      title: 'Recipe',
      accessor: (x) => {
        const recipe = x.recipe as Recipe;
        return (
          <RSTag
            className="bg-gray-300"
            title={recipe?.name}
            onClick={(event) => {
              event.stopPropagation();
              return navigate(`${localRoutingConstants.recipes.root}/${recipe.id}`);
            }}
          />
        );
      },
      id: 'recipe',
    },
  ];

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName={`user '${userId}'`} apolloError={error as ApolloError} />;
  } else if (user === null && !loading) {
    return <NotFoundPage />;
  }

  // Whether or not the user has logged in/been confirmed
  const isConfirmed = user.userStatus != UserStatus.Confirmed;
  // Whether or not this user can have their invite resent
  const canResend = isConfirmed && Date.now() < Date.parse(user.registrationDate) + millisecondsInDay;

  return (
    <>
      {/* Component */}
      <RSGrid>
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <>
              {/* Must have org management perms and can't disable themself */}
              <PermissionGuard policy={AuthPolicy.OrganisationManagement} isVisible={user.id !== currentUserId}>
                <RSButton variant="danger" onClick={() => setShowEnableToggle(true)}>
                  {user.enabled ? 'Disable' : 'Enable'}
                </RSButton>
              </PermissionGuard>
              <PermissionGuard policy={AuthPolicy.OrganisationManagement}>
                <RSButton variant="primary" onClick={() => setShowEdit(true)}>
                  <FontAwesomeIcon icon={faPencil} />
                  Edit User
                </RSButton>
                {isConfirmed && (
                  <RSButton
                    title={canResend ? 'One resend permitted per day.' : undefined}
                    variant={canResend ? 'disabled' : 'primary'}
                    onClick={async () => {
                      // Perform mutation to resend user invite
                      await resendInvite({
                        variables: {
                          input: {
                            userId: user.id,
                          },
                        },
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                    Resend Invite
                  </RSButton>
                )}
              </PermissionGuard>
            </>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        <GridTile colSpan={3} title={'Recent Jobs'}>
          <RSTable<Job>
            data={(user.jobs?.edges?.map((e) => e.node) as unknown as Job[]) ?? []}
            totalCount={data?.user?.jobs?.totalCount}
            columns={jobCols}
            onClickPathPrefix={`${localRoutingConstants.jobs.root}`}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.user?.jobs?.pageInfo.endCursor as string) : undefined,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.OrganisationManagement} isVisible={showEdit}>
        <UserFormDialog
          initialValues={user}
          isOpen={true}
          onClose={() => setShowEdit(false)}
          isOrganisational={false}
        />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.OrganisationManagement} isVisible={showDelete}>
        <RSConfirmationDialog
          type="danger"
          isOpen={true}
          onClose={() => setShowEnableToggle(false)}
          title={`${user.enabled ? 'Disable' : 'Enable'} User?`}
          onConfirm={async () => {
            await toggleFunction({
              variables: {
                input: {
                  userId: user.id,
                },
              },
              onCompleted() {
                setShowEnableToggle(false);
                toast(
                  `You successfully ${user.enabled ? 'disabled' : 'enabled'} user with name "${user.firstName} ${
                    user.lastName
                  }"`,
                  {
                    type: 'success',
                  },
                );
              },
              onError(error) {
                console.log(JSON.stringify(error));
                toast(error.message, {
                  type: 'error',
                });
              },
            });
          }}
        >{`Selecting confirm will ${
          user.enabled ? 'disable the user preventing them from logging in' : 'enable the user allowing them to login'
        }, are you sure you want to proceed?`}</RSConfirmationDialog>
      </PermissionGuard>
    </>
  );
}
