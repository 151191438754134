import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Concentration,
  Recipe,
  useGetRecipesByOrganisationIdQuery,
  RecipeSortInput,
  RecipeFilterInput,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import ConcentrationTags from '../components/ConcentrationTags';
import RecipeFormDialog from '../components/RecipeFormDialog';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';

export default function RecipesPage() {
  const [showCreateRecipe, setShowCreateRecipe] = useState<boolean>(false);

  const currentOrganisation = useOrganisationId();

  const {
    searchString: searchString,
    where: where,
    setSearchString: setSearchString,
    order: order,
    setSort: setSort,
  } = useSearchSortFilter<RecipeFilterInput>({
    searchableFieldNames: ['name', 'description'],
  });

  const {
    data: data,
    loading: loading,
    fetchMore: fetchMore,
  } = useGetRecipesByOrganisationIdQuery({
    variables: {
      id: currentOrganisation,
      where: where,
      order: order,
    },
  });

  const cols = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
    {
      title: 'Chemicals',
      id: 'chemicals',
      accessor: (x) => <ConcentrationTags concentrations={(x.concentrations as Concentration[]) ?? []} jobId={null} />,
    },
  ] as Array<RSTableColumnDefinition<Recipe>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Recipes'}
          actions={
            <PermissionGuard policy={AuthPolicy.RecipeManagement}>
              <RSButton onClick={() => setShowCreateRecipe(true)}>
                <FontAwesomeIcon icon={faPlus} />
                Create Recipe
              </RSButton>
            </PermissionGuard>
          }
        >
          <SearchSortFilterBar<RecipeSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['name', 'description']}
          />
          <RSTable<Recipe>
            isLoading={loading}
            columns={cols}
            data={(data?.recipesByOrganisationId?.edges?.map((e) => e.node) as Recipe[]) ?? []}
            totalCount={data?.recipesByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.recipesByOrganisationId?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={showCreateRecipe}>
        <RecipeFormDialog
          currentOrganisation={currentOrganisation}
          isOpen={true}
          onClose={() => setShowCreateRecipe(false)}
        />
      </PermissionGuard>
    </>
  );
}
