import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { OptionalObjectSchema } from 'yup/lib/object';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { toast } from 'react-toastify';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useBlocker from '../../../shared/hooks/UseBlocker';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FormDateInput from '../../../shared/components/forms/form-date-input/FormDateInput';
import FormListInput from '../../../shared/components/forms/form-list-input/FormListInput';
import { defaultDeviceAssignment } from '../validation/JobValidator';
import {
  Device,
  DeviceAssignment,
  DeviceFilterInput,
  DevicesSearchQuery,
  DevicesSearchQueryVariables,
  Job,
  Recipe,
  RecipeFilterInput,
  RecipeSearchQuery,
  RecipeSearchQueryVariables,
  useDevicesSearchQuery,
  User,
  useRecipeSearchQuery,
  UserFilterInput,
  UserSearchQuery,
  UserSearchQueryVariables,
  useUserSearchQuery,
} from '../../../graphql/generated';
import moment, { duration } from 'moment';
import { DateInputFormat } from '../../../shared/utilities/TimeUtilities';
import WeedAssignmentsInput from './WeedAssignmentsInput';
import { ComboBoxItem } from '../../../shared/components/input/combo-box/ComboBox';
import { MaximumNameLength, MaximumDescriptionLength } from '../../../shared/constants/ValidationConstants';
import MapWithAutocomplete from '../../../shared/components/maps/MapWithAutoComplete';
import FormComboBoxWithQuery from '../../../shared/components/forms/form-combo-box/FormComboBoxWithQuery';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import { applicationTypeOptions } from '../utilities/ApplicationTypeUtilities';

type Props<TFormInput extends FormikValues> = {
  initialValues?: Partial<Job>;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: (submitted: boolean) => void;
  onSubmit: (values: TFormInput) => Promise<void>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
  currentOrganisation: string;
  initialValuesMapper: (initialValues: Partial<Job> | undefined) => TFormInput;
};

// Abstracted user form allowing for the same structure to be used for editing and creating a user
export default function JobFormContent<TFormInput extends FormikValues>({
  currentOrganisation,
  isEdit,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
  initialValuesMapper,
}: Props<TFormInput>) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  return (
    <Formik<TFormInput>
      initialValues={initialValuesMapper(initialValues)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers: FormikHelpers<TFormInput>) => {
        try {
          await onSubmit(values);
          helpers.resetForm();
          onClose(true);
        } catch (ex) {
          console.log(ex);
          toast(`Error: ${ex}`, {
            type: 'error',
          });
        }
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title={isEdit ? 'Edit Job' : 'Add Job'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={() => {
                    formik.resetForm();
                    onClose(false);
                  }}
                />
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  {isEdit ? 'Update' : 'Add'}
                </RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Name"
                name="name"
                placeholder="Name of job"
                maxLength={MaximumNameLength}
              />
              <FormTextAreaInput
                required
                label="Description"
                name="description"
                placeholder="Write a description for the job here..."
                maxLength={MaximumDescriptionLength}
              />
              <div className="grid sm:grid-cols-2 gap-x-4">
                <FormDateInput name={'startTime'} label={'Start Time'} />
                <FormDateInput
                  name={'endTime'}
                  label={'End Time'}
                  min={
                    formik.values.startTime
                      ? moment(formik.values.startTime).add(duration(1, 'minute')).format(DateInputFormat)
                      : undefined
                  }
                  max={
                    formik.values.startTime
                      ? moment(formik.values.startTime).add(duration(1, 'day')).format(DateInputFormat)
                      : undefined
                  }
                />
              </div>
              <FormSelectInput options={applicationTypeOptions} name={'applicationType'} label={'Application Type'} />
              <MapWithAutocomplete name="location" location={formik.values.location} />
              <FormListInput
                minItems={0}
                items={formik.values.deviceAssignments}
                name={'deviceAssignments'}
                label={'Device Assignments'}
                rowChildrenBuilder={(_, index) => {
                  const hasLabel = index == 0;
                  const deviceAssignments = initialValues?.deviceAssignments
                    ? (initialValues?.deviceAssignments as DeviceAssignment[])
                    : formik.values.deviceAssignments ?? [];
                  return (
                    <>
                      <FormComboBoxWithQuery<Device, DevicesSearchQuery, DevicesSearchQueryVariables, DeviceFilterInput>
                        initialValue={{
                          value: deviceAssignments[index]?.device?.id ?? '',
                          displayValue: deviceAssignments[index]?.device?.alias ?? '',
                        }}
                        label={hasLabel ? 'Device' : undefined}
                        required={hasLabel}
                        name={`deviceAssignments.${index}.deviceId`}
                        dataAccessorCallback={(data) =>
                          SelectedDeviceFilter(
                            data?.devicesByOrganisationId?.nodes?.map((dev) => ({
                              displayValue: dev.alias,
                              value: dev.id,
                            })) ?? [],
                            formik.values.deviceAssignments,
                            index,
                          ) as ComboBoxItem<Device>[]
                        }
                        searchFieldNames={['alias']}
                        query={useDevicesSearchQuery}
                        variables={{ organisationId: currentOrganisation }}
                      />
                      <FormComboBoxWithQuery<User, UserSearchQuery, UserSearchQueryVariables, UserFilterInput>
                        initialValue={{
                          value: deviceAssignments[index]?.user?.id ?? '',
                          displayValue: deviceAssignments[index]?.user?.fullName ?? '',
                        }}
                        label={hasLabel ? 'User' : undefined}
                        required={hasLabel}
                        name={`deviceAssignments.${index}.userId`}
                        dataAccessorCallback={(data) =>
                          (data?.usersByOrganisationId?.nodes?.map((user) => ({
                            displayValue: user.fullName,
                            value: user.id,
                          })) as ComboBoxItem<User>[]) ?? []
                        }
                        searchFieldNames={['firstName', 'lastName', 'email']}
                        query={useUserSearchQuery}
                        variables={{ id: currentOrganisation }}
                      />
                    </>
                  );
                }}
                newItem={defaultDeviceAssignment}
              />
              <WeedAssignmentsInput
                currentOrganisation={currentOrganisation}
                weedAssignments={initialValues?.weedAssignments}
              />
              <FormComboBoxWithQuery<Recipe, RecipeSearchQuery, RecipeSearchQueryVariables, RecipeFilterInput>
                initialValue={{
                  value: initialValues?.recipe?.id ?? '',
                  displayValue: initialValues?.recipe?.name ?? '',
                }}
                label="Recipe"
                name={`recipeId`}
                dataAccessorCallback={(data) =>
                  data?.recipesByOrganisationId?.nodes?.map((rec) => ({
                    displayValue: rec.name,
                    value: rec.id,
                  })) ?? []
                }
                searchFieldNames={['name']}
                query={useRecipeSearchQuery}
                variables={{ id: currentOrganisation }}
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}

function SelectedDeviceFilter(devices: ComboBoxItem[], selected: { deviceId: string }[], index: number) {
  return devices.filter((x) => selected[index].deviceId == x.value || !selected.some((s) => s.deviceId == x.value));
}
