import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import FormErrorText from '../form-error-text/FormErrorText';
import FormLabel from '../FormLabel';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  icon?: IconProp;
  maxLength: number;
  minLength?: number;
};

export default function FormTextAreaInput(props: Props) {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col my-1">
      <FormLabel label={props.label} required={props.required} />
      <div className="rs-form-icon-container w-full ">
        {/* Icon */}
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {/* Input */}
        <textarea
          className={`rs-form-input w-full ${!props.icon && 'pl-4'} ${meta.error && meta.touched && 'border-red-500'}`}
          {...field}
          {...props}
          placeholder={props.placeholder}
        />
        {/* Error */}
      </div>
      <FormErrorText meta={meta} />
    </div>
  );
}
