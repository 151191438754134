import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import useOverrideBreadcrumbStore from '../../hooks/stores/UseOverrideBreadcrumbStore';
import { authenticatedRoutes } from '../../routes/Routes';

type RSBreadcrumbsProps = {
  path: string;
  className?: string;
};

export default function RSBreadcrumbs(props: RSBreadcrumbsProps) {
  const breadcrumbs = useBreadcrumbs(authenticatedRoutes);
  const { crumbsForPath } = useOverrideBreadcrumbStore();

  const filteredBreadcrumbs = useMemo(() => {
    const includedCrumbs = breadcrumbs.slice(1); //We ignore the root

    //Now we override any paths if they have been set in the breadcrumb store
    return includedCrumbs.map((bc) => {
      let breadcrumb: ReactNode;

      // Replace param values with custom ones were provided
      Object.entries(bc.match.params).forEach(([key, value]) => {
        breadcrumb = crumbsForPath[key] ?? value;
      });

      return breadcrumb ? { ...bc, breadcrumb } : bc;
    });
  }, [breadcrumbs]);

  return (
    <h3 className={`${props.className}`}>
      {filteredBreadcrumbs.map((bc, idx) => {
        return (
          <Link to={bc.match.pathname} key={idx}>
            <span className={`${idx !== filteredBreadcrumbs.length - 1 && 'underline text-gray-500'} pr-1 capitalize`}>
              {bc.breadcrumb}
            </span>
            {/* No / on last entry */}
            {idx !== filteredBreadcrumbs.length - 1 && '/'}
          </Link>
        );
      })}
    </h3>
  );
}
