const MongoIdLength = 24;
const DeviceIdLength = 15;
const UserIdLength = 36;
const MaximumNameLength = 20;
const MaximumLongNameLength = 40;
const MaximumChemicalActiveIngredientLength = 80;
const MaximumDescriptionLength = 250;
const MaximumLongDescriptionLength = 500;

export {
  MongoIdLength,
  DeviceIdLength,
  UserIdLength,
  MaximumNameLength,
  MaximumLongNameLength,
  MaximumDescriptionLength,
  MaximumLongDescriptionLength,
  MaximumChemicalActiveIngredientLength,
};
