import { ApolloError } from '@apollo/client/errors';
import {
  faCode,
  faEdit,
  faEnvelope,
  faLocationDot,
  faPhone,
  faRuler,
  faStickyNote,
  faSuitcase,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FirmwareLockStatusToDisplayName } from '../../../graphql/firmware/FirmwareUtils';
import {
  Device,
  Organisation,
  User,
  UserRole,
  useAdminDisableOrganisationMutation,
  useAdminEnableOrganisationMutation,
  useAdminGetOrganisationQuery,
  useAdminGetUsersQuery,
  useGetDevicesByOrganisationIdQuery,
} from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import RSDropdownMenu, { RSDropdownMenuItem } from '../../../shared/components/RSDropdownMenu';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable from '../../../shared/layout/table/RSPivotTable';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import { enabledToTailwindClassName, enabledToText } from '../../../shared/utilities/EnabledValueUtilities';
import { userRoleToText } from '../../users/utilities/UserRoleUtilities';
import OrganisationFirmwareFormDialogue from '../components/OrganisationFirmwareFormDialog';
import OrganisationFormDialog from '../components/OrganisationFormDialog';
import FirmwareTag from '../../firmware/components/FirmwareTag';
import { UnitSystemToDisplayName } from '../../../shared/utilities/OrganisationUtils';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default function OrganisationPage({}: Props) {
  const [disableOrganisation] = useAdminDisableOrganisationMutation();
  const [enableOrganisation] = useAdminEnableOrganisationMutation();

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowEnableToggle] = useState<boolean>(false);
  const [showFirmware, setShowFirmware] = useState<boolean>(false);

  const { organisationId } = useParams();

  const {
    data: orgData,
    loading: orgLoading,
    error: orgError,
  } = useAdminGetOrganisationQuery({
    variables: {
      id: organisationId as string,
    },
  });

  const {
    data: devData,
    loading: devLoading,
    fetchMore: devFetchMore,
  } = useGetDevicesByOrganisationIdQuery({
    variables: {
      id: organisationId as string,
    },
  });

  const {
    data: usrData,
    loading: usrLoading,
    fetchMore: usrFetchMore,
  } = useAdminGetUsersQuery({
    variables: {
      id: organisationId as string,
    },
  });

  const organisation = orgData?.organisation as Organisation;

  // Select appropriate function for enabling/disabling the organisation
  const toggleFunction = organisation?.enabled ? disableOrganisation : enableOrganisation;

  //On mount (after fetch), set custom breadcrumb so it appears as real name and not jibberish id.
  useSetBreadcrumbs({
    loading: orgLoading,
    entity: organisation,
    params: {
      organisationId: (x) => x.name,
    },
  });

  if (orgLoading) {
    return <BubbleLoader />;
  } else if (orgError) {
    return <QueryError resourceName="organisation" apolloError={orgError as ApolloError} />;
  } else if (organisation === null && !orgLoading) {
    return <NotFoundPage />;
  }

  const rows = [
    {
      name: 'Name',
      value: organisation.name,
      icon: faEdit,
    },
    {
      name: 'Business Number',
      value: organisation.businessNumber,
      icon: faSuitcase,
    },
    {
      name: 'Address',
      value: organisation.address,
      icon: faLocationDot,
    },
    {
      name: 'Contact Name',
      value: organisation.contactName,
      icon: faUser,
    },
    {
      name: 'Phone',
      value: organisation.contactPhone,
      icon: faPhone,
    },
    {
      name: 'Email',
      value: organisation.contactEmail,
      icon: faEnvelope,
    },
    {
      name: 'Description',
      value: organisation.description,
      icon: faStickyNote,
    },
    {
      name: 'Firmware',
      icon: faCode,
      value: organisation.organisationFirmware?.firmware ? (
        <span>
          <FirmwareTag firmware={organisation.organisationFirmware.firmware} /> (
          {FirmwareLockStatusToDisplayName[organisation.organisationFirmware?.lockStatus]})
        </span>
      ) : (
        'Not Set'
      ),
    },
    {
      name: 'Units',
      icon: faRuler,
      value: UnitSystemToDisplayName[organisation.unitSystem],
    },
  ];

  const userColumns = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => `${x.firstName} ${x.lastName}`,
      id: 'name',
    },
    {
      title: 'Role',
      id: 'role',
      accessor: (x) => userRoleToText[x.role],
    },
    {
      title: 'Enabled',
      id: 'enabled',
      accessor: (x) => <RSTag title={enabledToText(x.enabled)} className={enabledToTailwindClassName(x.enabled)} />,
    },
  ] as Array<RSTableColumnDefinition<User>>;

  const deviceColumns = [
    {
      title: 'Id',
      accessor: (x) => x.id,
      id: 'name',
    },
    {
      title: 'Alias',
      id: 'alias',
      accessor: (x) => x.alias,
    },
  ] as Array<RSTableColumnDefinition<Device>>;

  const dropdownActions: RSDropdownMenuItem[] = [
    {
      title: organisation.enabled ? 'Disable' : 'Enable',
      action: () => setShowEnableToggle(true),
      role: UserRole.SuperAdmin,
    },
    {
      title: 'Edit Organisation',
      action: () => setShowEdit(true),
    },
    {
      title: 'Set Firmware',
      action: () => setShowFirmware(true),
      permission: AuthPolicy.FirmwareManagement,
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={<RSDropdownMenu items={dropdownActions} permission={AuthPolicy.OrganisationManagement} />}
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        <GridTile colSpan={3} title={'Devices'}>
          <RSTable<Device>
            isLoading={devLoading}
            columns={deviceColumns}
            data={(devData?.devicesByOrganisationId?.edges?.map((e) => e.node) as Device[]) ?? []}
            onClickPathPrefix={localRoutingConstants.devices.root}
            totalCount={devData?.devicesByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await devFetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (devData?.devicesByOrganisationId?.pageInfo?.endCursor as string) : undefined,
                },
              });
            }}
          />
        </GridTile>
        <GridTile colSpan={3} title={'Users'}>
          <RSTable<User>
            isLoading={usrLoading}
            columns={userColumns}
            data={(usrData?.usersByOrganisationId?.edges?.map((e) => e.node) as User[]) ?? []}
            onClickPathPrefix={localRoutingConstants.users.root}
            totalCount={usrData?.usersByOrganisationId?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await usrFetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (usrData?.usersByOrganisationId?.pageInfo?.endCursor as string) : undefined,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      {/* Add User Modal */}
      <OrganisationFormDialog initialValues={organisation} isOpen={showEdit} onClose={() => setShowEdit(false)} />
      <PermissionGuard policy={AuthPolicy.DeviceManufacturing} isVisible={showFirmware}>
        <OrganisationFirmwareFormDialogue
          organisation={organisation}
          isOpen={showFirmware}
          onClose={() => setShowFirmware(false)}
        />
      </PermissionGuard>
      {/* Confirmation Dialog */}
      <RSConfirmationDialog
        type="danger"
        isOpen={showDelete}
        onClose={() => setShowEnableToggle(false)}
        title={`${organisation.enabled ? 'Disable' : 'Enable'} Organisation?`}
        onConfirm={async () => {
          await toggleFunction({
            variables: {
              input: {
                organisationId: organisation.id,
              },
            },
            onCompleted() {
              setShowEnableToggle(false);
              toast(
                `You successfully ${organisation.enabled ? 'disabled' : 'enabled'} organisation with name "${
                  organisation.name
                }"`,
                {
                  type: 'success',
                },
              );
            },
            onError(error) {
              console.log(JSON.stringify(error));
              toast(error.message, {
                type: 'error',
              });
            },
          });
        }}
      >
        {`Selecting confirm will ${
          organisation.enabled
            ? 'disable the organisation preventing all users within it from logging in'
            : 'enable the organisation allowing all users within it to login'
        }, are you sure you want to proceed?`}
      </RSConfirmationDialog>
    </>
  );
}
