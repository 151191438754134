import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField, useFormikContext } from 'formik';
import { FormEvent, ReactNode, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import FormLabel from '../FormLabel';
import FormErrorText from '../form-error-text/FormErrorText';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  suffix?: ReactNode;
  required?: boolean;
  icon?: IconProp;
  disabled?: boolean;
  value?: string;
  debounceTimeout: number;
  onChangeHandler?: (value: string) => void;
  onTypeHandler?: (value: string) => boolean;
  inputFormatter?: (value: string) => string;
};

export default function FormDebounceInput(props: Props) {
  const [field, meta] = useField(props);
  const [val, setVal] = useState<string>(props.value ? props.value : '');

  const form = useFormikContext();

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    const newVal = e.currentTarget.value;
    // Call your onTypeHandler here for every input change
    if (props.onTypeHandler) {
      if (!props.onTypeHandler(newVal)) {
        //reset the value to the original val
        e.currentTarget.value = val;
        return;
      }

      setVal(newVal);
      form.setFieldTouched(props.name, true);
      form.setFieldValue(props.name, newVal);
    }
  };

  return (
    <div className={`flex flex-col flex-grow`}>
      <FormLabel label={props.label} required={props.required} />
      <div className={`rs-form-icon-container w-full`}>
        {/* Icon */}
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {/* Input */}
        <DebounceInput
          autoComplete="off"
          className={`rs-form-input w-full ${!props.icon && 'pl-4'} ${
            meta.error && meta.touched ? 'border-red-500' : ''
          }`}
          {...field}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disabled}
          required={props.required}
          debounceTimeout={props.debounceTimeout}
          onChange={(e) => {
            form.setFieldTouched(props.name, true);
            form.setFieldValue(props.name, e.target.value);
            if (props.onChangeHandler) {
              props.onChangeHandler(e.target.value);
            }
          }}
          onInput={handleInput}
        />
        {props.suffix && <div className="input-suffix">{props.suffix}</div>}
      </div>
      <FormErrorText meta={meta} />
    </div>
  );
}
