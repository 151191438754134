import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Device,
  DeviceFilterInput,
  DeviceSortInput,
  useGetManufacturingDevicesQuery,
} from '../../../graphql/generated';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import DeviceFormDialog from '../components/DeviceFormDialog';

export default function ManufacturingManagementPage() {
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<DeviceFilterInput>({
    searchableFieldNames: ['alias', 'id'],
  });

  const { data, loading, fetchMore } = useGetManufacturingDevicesQuery({
    variables: {
      where: where,
      order: order,
    },
  });

  const deviceTableCols = [
    {
      title: 'ID',
      id: 'id',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.id,
    },
    {
      title: 'Alias',
      id: 'alias',
      accessor: (x) => x.alias,
    },
    {
      title: 'Registration Date',
      id: 'registrationDate',
      accessor: (x) => new Date(x.registrationDate).toLocaleString(),
    },
  ] as Array<RSTableColumnDefinition<Device>>;

  return (
    <>
      {/* User Table */}
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Unactivated Devices'}
          actions={
            <RSButton variant="primary" onClick={() => setShowAdd(true)}>
              <FontAwesomeIcon icon={faPlus} />
              Add
            </RSButton>
          }
        >
          <SearchSortFilterBar<DeviceSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['alias', 'id', 'registrationDate']}
          />
          <RSTable<Device>
            isLoading={loading}
            columns={deviceTableCols}
            data={(data?.manufacturingDevices?.edges?.map((e) => e.node) as Device[]) ?? []}
            totalCount={data?.manufacturingDevices?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.manufacturingDevices?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      {/* Add Device Modal */}
      <DeviceFormDialog orgLock={undefined} isOpen={showAdd} onClose={() => setShowAdd(false)} />
    </>
  );
}
