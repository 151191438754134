import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useMatch } from 'react-router-dom';

type Props = {
  icon: IconProp;
  title: string;
  path: string;
};

export default function NavigationTab({ title, icon, path }: Props) {
  const isSelected = useMatch({ path, end: false });
  return (
    <Link
      to={path}
      className={`cursor-pointer flex align-middle items-center py-2 mx-1 my-1 rounded ${
        isSelected ? 'bg-primary' : 'bg-white hover:bg-gray-200 transition-all'
      }`}
    >
      <FontAwesomeIcon icon={icon} className={`w-6 text-lg mx-3 ${isSelected ? 'text-white' : 'text-icon-gray'}`} />
      <div className={`align-middle overflow-hidden font-semibold ${isSelected ? 'text-white' : 'text-text-gray'}`}>
        {title}
      </div>
    </Link>
  );
}
