import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import BubbleLoader from '../../layout/loading/BubbleLoader';

interface ThumbnailProps {
  imageUrl: string;
  className?: string;
}

export enum ThumbnailState {
  Loading = 'Loading',
  Loaded = 'Loaded',
  NotFound = 'NotFound',
}

export default function Thumbnail(props: ThumbnailProps) {
  const [imageState, setImageState] = useState<ThumbnailState>(ThumbnailState.Loading);
  return (
    <div
      className={`${props.className}  ${imageState === ThumbnailState.NotFound ? 'rounded-xl flex items-center' : ''}`}
    >
      <img
        alt="Thumbnail not found"
        className={`rounded-xl ${imageState === ThumbnailState.Loaded ? 'block' : 'hidden'}`}
        src={props.imageUrl}
        onLoad={() => setImageState(ThumbnailState.Loaded)}
        onError={() => setImageState(ThumbnailState.NotFound)}
      />
      {imageState === ThumbnailState.Loading && <BubbleLoader className="pt-0 h-full items-center" />}
      <div className={`${imageState === ThumbnailState.NotFound ? 'block' : 'hidden'}`}>
        <div className="flex flex-col items-center rounded-xl">
          <FontAwesomeIcon className="w-full h-full" icon={faImage} />
        </div>
      </div>
    </div>
  );
}
