import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { uniqueId } from 'lodash';
import { CSSProperties, useMemo, useState } from 'react';
import { Location } from '../../../graphql/generated';
import BubbleLoader from '../../layout/loading/BubbleLoader';
import FormLabel from '../forms/FormLabel';
import env from '@beam-australia/react-env';

const mapContainerStyle: CSSProperties = {
  height: '400px',
  width: 'w-full',
};

interface MapWithAutoCompleteProps {
  name: string;
  location?: Location;
}

type Libraries = 'places'[];
const libraries: Libraries = ['places'];
const zoomValue = 15;
export default function MapWithAutoCompleteWrapper(props: MapWithAutoCompleteProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env('GOOGLE_MAP_KEY') ?? '',
    libraries: libraries,
  });

  return isLoaded ? <MapWithAutoComplete {...props} /> : <BubbleLoader />;
}

function MapWithAutoComplete(props: MapWithAutoCompleteProps) {
  const form = useFormikContext();
  const geocoder = useMemo(() => new google.maps.Geocoder(), []);
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const [center, setCenter] = useState<Location>(props.location ?? { latitude: 0, longitude: 0, address: '' });
  const [markerVisible, setMarkerVisible] = useState<boolean>(!!props.location);

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete?.getPlace();
      if (place) {
        updateMapViewAndFormValues({
          latitude: place?.geometry?.location?.lat() ?? 0,
          longitude: place?.geometry?.location?.lng() ?? 0,
          address: place.formatted_address,
        });
      }
    }
  };

  //Helper function to handle updating the map view as well as updating the form
  async function updateMapViewAndFormValues(inputLocation: {
    address?: string | undefined;
    latitude: number;
    longitude: number;
  }) {
    // ensure we have a type-valid address
    const address =
      inputLocation.address ??
      (await geocoder.geocode({ location: { lat: inputLocation.latitude, lng: inputLocation.longitude } })).results[0]
        ?.formatted_address ??
      '';

    // create a location
    const location: Location = {
      latitude: inputLocation.latitude,
      longitude: inputLocation.longitude,
      address,
    };

    // update map
    setCenter(location);
    setMarkerVisible(true);
    map?.setZoom(zoomValue);
    setFieldValue(location);
  }

  //Sets Value for the for the form then sets the text in that input
  function setFieldValue(location: Location) {
    form.setFieldValue(props.name, location);
  }

  const onAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);

    // only set the current location as the location if there wasn't one provided
    if (!props?.location) {
      handleSetCurrentLocation();
    }
  };

  //Listens to when centre changes and then updates for the map
  const latLng = { lat: center.latitude, lng: center.longitude };

  //Uses navigator to get the current position if it has been allows and then sets that on the map
  const handleSetCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      updateMapViewAndFormValues({ latitude, longitude });
    });
    //Sets zoom on initial load
    map?.setZoom(zoomValue);
  };

  return (
    <div>
      <div id="searchbox">
        <FormLabel label="Location" />
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          onLoad={onAutocompleteLoad}
          fields={['geometry.location', 'name', 'formatted_address']}
        >
          <input
            value={center.address}
            onChange={(e) => setCenter({ ...center, address: e.target.value })}
            className="rs-form-input w-full pl-4"
            type="text"
            placeholder="Job location"
          />
        </Autocomplete>
      </div>
      <div>
        <GoogleMap
          mapTypeId="hybrid"
          center={latLng}
          zoom={zoomValue}
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          onClick={(e) => {
            // update the map with the clicked location
            updateMapViewAndFormValues({ latitude: e.latLng?.lat() ?? 0, longitude: e.latLng?.lng() ?? 0 });
          }}
        >
          <Marker
            key={uniqueId()}
            title={`Latitude: ${center.latitude.toFixed(3)}, Longitude: ${center.longitude.toFixed(3)}`}
            position={latLng}
            visible={markerVisible}
          />
        </GoogleMap>
      </div>
    </div>
  );
}
