import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface props {
  className: string;
}

export default function DefaultThumbnail(props: props) {
  return (
    <div className={props.className}>
      <div className={'block'}>
        <div className="flex flex-col items-center rounded-xl">
          <FontAwesomeIcon className="w-full h-full" icon={faImage} />
        </div>
      </div>
    </div>
  );
}
