import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';
import DropDownMenu, { DropDownOption } from '../../input/DropDownMenu';
import FormErrorText from '../form-error-text/FormErrorText';
import FormLabel from '../FormLabel';

type Props = {
  name: string;
  label: string;
  options: Array<DropDownOption<string>>;
  required?: boolean;
  icon?: IconProp;
};

export default function FormSelectInput(props: Props) {
  const [field, meta] = useField(props);
  const formik = useFormikContext();
  const initialSelection = useMemo(() => {
    //Attempt to find the selected value in the list of options
    const initial = props.options.find((option) => option.value == field.value);
    if (initial === undefined) {
      throw new Error('The initial selection must be a value that is available among options !');
    }
    return initial;
  }, [props.options, field.value]);
  return (
    <div className="flex flex-col my-1">
      <FormLabel label={props.label} required={props.required} />
      <DropDownMenu
        icon={props.icon}
        onChange={(value) => {
          field.onChange(value);
          formik.setFieldValue(props.name, value);
        }}
        initialSelection={initialSelection}
        options={props.options}
      />
      <FormErrorText meta={meta} />
    </div>
  );
}
