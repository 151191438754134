import { useState } from 'react';
import { CreateWebhookResponse } from '../../../graphql/generated';
import { CreateWebhookFormDialog } from './CreateWebhookFormDialog';
import { PostWebhookCreationDialog } from './PostCreateWebhookDialog';

export type CreateWebhookDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

enum CreateWebhookDialogStateType {
  Form,
  PostCreation,
}

type CreateWebhookDialogState =
  | { state: CreateWebhookDialogStateType.Form }
  | { state: CreateWebhookDialogStateType.PostCreation; response: CreateWebhookResponse };

export function CreateWebhookDialog({ isOpen, onClose }: CreateWebhookDialogProps) {
  const [dialogState, setDialogState] = useState<CreateWebhookDialogState>({
    state: CreateWebhookDialogStateType.Form,
  });

  switch (dialogState.state) {
    case CreateWebhookDialogStateType.Form:
      return (
        <CreateWebhookFormDialog
          isOpen={isOpen}
          onClose={onClose}
          onComplete={(response) => {
            if (!response) {
              onClose();
            } else {
              setDialogState({ state: CreateWebhookDialogStateType.PostCreation, response });
            }
          }}
        />
      );
    case CreateWebhookDialogStateType.PostCreation:
      return (
        <PostWebhookCreationDialog
          isOpen={isOpen}
          onClose={onClose}
          response={dialogState.response}
          onComplete={onClose}
        />
      );
    default:
      throw new Error(`Unknown State ${dialogState}`);
  }
}
