import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useState } from 'react';
import { OptionalObjectSchema } from 'yup/lib/object';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { toast } from 'react-toastify';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useBlocker from '../../../shared/hooks/UseBlocker';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FileUploader from '../../../shared/components/forms/form-file-uploader/FileUploader';
import UseUploadFile from '../../../shared/hooks/UseFileUpload';
import UploadFileProgressCard from './WeedUploadProgressCard';
import { CreateWeedInput, GetWeedDocument, UpdateWeedInput, Weed } from '../../../graphql/generated';
import {
  MaximumLongDescriptionLength,
  MaximumLongNameLength,
  MaximumNameLength,
} from '../../../shared/constants/ValidationConstants';
import apolloClient from '../../../shared/utilities/ApolloClient';

type Props<T extends FormikValues, TEntity extends Weed> = {
  initialValues?: T;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: () => void;
  onSubmit: (value: T) => Promise<TEntity>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
};

// Abstracted user form allowing for the same structure to be used for editing and creating a user
export default function WeedFormContent<TInput extends FormikValues, TEntity extends Weed>({
  isEdit,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
}: Props<TInput, TEntity>) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  const [upload, setUpload] = useState<File>();
  const [uploadError, setUploadError] = useState<string>('');

  const { uploadProgress, uploadFile } = UseUploadFile({
    onError: (msg) => {
      setUploadError(msg);
    },
    onCompletion: () => {
      toast('The weed has been successfully created!', {
        type: 'success',
      });
    },
  });

  //If upload is in progress, show file upload progress bar
  if (uploadProgress && !uploadProgress.complete) {
    return (
      <RSDialog title={''} isOpen={true}>
        <UploadFileProgressCard
          error={uploadError}
          loaded={uploadProgress.loaded}
          total={uploadProgress.total}
          fileName={upload?.name as string}
        />
      </RSDialog>
    );
  }

  return (
    <Formik<TInput>
      initialValues={initialValues ?? (validator.cast({}) as TInput)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers: FormikHelpers<TInput>) => {
        try {
          let weed: TEntity;

          // Update the update input to specify whether or not the user intends to update the image
          if (isEdit) {
            const updateValues = values as unknown as UpdateWeedInput;
            updateValues.updateImage = upload ? true : false;
            weed = await onSubmit(updateValues as unknown as TInput);
          } else {
            const createValues = values as unknown as CreateWeedInput;
            createValues.updateImage = upload ? true : false;
            weed = await onSubmit(createValues as unknown as TInput);
          }

          if (upload) {
            await uploadFile(upload, weed.imageUrl as string);
            // Refetch the weed if an image has been uploaded to get the image
            await apolloClient.refetchQueries({
              include: [GetWeedDocument],
            });
            helpers.resetForm();
            setUpload(undefined);
            onClose();
          }
        } catch (ex) {
          console.log(ex);
          toast(`An unexpected error occurred whilst trying to ${initialValues ? 'update' : 'create'} the weed`, {
            type: 'error',
          });
        }

        helpers.resetForm();
        setUpload(undefined);
        onClose();
      }}
    >
      {(formik) => {
        const onCloseWrapper = () => {
          formik.resetForm();
          setUpload(undefined);
          onClose();
        };
        return (
          <RSDialog
            title={isEdit ? 'Edit Weed' : 'Add Weed'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={onCloseWrapper}
                />
                <RSButton onClick={formik.submitForm}>{isEdit ? 'Update' : 'Add'}</RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Name"
                name="name"
                placeholder="Name of Weed"
                maxLength={MaximumNameLength}
              />
              <FormTextInput
                required
                label="Species"
                name="species"
                placeholder="Species"
                maxLength={MaximumLongNameLength}
              />
              <FormTextAreaInput
                required
                label="Description"
                name="description"
                placeholder="Write a description for the weed here..."
                maxLength={MaximumLongDescriptionLength}
              />
              <FileUploader
                accept={{
                  'image/png': [],
                  'image/jpeg': [],
                  'image/webp': [],
                  'image/svg+xml': [],
                }}
                name="updateImage"
                label={'Image'}
                onFileSelected={setUpload}
                selectedFile={upload}
                onFileRemove={() => {
                  setUpload(undefined);
                }}
                messageSuffix=" an image (png, jpg, webp, svg)"
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
