import { useContext, useEffect } from 'react';
import { FullScreen } from 'ol/control';
import MapContext from '../MapContext';
import { Options } from 'ol/control/FullScreen';

export default function FullScreenControl(options: Options) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const fullScreenControl = new FullScreen(options);

    map.addControl(fullScreenControl);

    return () => {
      map.removeControl(fullScreenControl);
    };
  }, [map]);

  return null;
}
