import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Organisation,
  User,
  UserFilterInput,
  UserSortInput,
  UserStatus,
  useSuperAdminGetAllUsersQuery,
} from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import OrganisationTag from '../../../shared/components/tags/OrganisationTag';
import { RSTag } from '../../../shared/components/tags/RSTag';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { enabledToTailwindClassName, enabledToText } from '../../../shared/utilities/EnabledValueUtilities';
import UserFormDialog from '../components/UserFormDialog';
import { userRoleToText } from '../utilities/UserRoleUtilities';
import { userStatusToText, userStatusToTailwindClassName } from '../utilities/UserStatusUtilities';

type Props = {};

//TODO: Can we use existing user management page and just override the onSubmit function ?
//TODO: Add filter by role and filter by organisation support ?
export default function UserAdministrationPage({}: Props) {
  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<UserFilterInput>({
    searchableFieldNames: ['email', 'firstName', 'fullName', 'lastName'],
  });

  const { data, loading, fetchMore } = useSuperAdminGetAllUsersQuery({
    variables: {
      where: where,
      order: order,
    },
  });

  const [showInvite, setShowInvite] = useState<boolean>(false);
  const navigate = useNavigate();

  const userTableCols = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => `${x.fullName}`,
      id: 'name',
    },
    {
      title: 'Organisation',
      accessor: (x) => <OrganisationTag organisation={x.organisation as Organisation} navigate={navigate} />,
      id: 'organisation',
    },
    {
      title: 'Email',
      id: 'email',
      accessor: (x) => x.email,
    },
    {
      title: 'Role',
      id: 'role',
      accessor: (x) => userRoleToText[x.role],
    },
    {
      title: 'Enabled',
      id: 'enabled',
      accessor: (x) => <RSTag title={enabledToText(x.enabled)} className={enabledToTailwindClassName(x.enabled)} />,
    },
    {
      title: 'Status',
      id: 'status',
      accessor: (x) => (
        <RSTag
          title={userStatusToText[x.userStatus as UserStatus]}
          className={userStatusToTailwindClassName[x.userStatus as UserStatus]}
        />
      ),
    },
  ] as Array<RSTableColumnDefinition<User>>;

  return (
    <>
      {/* User Table */}
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'Users'}
          actions={
            <RSButton onClick={() => setShowInvite(true)}>
              <FontAwesomeIcon icon={faPlus} />
              Add
            </RSButton>
          }
        >
          <SearchSortFilterBar<UserSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['email', 'firstName', 'lastName', 'role', 'userStatus']}
          />
          <RSTable<User>
            isLoading={loading}
            columns={userTableCols}
            data={(data?.users?.edges?.map((e) => e.node) as User[]) ?? []}
            totalCount={data?.users?.totalCount as number}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.users?.pageInfo?.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      {/* Add User Modal */}
      <UserFormDialog isOpen={showInvite} onClose={() => setShowInvite(false)} isOrganisational={false} />
    </>
  );
}
