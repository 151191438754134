import * as Yup from 'yup';
import { WebhookEventKind, WebhookStatus } from '../../../graphql/generated';
import { MaximumDescriptionLength, MaximumLongNameLength } from '../../../shared/constants/ValidationConstants';

export const webhookFormValidator = Yup.object({
  name: Yup.string().required().min(1).max(MaximumLongNameLength),
  description: Yup.string().nullable().min(0).max(MaximumDescriptionLength),
  url: Yup.string()
    .required()
    .url()
    .test('is-https', 'URL must use the HTTPS protocol', (value) => {
      if (!value) {
        return true;
      }
      try {
        const parsedUrl = new URL(value);
        return parsedUrl.protocol === 'https:';
      } catch (err) {
        return false;
      }
    }),
  status: Yup.mixed<WebhookStatus>().required().oneOf(Object.values(WebhookStatus)),
  events: Yup.array()
    .of(
      Yup.object({
        event: Yup.mixed<WebhookEventKind>().required().oneOf(Object.values(WebhookEventKind)),
        checked: Yup.bool().required(),
      }),
    )
    .required()
    .test('unique', 'Events must be unique', function (list) {
      if (!Array.isArray(list)) return true; // If it's not an array, don't run the test

      const uniqueList = list.map((item) => item.event);
      const isUnique = uniqueList.length === new Set(uniqueList).size;

      return isUnique;
    })
    .test('at least one', 'You must select at least one event', (list) => {
      if (!list) {
        return true;
      }

      for (const value of list) {
        if (value.checked) {
          return true;
        }
      }

      return false;
    }),
});
