import * as Yup from 'yup';
import moment from 'moment';

export const recipeUsageSummaryGraphValidation = Yup.object({
  startDate: Yup.date()
    .required('Start date is required.')
    .max(moment.utc().startOf('day'), 'Start date cannot be in the future.')
    .test('start-end-date', 'Start date cannot exceed end date.', function (value) {
      const { endDate } = this.parent;
      return value && endDate ? moment.utc(value).isSameOrBefore(moment.utc(endDate)) : true;
    })
    .test('date-range', 'The start and end dates cannot be more than a year apart.', function (value) {
      const { endDate } = this.parent;
      return endDate ? moment.utc(endDate).diff(moment.utc(value), 'years') < 1 : true;
    }),
  endDate: Yup.date()
    .required('End date is required.')
    .min(Yup.ref('startDate'), 'End date cannot be before start date.')
    .max(moment.utc().endOf('day'), 'End date cannot be in the future.')
    .test('date-range', 'The start and end dates cannot be more than a year apart.', function (value) {
      const { startDate } = this.parent;
      return startDate ? moment.utc(value).diff(moment.utc(startDate), 'years') < 1 : true;
    }),
});
