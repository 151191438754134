export const menuSlideAnimation = {
  enter: 'transition ease-out duration-200 transform',
  enterFrom: 'transform opacity-0 -translate-x-full',
  enterTo: 'transform opacity-100 translate-x-0',
  leave: 'transition ease-in duration-75',
  leaveFrom: 'transform opacity-100 translate-x-0',
  leaveTo: 'transform opacity-0 -translate-x-full',
};

export const menuPopOutAnimation = {
  enter: 'transition ease-out duration-100',
  enterFrom: 'transform opacity-0 scale-95',
  enterTo: 'transform opacity-100 scale-100',
  leave: 'transition ease-in duration-75',
  leaveFrom: 'transform opacity-100 scale-100',
  leaveTo: 'transform opacity-0 scale-95',
};

export const dialogPopUpAnimation = {
  enter: 'transition duration-100 ease-out',
  enterFrom: 'transform scale-95 opacity-0',
  enterTo: 'transform scale-100 opacity-100',
  leave: 'transition duration-75 ease-out',
  leaveFrom: 'transform scale-100 opacity-100',
  leaveTo: 'transform scale-95 opacity-0',
};

export const popoverAnimation = {
  enter: 'transition ease-out duration-200',
  enterFrom: 'opacity-0 translate-y-1',
  enterTo: 'opacity-100 translate-y-0',
  leave: 'transition ease-in duration-150',
  leaveFrom: 'opacity-100 translate-y-0',
  leaveTo: 'opacity-0 translate-y-1',
};

export const disclosureAnimation = {
  enter: 'transition duration-100 ease-out',
  enterFrom: 'transform scale-95 opacity-0',
  enterTo: 'transform scale-100 opacity-100',
  leave: 'transition duration-75 ease-out',
  leaveFrom: 'transform scale-100 opacity-100',
  leaveTo: 'transform scale-95 opacity-0',
};
