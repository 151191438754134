import convert from 'convert-units';
import { ChemicalType, UnitMeasurement, UnitSystem, UnitType } from '../../../graphql/generated';
import { GetConvertUnit, GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';

export const concentrationUnitsForChemicalType: { [key in ChemicalType]: { [key in UnitSystem]: UnitType } } = {
  [ChemicalType.Liquid]: {
    [UnitSystem.Imperial]: UnitType.FluidOuncesPerUsGallon,
    [UnitSystem.Metric]: UnitType.MillilitresPerHundredLitres,
  },
  [ChemicalType.Solid]: {
    [UnitSystem.Imperial]: UnitType.OuncePerUsGallon,
    [UnitSystem.Metric]: UnitType.GramsPerHundredLitres,
  },
};

export const concentrationUnitUsageForChemicalType: { [key in ChemicalType]: { [key in UnitSystem]: UnitType } } = {
  [ChemicalType.Liquid]: {
    [UnitSystem.Imperial]: UnitType.FluidOunce,
    [UnitSystem.Metric]: UnitType.Millilitre,
  },
  [ChemicalType.Solid]: {
    [UnitSystem.Imperial]: UnitType.Ounce,
    [UnitSystem.Metric]: UnitType.Gram,
  },
};

export function CalculateAmountUsed(volume: UnitMeasurement, concentration: UnitMeasurement) {
  switch (concentration.unit) {
    case UnitType.FluidOuncesPerUsGallon:
    case UnitType.OuncePerUsGallon:
      const gallons = convert(volume.value).from(GetConvertUnit(volume.unit)).to('gal');
      return concentration.value * gallons;
    case UnitType.GramsPerHundredLitres:
    case UnitType.MillilitresPerHundredLitres:
      const perHundredLitres = convert(volume.value).from(GetConvertUnit(volume.unit)).to('l') / 100;
      return perHundredLitres * concentration.value;
  }
}

export function ConcentrationYAxis(unitSystem: UnitSystem) {
  return `          Amount Used (${GetUnitSymbol(
    concentrationUnitUsageForChemicalType[ChemicalType.Liquid][unitSystem],
  )}/${GetUnitSymbol(concentrationUnitUsageForChemicalType[ChemicalType.Solid][unitSystem])})`;
}
