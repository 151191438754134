import { WebhookStatus } from '../../graphql/generated';

type WebhookStatusDefinition = {
  displayName: string;
};

export const WebhookStatusDefinitions: { [key in WebhookStatus]: WebhookStatusDefinition } = {
  [WebhookStatus.Enabled]: {
    displayName: 'Enabled',
  },
  [WebhookStatus.Disabled]: {
    displayName: 'Disabled',
  },
};
