import { ReactNode } from 'react';

type Props = {
  children?: ReactNode[] | ReactNode;
  className?: string;
};

export default function RSGrid({ children, className }: Props) {
  return <div className={`rs-grid${className ? ` ${className}` : ''}`}>{children}</div>;
}
