import { WebhookEventKind } from '../../graphql/generated';

type WebhookEventDefinition = {
  displayName: string;
  category: string;
  version: string;
};

const v1 = '1.0.0';

const Jobs = 'Jobs';

export const WebhookEventDefinitions: { [key in WebhookEventKind]: WebhookEventDefinition } = {
  [WebhookEventKind.JobCompletedV1]: {
    displayName: 'job.completed',
    category: Jobs,
    version: v1,
  },
};
