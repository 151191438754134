import React, { useRef, useState, useEffect, ReactNode } from 'react';
import MapContext from './MapContext';
import * as ol from 'ol';
import 'ol/ol.css';
import { ProjectionLike } from 'ol/proj';
import { MapOptions } from 'ol/Map';
import { ViewOptions } from 'ol/View';
import Interaction from 'ol/interaction/Interaction';
import Control from 'ol/control/Control';
import { UnitSystem } from '../../../../graphql/generated';

export const OriginalProjection: ProjectionLike = 'EPSG:4326';
export const ResultantProjection: ProjectionLike = 'EPSG:3857';

export const UnitSystemToMapCulture: { [key in UnitSystem]: string } = {
  [UnitSystem.Imperial]: 'en-US',
  [UnitSystem.Metric]: 'en-GB',
};

interface MapProps {
  children: ReactNode;
  viewOptions: ViewOptions;
  mapCallback?: (map: ol.Map) => void;
  interactions?: ol.Collection<Interaction>;
  controls?: ol.Collection<Control>;
}

export default function Map({ children, viewOptions, mapCallback, interactions, controls }: MapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<ol.Map>();

  // on component mount
  useEffect(() => {
    const options: MapOptions = {
      view: new ol.View(viewOptions),
      layers: [],
      controls: controls,
      overlays: [],
      interactions: interactions,
    };

    const mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current ?? undefined);
    mapCallback && mapCallback(mapObject);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map || !viewOptions.zoom) return;

    map.getView().animate({ zoom: viewOptions.zoom }, {});
  }, [viewOptions.zoom]);

  // center change handler
  useEffect(() => {
    if (!map || !viewOptions.center) return;

    map.getView().animate({}, { center: viewOptions.center });
  }, [viewOptions.center]);

  return (
    <MapContext.Provider value={map}>
      <div style={{ aspectRatio: '16/9', width: '100%' }} ref={mapRef} className="map-container bg-black">
        {children}
      </div>
    </MapContext.Provider>
  );
}
