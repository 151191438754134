import * as ol from 'ol';
import { useState } from 'react';

export type GenerateMapImageType = {
  mapImage: string | undefined;
  generateMapImage: (map: ol.Map | undefined) => void;
};

export default function useGenerateMapImage(): GenerateMapImageType {
  const [mapImage, setMapImage] = useState<string>();

  const generateMapImage = (map: ol.Map | undefined) => {
    map?.once('rendercomplete', function () {
      const mapCanvas = document.createElement('canvas');
      const size = map.getSize();
      mapCanvas.width = size ? size[0] : 0;
      mapCanvas.height = size ? size[1] : 0;
      const mapContext = mapCanvas.getContext('2d');
      if (mapContext) {
        Array.prototype.forEach.call(
          map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer, .WeedLayer'),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              let matrix;
              const transform = canvas.style.transform;
              if (transform) {
                // Get the transform parameters from the style's transform matrix
                matrix = transform
                  .match(/^matrix\(([^\(]*)\)$/)[1]
                  .split(',')
                  .map(Number);
              } else {
                matrix = [
                  parseFloat(canvas.style.width) / canvas.width,
                  0,
                  0,
                  parseFloat(canvas.style.height) / canvas.height,
                  0,
                  0,
                ];
              }
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
              const backgroundColor = canvas.parentNode.style.backgroundColor;
              if (backgroundColor) {
                mapContext.fillStyle = backgroundColor;
                mapContext.fillRect(0, 0, canvas.width, canvas.height);
              }
              mapContext.drawImage(canvas, 0, 0);
            }
          },
        );
        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);
        setMapImage(mapCanvas.toDataURL());
      }
    });
    map?.renderSync();
  };

  return {
    generateMapImage: generateMapImage,
    mapImage: mapImage,
  };
}
