import * as Yup from 'yup';
import {
  MaximumDescriptionLength,
  MaximumLongNameLength,
  MongoIdLength,
} from '../../../shared/constants/ValidationConstants';
import { FirmwareLockStatus, UnitSystem } from '../../../graphql/generated';

const testBusinessNumber = (val: string | undefined) => {
  if (val) {
    return /^(\d *?){1,30}$/.test(val);
  }
  return true;
};

const testPhone = (val: string | undefined) => {
  if (val) {
    return /^[0-9]*$/.test(val);
  }
  return true;
};

export const organisationValidator = Yup.object({
  name: Yup.string()
    .required()
    .max(MaximumLongNameLength, `name can not exceed ${MaximumLongNameLength} characters`)
    .default(''),
  description: Yup.string()
    .max(MaximumDescriptionLength, `description can not exceed ${MaximumDescriptionLength} characters`)
    .required()
    .default(''),
  businessNumber: Yup.string()
    .test({
      message: 'Please provide a valid business number less than 30 characters',
      test: testBusinessNumber,
    })
    .required()
    .default(''),
  address: Yup.string().required().max(MaximumDescriptionLength).default(''),
  contactName: Yup.string()
    .max(MaximumLongNameLength, `name can not exceed ${MaximumLongNameLength} characters`)
    .required()
    .default(''),
  contactEmail: Yup.string().required().email().default(''),
  contactPhone: Yup.string()
    .required()
    .test({
      message: 'Please provide a valid phone',
      test: testPhone,
    })
    .default(''),
  unitSystem: Yup.mixed<UnitSystem>().oneOf(Object.values(UnitSystem)).default(UnitSystem.Metric),
});

export const organisationUpdateValidator = organisationValidator
  .shape({
    id: Yup.string().required().length(MongoIdLength),
  })
  .omit([]);

export const organisationFirmwareValidator = Yup.object({
  firmwareId: Yup.string().required().length(MongoIdLength),
  lockedStatus: Yup.mixed<FirmwareLockStatus>()
    .oneOf([FirmwareLockStatus.Unlocked, FirmwareLockStatus.Locked])
    .default(FirmwareLockStatus.Unlocked),
});
