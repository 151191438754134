import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Job,
  UnitSystem,
  UnitType,
  UpdateSprayAreaInput,
  useJobUpdateSprayAreaMutation,
} from '../../../graphql/generated';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FormUnitInput from '../../../shared/components/forms/form-number-input/FormUnitInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { UpdateSprayAreaBounds, updateSprayAreaValidator } from '../validation/JobValidator';

type JobUpdateSprayAreaProps = {
  job: Job;
  isOpen: boolean;
  onClose: () => void;
  initialValues?: UpdateSprayAreaInput;
};

const UnitSystemToNozzleWidthUnit: { [key in UnitSystem]: UnitType } = {
  [UnitSystem.Imperial]: UnitType.Feet,
  [UnitSystem.Metric]: UnitType.Metre,
};

export default function JobUpdateSprayAreaDialog({ job, initialValues, onClose, isOpen }: JobUpdateSprayAreaProps) {
  const [updateSprayArea] = useJobUpdateSprayAreaMutation();
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);
  const { unitSystem } = useSelectedOrganisationIdStore();

  const nozzleWidthUnit = UnitSystemToNozzleWidthUnit[unitSystem];

  const onSubmit = async (input: UpdateSprayAreaInput) => {
    try {
      await updateSprayArea({
        variables: {
          input: {
            jobId: job.id,
            nozzleWidth: {
              value: input.nozzleWidth.value,
              unit: input.nozzleWidth.unit,
            },
          },
        },
      });

      toast('Spray area has been successfully updated!', { type: 'success' });
    } catch (error) {
      toast('An error occurred when trying to update the spray area!', { type: 'error' });
      console.error(error, 'An error occurred when updating the spray area');
    } finally {
      onClose();
    }
  };

  const GetNewSprayAreaInput = () => {
    const input: UpdateSprayAreaInput = {
      jobId: job.id,
      nozzleWidth: {
        unit: nozzleWidthUnit,
        value: 1,
      },
    };

    return input;
  };

  return (
    <Formik<UpdateSprayAreaInput>
      initialValues={{
        ...(initialValues ?? GetNewSprayAreaInput()),
      }}
      validationSchema={updateSprayAreaValidator}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <RSDialog
          title={'Update Spray Area'}
          isOpen={isOpen}
          actions={
            <>
              <FormConfirmCloseButton
                isBlocking={isBlocking}
                formik={formik}
                setIsBlocking={setIsBlocking}
                onClose={onClose}
              />
              <RSButton variant="primary" onClick={formik.submitForm}>
                Set
              </RSButton>
            </>
          }
        >
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              setIsBlocking(false);
            }}
            onChange={() => {
              setIsBlocking(true);
            }}
          >
            <FormUnitInput
              name="nozzleWidth"
              label="Nozzle Width"
              defaultUnit={nozzleWidthUnit}
              min={UpdateSprayAreaBounds.nozzleWidth.min}
              targetUnit={UpdateSprayAreaBounds.nozzleWidth.validationUnit}
            />
          </Form>
        </RSDialog>
      )}
    </Formik>
  );
}
