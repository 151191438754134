import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import { FirmwareLockStatus } from '../../../graphql/generated';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { organisationFirmwareValidator } from '../validation/OrganisationValidator';
import FirmwareComboboxInput from './FirmwareComboboxInput';

export type OrganisationFirmwareForm = {
  firmwareId: string;
  firmwareVersion: string;
  lockedStatus: FirmwareLockStatus;
};

type Props = {
  initialValues?: OrganisationFirmwareForm;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: OrganisationFirmwareForm) => Promise<void>;
};

export default function OrganisationFirmwareFormContent({ initialValues, onClose, onSubmit, isOpen }: Props) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  const lockedOptions: DropDownOption<string>[] = [
    {
      value: FirmwareLockStatus.Unlocked,
      displayValue: 'Unlocked',
    },
    {
      value: FirmwareLockStatus.Locked,
      displayValue: 'Locked',
    },
  ];

  return (
    <Formik<OrganisationFirmwareForm>
      initialValues={initialValues ?? (organisationFirmwareValidator.cast({}) as OrganisationFirmwareForm)}
      validationSchema={organisationFirmwareValidator}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <RSDialog
          title="Set Firmware"
          isOpen={isOpen}
          actions={
            <>
              <FormConfirmCloseButton
                isBlocking={isBlocking}
                formik={formik}
                setIsBlocking={setIsBlocking}
                onClose={onClose}
              />
              <RSButton variant="primary" onClick={formik.submitForm}>
                Set
              </RSButton>
            </>
          }
        >
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              setIsBlocking(false);
            }}
            onChange={() => {
              setIsBlocking(true);
            }}
          >
            <div className="grid sm:grid-cols-2 gap-x-4">
              <FirmwareComboboxInput
                initialValue={{
                  value: formik.initialValues.firmwareId,
                  displayValue: formik.initialValues.firmwareVersion,
                }}
                label="Firmware"
                name="firmwareId"
              />
              <FormSelectInput options={lockedOptions} name="lockedStatus" label="Locked" icon={faLock} />
            </div>
          </Form>
        </RSDialog>
      )}
    </Formik>
  );
}
