import DashBoardPage from '../../features/dashboard/pages/DashboardPage';
import MyJobsPage from '../../features/jobs/pages/MyJobsPage';
import ManufacturingManagementPage from '../../features/devices/pages/ManufacturingManagementPage';
import BusinessOverviewPage from '../../features/business-overview/pages/BusinessOverviewPage';
import { UserRole } from '../../graphql/generated';
import SharedJobsPage from '../../features/jobs/pages/SharedJobsPage';

export default function DefaultRoutes(role: UserRole | undefined) {
  switch (role) {
    case UserRole.SuperAdmin:
      return <BusinessOverviewPage />;
    case UserRole.Manufacturer:
      return <ManufacturingManagementPage />;
    case UserRole.OrganisationAdmin:
      return <DashBoardPage />;
    case UserRole.Operator:
      return <MyJobsPage />;
    default:
      return null;
  }
}
