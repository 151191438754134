import { ApolloError } from '@apollo/client';
// import { LocationsDocument } from '../../../graphql/generated';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { useAdminGetBusinessOverviewQuery } from '../../../graphql/generated';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import { Link } from 'react-router-dom';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';

type Props = {};

export default function BusinessOverviewPage({}: Props) {
  const { data: data, loading: loading, error: error } = useAdminGetBusinessOverviewQuery();

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName="business overview" apolloError={error as ApolloError} />;
  }

  return (
    <RSGrid>
      {/* Row One */}
      <NumberTile
        title="Organisations"
        value={data?.organisationCount}
        destination={localRoutingConstants.organisations.root}
      />
      <NumberTile title="Users" value={data?.userCount} destination={localRoutingConstants.manageUsers.root} />
      <NumberTile title="Devices" value={data?.deviceCount} destination={localRoutingConstants.manageDevices.root} />
      {/* <GridTile rowSpan={3} colSpan={3} title="Drop Events"></GridTile> */}

      {/* Row Two */}
      {/* <GridTile height={320} rowSpan={2} colSpan={3} title="Total Spray Activity"></GridTile> */}
    </RSGrid>
  );
}

interface NumberTileProps {
  title: string;
  value?: number;
  link?: string;
  destination: string;
}

function NumberTile({ title, value, destination }: NumberTileProps) {
  return (
    <GridTile title={title}>
      <div className="big-text text-center">{value}</div>
      <div className="underline text-center cursor-pointer">
        <Link to={destination}>View all</Link>
      </div>
    </GridTile>
  );
}
