import { JobStatus } from '../../../graphql/generated';
import { BadgeType } from '../../../shared/components/tags/RSBadge';

export const jobStatusToText: { [key: string]: string } = {
  [JobStatus.Incomplete]: 'Incomplete',
  [JobStatus.Complete]: 'Complete',
  [JobStatus.Finalised]: 'Finalised',
};

export const jobStatusToBadgeType: { [key: string]: BadgeType } = {
  [JobStatus.Incomplete]: 'warning',
  [JobStatus.Complete]: 'success',
  [JobStatus.Finalised]: 'danger',
};
