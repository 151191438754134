import { NavigateFunction } from 'react-router';
import { Job, JobStatus } from '../../../graphql/generated';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';

interface RecipeTagProps {
  job: Job;
  navigate: NavigateFunction;
}

export default function RecipeTag({ job, navigate }: RecipeTagProps) {
  if (job.recipe) {
    return (
      <RSTag
        title={job.recipe.name}
        className="bg-gray-300"
        onClick={() => {
          const suffix = job.status == JobStatus.Incomplete ? '' : `?jobId=${job.id}`;
          return navigate(`${localRoutingConstants.recipes.root}/${job.recipe?.id}${suffix}`);
        }}
      />
    );
  }

  return <>N/A</>;
}
