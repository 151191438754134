import moment from 'moment';
import * as Yup from 'yup';
import { MongoIdLength } from '../../../shared/constants/ValidationConstants';

export const searchValidator = Yup.object({
  weedId: Yup.string().required().length(MongoIdLength),
  coordinates: Yup.object({
    latitude: Yup.number().required().min(-90).max(90),
    longitude: Yup.number().required().min(-180).max(180),
  }),
  radius: Yup.number().min(1).max(100),
  startTime: Yup.date(),
  endTime: Yup.date().when('startTime', (startTime: Date) =>
    Yup.date()
      .min(moment(startTime).add(1, 'minute'), 'End Time must be after Start Time.')
      .max(moment(startTime).add(1, 'year'), 'End Time must be less than a year after Start Time.'),
  ),
});
