import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import { Heatmap } from 'ol/layer';

interface HeatmapLayer {
  key: React.Key;
  heatmap: Heatmap;
}

export default function HeatmapLayer({ heatmap }: HeatmapLayer) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.addLayer(heatmap);

    return () => {
      if (map) {
        map.removeLayer(heatmap);
      }
    };
  }, [map]);

  return null;
}
