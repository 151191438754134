import { ApolloError } from '@apollo/client/errors';
import { faDroplet, faPencil, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Concentration, Job, Recipe, useGetRecipeFromJobQuery, useGetRecipeQuery } from '../../../graphql/generated';
import RSBanner from '../../../shared/components/Banners/RSBanner';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import ConcentrationTags from '../components/ConcentrationTags';
import RecipeDeleteConfirmation from '../components/RecipeDeleteConfirmation';
import RecipeFormDialog from '../components/RecipeFormDialog';
import RecipeUsageGraph from '../components/RecipeUsageGraph';

interface RecipePageProps {
  recipe?: Recipe;
}

export default function RecipePage({}: RecipePageProps) {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const currentOrganisation = useOrganisationId();
  const { recipeId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // For getting recipe summary
  const jobId = searchParams.get('jobId');
  const getLiveRecipe = useMemo(() => {
    return jobId === null ? true : false;
  }, [jobId]);

  const {
    data: jobData,
    loading: jobLoading,
    error: jobError,
  } = useGetRecipeFromJobQuery({
    variables: {
      id: jobId as string,
    },
    skip: getLiveRecipe,
  });

  const {
    data: data,
    loading: loading,
    error: error,
    fetchMore,
  } = useGetRecipeQuery({
    variables: {
      id: recipeId as string,
    },
    skip: !getLiveRecipe,
  });

  const recipe = (jobData?.job?.recipe ?? data?.recipe) as Recipe;

  useSetBreadcrumbs({
    loading: loading || jobLoading,
    entity: recipe,
    params: {
      recipeId: (x) => x.name,
    },
  });

  if (loading || jobLoading) {
    return <BubbleLoader />;
  } else if (error || jobError) {
    return <QueryError resourceName="recipe" apolloError={(error ?? jobError) as ApolloError} />;
  } else if (recipe === null && !(loading || jobLoading)) {
    return <NotFoundPage />;
  }

  const isDeleted = recipe.deleteInformation != undefined ? true : false;

  const rows: RSPivotRowProps[] = [
    {
      name: 'Name',
      value: recipe?.name,
      icon: faPencil,
    },
    {
      name: 'Description',
      value: recipe?.description,
      icon: faStickyNote,
    },
    {
      name: 'Concentrations',
      value: (
        <ConcentrationTags
          concentrations={recipe?.concentrations as Concentration[]}
          navigate={navigate}
          jobId={jobId}
        />
      ),
      notCopyable: true,
      icon: faDroplet,
    },
  ];

  const jobCols: Array<RSTableColumnDefinition<Job>> = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Description',
      accessor: (x) => x.description,
      id: 'description',
    },
    {
      title: 'Start Time',
      accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
      id: 'startTime',
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        {isDeleted && (
          <RSBanner
            type="danger"
            text={`This recipe was deleted ${moment(recipe.deleteInformation?.deletedAt).toLocaleString()}`}
          />
        )}
        {!getLiveRecipe && (
          <RSBanner
            type="alert"
            text={`This is a recipe summary made on ${moment(
              jobData?.job?.completedAt,
            ).toLocaleString()} and may differ from this recipe's current values.`}
          />
        )}
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={getLiveRecipe && !isDeleted}>
              <RSButton variant="danger" onClick={() => setShowDelete(true)}>
                Delete
              </RSButton>
              <RSButton variant="primary" onClick={() => setShowEdit(true)}>
                <FontAwesomeIcon icon={faPencil} />
                Edit
              </RSButton>
            </PermissionGuard>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        {getLiveRecipe && (
          <GridTile colSpan={3} title={'Jobs'}>
            <RSTable
              data={(data?.recipe?.jobs?.edges?.map((e) => e.node) as unknown as Job[]) ?? []}
              totalCount={data?.recipe?.jobs?.totalCount}
              columns={jobCols}
              onClickPathPrefix={`${localRoutingConstants.jobs.root}`}
              fetchMore={async (_, pageSize, increment) => {
                await fetchMore({
                  variables: {
                    first: pageSize,
                    after: increment ? (data?.recipe?.jobs?.pageInfo.endCursor as string) : undefined,
                  },
                });
              }}
            />
          </GridTile>
        )}
        {getLiveRecipe && <RecipeUsageGraph recipe={recipe} />}
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={showEdit}>
        <RecipeFormDialog
          initialValues={recipe}
          isOpen={true}
          onClose={() => setShowEdit(false)}
          currentOrganisation={currentOrganisation}
        />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={showDelete}>
        <RecipeDeleteConfirmation
          currentOrganisation={currentOrganisation}
          recipe={recipe}
          isOpen={true}
          onClose={() => setShowDelete(false)}
        />
      </PermissionGuard>
    </>
  );
}
