import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { DateInputFormat } from '../../utilities/TimeUtilities';
import RSButton from '../input/rs-button/RSButton';
import FormDateInput from './form-date-input/FormDateInput';
import * as Yup from 'yup';

export enum DateOfType {
  week = 'Week Of',
  month = 'Month Of',
  year = 'Year Of',
}

interface DateOfFormProps {
  initialDate: moment.Moment;
  type: DateOfType;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
}

interface FormValues {
  dateOf: Date;
}

export default function DateOfForm({ initialDate, onSubmit, type }: DateOfFormProps) {
  const momentPeriod = type === DateOfType.week ? 'week' : type === DateOfType.month ? 'month' : 'year';
  const validationSchema = Yup.object({
    dateOf: Yup.date().max(moment().endOf(momentPeriod), `Cannot exceed the current ${momentPeriod}.`),
  });

  return (
    <Formik<FormValues>
      initialValues={{
        dateOf: initialDate.toDate(),
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <>
            <FormDateInput
              name={'dateOf'}
              label={type}
              inLineLabel
              notLocal
              max={initialDate.format(DateInputFormat)}
            />
            <RSButton
              variant={formik.isValid ? 'primary' : 'disabled'}
              onClick={async () => {
                await formik.submitForm();
              }}
            >
              Search
            </RSButton>
          </>
        );
      }}
    </Formik>
  );
}
