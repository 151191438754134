import { Dialog, Popover, Transition } from '@headlessui/react';
import { ReactNode } from 'react';

type RSButtonType = 'primary' | 'danger' | 'secondary';

type Props = {
  children?: Array<ReactNode> | ReactNode;
  buttonLabel?: string;
  buttonType?: RSButtonType;
};

export default function RSPopover(props: Props) {
  return (
    <Popover className="relative">
      <>
        <Popover.Button className={`rs-btn-${props.buttonType}`}>{props.buttonLabel}</Popover.Button>
        <Popover.Overlay className="z-10 fixed inset-0 bg-black opacity-30" />
        <Transition>
          <Dialog open={true} onClose={() => {}} className="relative z-50">
            {/* Full-screen scrollable container */}
            <div className="fixed inset-0 overflow-y-scroll">
              {/* Container to center the panel */}
              <div className="flex flex-row min-h-full items-center justify-center">
                {/* The actual dialog panel  */}
                <Dialog.Panel>{props.children}</Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    </Popover>
  );
}
