import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShareStatus, useRespondToOrgShareMutation } from '../../../graphql/generated';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import SpinLoader from '../../../shared/layout/loading/SpinLoader';

export default function JobOrganisationInvitationPage() {
  const [params] = useSearchParams();
  const jobId = params.get('jobId');
  const statusParam = params.get('status');
  const orgId = params.get('orgId');
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  if (!jobId || !statusParam || !orgId) {
    return requestError();
  }

  let status: ShareStatus;
  if (statusParam == 'accept') {
    status = ShareStatus.Accepted;
  } else if (statusParam == 'deny') {
    status = ShareStatus.Denied;
  } else {
    return requestError();
  }

  const [respondToShareMutation] = useRespondToOrgShareMutation();

  useEffect(() => {
    respondToShareMutation({
      variables: {
        jobId: jobId,
        organisationId: orgId,
        status: status,
      },
      onCompleted: (result) => {
        //navigate depending on what they do with the job
        if (status == ShareStatus.Denied) {
          navigate('/');
        } else {
          navigate(`${localRoutingConstants.jobs.root}/${result.respondToOrgShare.id}`);
        }
      },
      onError: (error) => {
        setError(
          error.graphQLErrors.length == 0 ? error.message : (error.graphQLErrors[0].extensions['message'] as string),
        );
      },
    });
  }, []);

  if (error == null) {
    return (
      <div className="w-full flex flex-col items-center">
        <h1>Your request is being processed</h1>
        <SpinLoader />
      </div>
    );
  }

  return <h1 className="text-center">{error}</h1>;
}

function requestError() {
  return <h1 className="text-center">Your request was invalid or malformed!</h1>;
}
