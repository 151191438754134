import { ApolloError } from '@apollo/client/errors';
import { faBuilding, faIdBadge, faPencil, faShapes, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Chemical, Recipe, useGetChemicalFromJobQuery, useGetChemicalQuery } from '../../../graphql/generated';
import RSBanner from '../../../shared/components/Banners/RSBanner';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import ChemicalDeleteConfirmation from '../components/ChemicalDeleteConfirmation';
import ChemicalFormDialog from '../components/ChemicalFormDialog';
import ChemicalUsageGraph from '../components/ChemicalUsageGraph';
import { chemicalTypeToText } from '../utilities/ChemicalTypeUtilities';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default function ChemicalPage({}: Props) {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const currentOrganisation = useOrganisationId();
  const { chemicalId } = useParams();

  // For getting chemical summary
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const getLiveChemical = useMemo(() => {
    return jobId === null ? true : false;
  }, [jobId]);

  const {
    data: jobData,
    loading: jobLoading,
    error: jobError,
  } = useGetChemicalFromJobQuery({
    variables: {
      id: jobId as string,
    },
    skip: getLiveChemical,
  });

  const {
    data: data,
    loading: loading,
    error: error,
    fetchMore,
  } = useGetChemicalQuery({
    variables: {
      id: chemicalId as string,
    },
    skip: !getLiveChemical,
  });

  const chemicalFromJob = useMemo(
    () => jobData?.job?.recipe?.concentrations?.map((e) => e.chemical)?.find((e) => e.id == chemicalId),
    [jobData],
  );
  const chemical = (chemicalFromJob ?? data?.chemical) as Chemical;

  useSetBreadcrumbs({
    loading: loading || jobLoading,
    entity: chemical,
    params: {
      chemicalId: (x) => x.name,
    },
  });

  if (loading || jobLoading) {
    return <BubbleLoader />;
  } else if (error || jobError) {
    return <QueryError resourceName="chemical" apolloError={(error ?? jobError) as ApolloError} />;
  } else if (chemical === null && !(loading || jobLoading)) {
    return <NotFoundPage />;
  }

  const isDeleted = chemical.deleteInformation != undefined ? true : false;

  const rows: RSPivotRowProps[] = [
    {
      name: 'Name',
      value: chemical.name,
      icon: faPencil,
    },
    {
      name: 'Active Ingredient',
      value: chemical.activeIngredient,
      icon: faIdBadge,
    },
    {
      name: 'Type',
      value: chemicalTypeToText[chemical.type],
      icon: faShapes,
    },
    {
      name: 'Manufacturer',
      value: chemical.manufacturer,
      icon: faBuilding,
    },
    {
      name: 'Description',
      value: chemical.description,
      icon: faStickyNote,
    },
  ];

  const recipeCols: Array<RSTableColumnDefinition<Recipe>> = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Description',
      accessor: (x) => x.description,
      id: 'description',
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        {isDeleted && (
          <RSBanner
            type="danger"
            text={`This chemical was deleted ${moment(chemical.deleteInformation?.deletedAt).toLocaleString()}`}
          />
        )}
        {!getLiveChemical && (
          <RSBanner
            type="alert"
            text={`This is a chemical summary made on ${moment(
              jobData?.job?.completedAt,
            ).toLocaleString()} and may differ from this chemical's current values.`}
          />
        )}
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={getLiveChemical && !isDeleted}>
              <RSButton variant="danger" onClick={() => setShowDelete(true)}>
                Delete
              </RSButton>
              <RSButton variant="primary" onClick={() => setShowEdit(true)}>
                <FontAwesomeIcon icon={faPencil} />
                Edit
              </RSButton>
            </PermissionGuard>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        {getLiveChemical && (
          <GridTile colSpan={3} title={'Recipes'}>
            <RSTable
              data={(data?.chemical?.recipes?.edges?.map((e) => e.node) as unknown as Recipe[]) ?? []}
              totalCount={data?.chemical?.recipes?.totalCount}
              columns={recipeCols}
              onClickPathPrefix={`${localRoutingConstants.recipes.root}`}
              fetchMore={async (_, pageSize, increment) => {
                await fetchMore({
                  variables: {
                    first: pageSize,
                    after: increment ? (data?.chemical?.recipes?.pageInfo.endCursor as string) : undefined,
                  },
                });
              }}
            />
          </GridTile>
        )}
        {getLiveChemical && <ChemicalUsageGraph chemical={chemical} />}
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={showEdit}>
        <ChemicalFormDialog
          initialValues={chemical}
          isOpen={true}
          onClose={() => setShowEdit(false)}
          currentOrganisation={currentOrganisation}
        />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.RecipeManagement} isVisible={showDelete}>
        <ChemicalDeleteConfirmation
          currentOrganisation={currentOrganisation}
          chemical={chemical}
          isOpen={true}
          onClose={() => setShowDelete(false)}
        />
      </PermissionGuard>
    </>
  );
}
