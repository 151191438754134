import { toast } from 'react-toastify';
import { GetRecipesByOrganisationIdDocument, Recipe, useDeleteRecipeMutation } from '../../../graphql/generated';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';

interface RecipeDeleteConfirmationProps {
  currentOrganisation: string;
  recipe: Recipe;
  isOpen: boolean;
  onClose: () => unknown;
}

export default function RecipeDeleteConfirmation({
  currentOrganisation,
  recipe,
  isOpen,
  onClose,
}: RecipeDeleteConfirmationProps) {
  const [deleteRecipe] = useDeleteRecipeMutation();

  return (
    <RSConfirmationDialog
      type="danger"
      isOpen={isOpen}
      onClose={onClose}
      title={'Delete Recipe'}
      onConfirm={async () => {
        await deleteRecipe({
          variables: {
            id: recipe.id,
          },
          onCompleted() {
            onClose();
            toast(`You have successfully deleted the recipe '${recipe.name}'.`, {
              type: 'success',
            });
          },
          refetchQueries: [{ query: GetRecipesByOrganisationIdDocument, variables: { id: currentOrganisation } }],
          onError(error) {
            toast(error.message, {
              type: 'error',
            });
          },
        });
      }}
    >
      {'Selecting confirm will delete this recipe, stopping it from being edited and being used for new jobs.'}
    </RSConfirmationDialog>
  );
}
