import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import OLTileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import { Options } from 'ol/layer/BaseTile';

export default function TileLayer(options: Options<TileSource>) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const tileLayer = new OLTileLayer(options);

    map.addLayer(tileLayer);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map]);

  return null;
}
