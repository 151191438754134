import {
  faEnvelope,
  faLocationDot,
  faPencil,
  faPhone,
  faRuler,
  faSuitcase,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { OptionalObjectSchema } from 'yup/lib/object';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { toast } from 'react-toastify';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useBlocker from '../../../shared/hooks/UseBlocker';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import { MaximumDescriptionLength, MaximumLongNameLength } from '../../../shared/constants/ValidationConstants';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';
import { UnitSystem } from '../../../graphql/generated';
import { UnitSystemToDisplayName } from '../../../shared/utilities/OrganisationUtils';

type Props<T extends FormikValues> = {
  initialValues?: T;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: () => void;
  onSubmit: (values: T) => Promise<void>; // Means to provide custom onSubmit functionality
};

// Abstracted user form allowing for the same structure to be used for editing and creating a user
export default function OrganisationFormContent<T extends FormikValues>({
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
}: Props<T>) {
  const isEdit: boolean = initialValues ? true : false;
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  const unitSystemOptions: DropDownOption<string>[] = Object.values(UnitSystem).map((value) => {
    return {
      displayValue: UnitSystemToDisplayName[value as UnitSystem],
      value: value,
    };
  });

  return (
    <Formik<T>
      initialValues={initialValues ?? (validator.cast({}) as T)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers: FormikHelpers<T>) => {
        try {
          await onSubmit(values);
          helpers.resetForm();
        } catch (ex) {
          console.log(ex);
          toast(
            `An unexpected error occurred whilst trying to ${initialValues ? 'update' : 'create'} the organisation`,
            {
              type: 'error',
            },
          );
        }
        onClose();
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title={isEdit ? 'Edit Organisation' : 'Add Organisation'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={() => {
                    formik.resetForm();
                    onClose();
                  }}
                />
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  {isEdit ? 'Update' : 'Add'}
                </RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Name"
                name="name"
                icon={faPencil}
                placeholder="Name of organisation"
                maxLength={MaximumLongNameLength}
              />
              <FormTextAreaInput
                required
                label="Description"
                name="description"
                placeholder="Write a description for the organisation here..."
                maxLength={MaximumDescriptionLength}
              />
              <FormTextInput
                required
                label="Business Number"
                name="businessNumber"
                icon={faSuitcase}
                placeholder="BusinessNumber"
                maxLength={30}
                minLength={1}
              />
              <FormTextInput
                required
                label="Address"
                name="address"
                icon={faLocationDot}
                placeholder="Address"
                maxLength={MaximumDescriptionLength}
              />
              <FormTextInput
                required
                label="Contact Name"
                name="contactName"
                icon={faUser}
                placeholder="Full name of contact"
                maxLength={MaximumLongNameLength}
              />
              <FormTextInput
                required
                label="Contact Email"
                name="contactEmail"
                icon={faEnvelope}
                placeholder="Email of contact"
              />
              <FormTextInput
                required
                label="Contact Phone"
                name="contactPhone"
                icon={faPhone}
                placeholder="Phone of contact"
                maxLength={10}
              />
              <FormSelectInput required label="Units" name="unitSystem" icon={faRuler} options={unitSystemOptions} />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
