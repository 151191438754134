import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useMemo } from 'react';
import { Job, UnitType } from '../../../graphql/generated';
import { GetFullUnitDisplay } from '../../../shared/utilities/UnitConversionUtilities';

interface OperatorComparisonProps {
  job: Job;
}

interface Data {
  value: number;
  name: string;
  percent?: number;
}

export default function OperatorComparison({ job }: OperatorComparisonProps) {
  let unit = UnitType.Litre;
  const [data, hasData] = useMemo(() => {
    let hasData = false;

    const mappedData: { [k: string]: number } = {};

    job.deviceAssignments.forEach((deviceAssignment) => {
      let sprayed: number;

      // Shortcut the calculation if the job is completed
      if (deviceAssignment.volumeDispensed) {
        sprayed = deviceAssignment.volumeDispensed.value;
        unit = deviceAssignment.volumeDispensed.unit;
      } else {
        sprayed = deviceAssignment.sprayLogs.reduce<number>((prev, current) => {
          unit = current.v.unit;
          return (prev += current.v.value);
        }, 0);
      }

      if (sprayed) {
        hasData = true;
      }

      // Check to see if the user has already been accounted for and update/create as appropriate
      if (mappedData[deviceAssignment.user.fullName]) {
        mappedData[deviceAssignment.user.fullName] += sprayed;
      } else {
        mappedData[deviceAssignment.user.fullName] = sprayed;
      }
    });

    const data = Object.keys(mappedData).map((x) => ({ name: x, value: mappedData[x] }));
    return [data, hasData];
  }, [job]);

  const options = useMemo(() => {
    return {
      tooltip: {
        trigger: 'item',
        valueFormatter: (value: number) => `${GetFullUnitDisplay({ unit: unit, value: value }, 2)}`,
      },
      legend: {},
      series: [
        {
          name: 'Volume sprayed',
          type: 'pie',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            formatter: (item: Data) =>
              `${item.name}: ${GetFullUnitDisplay({ unit: unit, value: item.value }, 2)} (${item.percent}%)`,
          },
        },
      ],
    } as EChartsOption;
  }, [data]);

  return hasData ? (
    <ReactECharts className="pt-6" option={options} />
  ) : (
    <div className="flex flex-row justify-center h-full w-full p-8">
      Operator Comparison unavailable - no spray data found.
    </div>
  );
}
