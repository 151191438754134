import logoWhite from '../../../assets/images/rapid-spray-logo-small-white-no-bg.png';
import { Image } from '@aws-amplify/ui-react';

export default function RSBrand() {
  return (
    <div className="hidden md:flex md:flex-col min-h-screen col-span-1 justify-center bg-primary">
      <Image alt="Rapid Logix logo" src={logoWhite} className="h-80 place-self-center row-span-2" />
    </div>
  );
}
