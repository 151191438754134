import { WebhookInfrastructureStatus } from '../../graphql/generated';
import { BadgeType } from '../../shared/components/tags/RSBadge';
type WebhookInfrastructureStatusDefinition = {
  displayName: string;
  badgeVariant: BadgeType;
};

export const WebhookInfrastructureStatusDefinitions: {
  [key in WebhookInfrastructureStatus]: WebhookInfrastructureStatusDefinition;
} = {
  [WebhookInfrastructureStatus.DeploymentFailed]: {
    displayName: 'Failure',
    badgeVariant: 'danger',
  },
  [WebhookInfrastructureStatus.DeploymentComplete]: {
    displayName: 'Operational',
    badgeVariant: 'success',
  },
  [WebhookInfrastructureStatus.DeploymentInProgress]: {
    displayName: 'Deploying',
    badgeVariant: 'info',
  },
};
