import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import RSButton from '../input/rs-button/RSButton';
import FormDateInput from './form-date-input/FormDateInput';

export interface DatesRangeFormValues {
  startDate: Date;
  endDate: Date;
}

interface DateRangePickerProps {
  initialStartDate: moment.Moment;
  initialEndDate: moment.Moment;
  onSubmit: (values: DatesRangeFormValues, formikHelpers: FormikHelpers<DatesRangeFormValues>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: any;
}

export default function DateRangePicker({
  initialStartDate,
  initialEndDate,
  onSubmit,
  validator: validationSchema,
}: DateRangePickerProps) {
  return (
    <Formik<DatesRangeFormValues>
      initialValues={{
        startDate: initialStartDate.toDate(),
        endDate: initialEndDate.toDate(),
      }}
      onSubmit={(values, formikHelpers) => {
        const utcValues = {
          startDate: values.startDate,
          endDate: values.endDate,
        };
        onSubmit(utcValues, formikHelpers);
      }}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <>
          <FormDateInput
            name={'startDate'}
            label={'Start Date'}
            inLineLabel
            notLocal
            max={moment(formik.values.endDate).format('YYYY-MM-DD')}
          />
          <FormDateInput
            name={'endDate'}
            label={'End Date'}
            inLineLabel
            notLocal
            min={moment(formik.values.startDate).format('YYYY-MM-DD')}
            max={moment(initialEndDate).format('YYYY-MM-DD')}
          />
          <RSButton
            variant={formik.isValid ? 'primary' : 'disabled'}
            onClick={async () => {
              await formik.submitForm();
            }}
          >
            Search
          </RSButton>
        </>
      )}
    </Formik>
  );
}
