import { ApolloError } from '@apollo/client';
import {
  faAt,
  faClock,
  faClockRotateLeft,
  faEllipsisVertical,
  faSignal,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FirmwareStatusToDisplayName } from '../../../graphql/firmware/FirmwareUtils';
import {
  Firmware,
  FirmwareStatus,
  useAdminGetAllAvailableFirmwareQuery,
  useAdminGetFirmwareByIdQuery,
  useDisableFirmwareMutation,
} from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import SimpleDropdown from '../../../shared/components/SimpleDropdown';
import SimpleDropdownItem from '../../../shared/components/SimpleDropdownItem';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable, { RSPivotRowProps } from '../../../shared/layout/table/RSPivotTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';

export default function FirmwarePage() {
  const [disableFirmware] = useDisableFirmwareMutation();
  const [showDisable, setShowDisable] = useState<boolean>(false);
  const { firmwareId } = useParams();

  const {
    data: data,
    loading: loading,
    error: error,
  } = useAdminGetFirmwareByIdQuery({
    variables: {
      input: {
        id: firmwareId as string,
      },
    },
  });

  const { refetch } = useAdminGetAllAvailableFirmwareQuery();

  const firmware = data?.firmwareById as Firmware;

  useSetBreadcrumbs({
    loading: loading,
    entity: firmware,
    params: {
      firmwareId: (x) => x.version,
    },
  });

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName="firmware" apolloError={error as ApolloError} />;
  } else if (firmware === null && !loading) {
    return <NotFoundPage />;
  }

  const rows: RSPivotRowProps[] = [
    {
      name: 'Version',
      value: firmware.version,
      icon: faClockRotateLeft,
    },
    {
      name: 'Status',
      value: FirmwareStatusToDisplayName[firmware.status],
      icon: faSignal,
    },
    {
      name: 'Description',
      value: firmware.description,
      icon: faStickyNote,
    },
    {
      name: 'Created At',
      value: firmware.createInformation.createdAt
        ? moment(firmware.createInformation.createdAt).toLocaleString()
        : 'N/A',
      icon: faClock,
    },
    {
      name: 'Created By',
      value: firmware.user.fullName,
      icon: faAt,
    },
  ];

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <>
              {firmware.status != FirmwareStatus.Deprecated ? (
                <PermissionGuard policy={AuthPolicy.FirmwareManagement}>
                  <SimpleDropdown
                    align="right"
                    trigger={
                      <div className="cursor-pointer h-full w-7 text-center">
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </div>
                    }
                  >
                    <SimpleDropdownItem onClick={() => setShowDisable(true)}>Disable</SimpleDropdownItem>
                  </SimpleDropdown>
                </PermissionGuard>
              ) : (
                ''
              )}
            </>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
      </RSGrid>
      <RSConfirmationDialog
        type="danger"
        isOpen={showDisable}
        onClose={() => setShowDisable(false)}
        title="Disable Firmware?"
        onConfirm={async () => {
          await disableFirmware({
            variables: {
              id: firmware.id,
            },
            onCompleted() {
              setShowDisable(false);
              toast(`You successfully disabled firmware version "${firmware.version}"`, {
                type: 'success',
              });

              //refetch enabled firmware so bad entries don't show in the cache anymore.
              refetch();
            },
            onError(error) {
              console.log(JSON.stringify(error));
              toast(error.message, {
                type: 'error',
              });
            },
          });
        }}
      >
        {
          'Selecting confirm will disable the firmware preventing the use of this firmware in further devices, are you sure you want to proceed?'
        }
      </RSConfirmationDialog>
    </>
  );
}
