import { ReactNode } from 'react';

interface GridTileProps {
  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  colSpan?: number;
  rowSpan?: number;
  rowStart?: number;
  rowEnd?: number;
  height?: number;
  className?: string;
}

export default function GridTile({
  title,
  children,
  colSpan = 1,
  rowSpan = 1,
  rowStart,
  rowEnd,
  height,
  actions,
  className,
}: GridTileProps) {
  return (
    <div
      style={{
        minHeight: height,
        gridRow: `span ${rowSpan}`,
        gridColumn: `span ${colSpan}`,
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
      }}
      className={`rs-grid-tile${className ? ` ${className}` : ''}`}
    >
      {(title || actions) && (
        <div className="flex flex-row flex-wrap justify-between border-border-gray border-b text-left p-2 items-center">
          {title && <h3 className="flex flex-row gap-x-2">{title}</h3>}
          {actions && <div className="flex flex-row gap-x-2 gap-y-2 flex-wrap items-center">{actions}</div>}
        </div>
      )}
      <div className="h-100">{children}</div>
    </div>
  );
}
