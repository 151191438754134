import moment from 'moment';
import { Job, SortEnumType, useJobsByUserIdQuery } from '../../../graphql/generated';
import RSBadge from '../../../shared/components/tags/RSBadge';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { jobStatusToBadgeType, jobStatusToText } from '../utilities/JobStatusUtilities';

export default function MyJobsPage() {
  const { data, loading, fetchMore } = useJobsByUserIdQuery({
    variables: {
      order: [
        {
          endTime: SortEnumType.Desc,
        },
      ],
    },
  });

  const cols = [
    {
      title: 'Name',
      id: 'name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      accessor: (x) => x.name,
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
    {
      title: 'Status',
      id: 'status',
      accessor: (x) => <RSBadge title={jobStatusToText[x.status]} type={jobStatusToBadgeType[x.status]} />,
    },
    {
      title: 'Start Time',
      id: 'startTime',
      accessor: (x) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return x.startTime ? moment(x.startTime).toLocaleString() : 'N/A';
      },
    },
    {
      title: 'End Time',
      id: 'endTime',
      accessor: (x) => (x.endTime ? moment(x.endTime).toLocaleString() : 'N/A'),
    },
  ] as Array<RSTableColumnDefinition<Job>>;

  return (
    <RSGrid>
      <GridTile colSpan={6}>
        <RSTable<Job>
          isLoading={loading}
          data={(data?.myJobs?.edges?.map((e) => e.node) as unknown as Job[]) ?? []}
          totalCount={data?.myJobs?.totalCount}
          columns={cols}
          onClickPathPrefix={`${localRoutingConstants.myJobs.root}`}
          fetchMore={async (_, pageSize, increment) => {
            await fetchMore({
              variables: {
                first: pageSize,
                after: increment ? (data?.myJobs?.pageInfo.endCursor as string) : undefined,
              },
            });
          }}
        />
      </GridTile>
    </RSGrid>
  );
}
