import { RowModel } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Device, DeviceAssignment, Job, DeviceUnassignedSprayLogSummary, Scalars } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import ExistingJobSearch from './ExistingJobSearch';

interface AddLogsToJobDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: RowModel<DeviceUnassignedSprayLogSummary> | undefined;
  setJob: (job: Partial<Job>) => void;
}

export default function AddLogsToJobDialog(props: AddLogsToJobDialogProps) {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<Job>();

  const [earliestLog, latestLog, devices] = useMemo(() => {
    const firstRow = props.selectedRows?.rows[0]?.original;
    let earliestLog = firstRow?.startTime;
    let latestLog = firstRow?.endTime;
    const devices: Device[] = [firstRow?.device as Device];
    props.selectedRows?.rows.slice(1).forEach((row) => {
      const item = row.original;
      earliestLog = earliestLog < item.startTime ? earliestLog : item.startTime;
      latestLog = latestLog > item.endTime ? latestLog : item.endTime;
      devices.push(item.device);
    });
    // Add an additional minute to encompass the logs for the minute because these logs are aggregated per minute
    latestLog = moment(latestLog).add(1, 'minute').toISOString();
    return [earliestLog, latestLog, devices];
  }, [props.selectedRows]);

  const onCloseWrapper = () => {
    setIsSearching(false);
    setSelectedJob(undefined);
    props.onClose();
  };

  return (
    <RSDialog
      title="Add to Job"
      isOpen={props.isOpen}
      actions={
        <>
          {!isSearching ? (
            <>
              <RSButton variant="secondary" onClick={onCloseWrapper}>
                Close
              </RSButton>
              <RSButton
                variant="primary"
                onClick={() => {
                  props.setJob({
                    startTime: earliestLog,
                    endTime: latestLog,
                    deviceAssignments: devices.map((e) => {
                      return {
                        device: e,
                        user: {},
                      };
                    }) as Array<DeviceAssignment>,
                  });
                  onCloseWrapper;
                }}
              >
                New Job
              </RSButton>
              <RSButton variant="primary" onClick={() => setIsSearching(true)}>
                Existing Job
              </RSButton>
            </>
          ) : (
            <>
              <RSButton variant="secondary" onClick={() => setIsSearching(false)}>
                Cancel
              </RSButton>
              <RSButton
                variant={selectedJob ? 'primary' : 'disabled'}
                onClick={() => {
                  AddToExistingJobHandler(selectedJob, earliestLog, latestLog, devices, props);
                  onCloseWrapper;
                }}
              >
                Add to Job
              </RSButton>
            </>
          )}
        </>
      }
    >
      {props.selectedRows && (
        <>
          <p>
            You have selected logs between <span className="font-bold">{moment(earliestLog).toLocaleString()}</span> and
            <span className="font-bold">{` ${moment(latestLog).toLocaleString()}`}</span> for the following devices:
          </p>
          <ul className="mx-5 list-disc">
            {devices.map((e, index) => (
              <li key={`device.${index}`}>{e.alias}</li>
            ))}
          </ul>
        </>
      )}
      <br />
      {!isSearching ? (
        <p>Do you wish to add these to a new or existing job?</p>
      ) : (
        <ExistingJobSearch setSelectedJob={setSelectedJob} />
      )}
    </RSDialog>
  );
}
function AddToExistingJobHandler(
  selectedJob: Job | undefined,
  earliestLog: Scalars['DateTime'],
  latestLog: Scalars['DateTime'],
  devices: Device[],
  props: AddLogsToJobDialogProps,
) {
  const job = selectedJob as Job;

  // Update the Job start and end times to accommodate the selected logs
  let startTime: Date | undefined;
  let endTime: Date | undefined;

  if (!job.startTime || job.startTime > earliestLog) {
    startTime = earliestLog;
  } else {
    startTime = job.startTime;
  }
  if (!job.endTime || job.endTime < latestLog) {
    endTime = latestLog;
  } else {
    endTime = job.endTime;
  }

  // Construct the new job info
  const newJob: Job = {
    ...job,
    startTime: startTime,
    endTime: endTime,
    deviceAssignments: job.deviceAssignments.concat(
      devices.map((d) => {
        return {
          device: d,
          user: {}, // Users will still have to set user information so leave it blank
        };
      }) as Array<DeviceAssignment>,
    ),
  };

  props.setJob(newJob as Job);
}
