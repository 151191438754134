import { MouseEventHandler } from 'react';
import { RSTag } from './RSTag';

export type BadgeType = 'warning' | 'danger' | 'success' | 'info';

type BadgeProps = {
  title: string;
  type: BadgeType;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  key?: React.Key;
  tooltip?: string;
};

export default function RSBadge(props: BadgeProps) {
  return (
    <RSTag
      title={props.title}
      className={typeToClassName(props.type)}
      onClick={props.onClick}
      key={props.key}
      tooltip={props.tooltip}
    />
  );
}

function typeToClassName(type: BadgeType): string {
  switch (type) {
    case 'success':
      return 'bg-green-400 text-green-800';
    case 'danger':
      return 'bg-red-400 text-red-800';
    case 'warning':
      return 'bg-orange-400 text-orange-800';
    case 'info':
      return 'bg-blue-400 text-blue-800';
  }
}

/**
 *   [JobStatus.Incomplete]: 'bg-orange-400 text-orange-800',
  [JobStatus.Complete]: 'bg-green-400 text-green-800',
  [JobStatus.Finalised]: 'bg-red-400 text-red-800',
 */
