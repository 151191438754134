import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AnyObject } from 'yup/lib/types';
import { FetchResult } from '@apollo/client';
import { CreateDeviceMutation, Device } from '../../../graphql/generated';
import DevicePostRegistrationContent from './DevicePostRegistrationContent';
import DeviceRegistrationFormContent from './DeviceRegistrationFormContent';
import { getErrorType, ServerErrorCode } from '../../../shared/utilities/ApolloErrorUtils';

type Props<T extends FormikValues> = {
  initialValues?: T;
  isOpen: boolean;
  validator: AnyObject;
  onClose: () => void;
  onSubmit: (
    values: T,
  ) => void | Promise<any | FetchResult<CreateDeviceMutation, Record<string, any>, Record<string, any>>>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
  isSuperAdmin: boolean;
  orgLock: string | undefined;
};

interface NewDeviceCredentials {
  activationToken?: string;
  authenticationToken?: string;
  imei?: string;
  alias?: string;
}

export default function DeviceFormContent<T extends FormikValues>({
  isEdit,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
  isSuperAdmin,
  orgLock,
}: Props<T>) {
  const [modalSwitch, setModalSwitch] = useState<boolean>(false);
  const [newDeviceCredentials, setNewDeviceCredentials] = useState<NewDeviceCredentials>();
  const formRef = useRef(null);

  return (
    <Formik<T>
      initialValues={initialValues ?? (validator.cast({}) as T)}
      validationSchema={validator}
      enableReinitialize
      innerRef={formRef}
      onSubmit={async (values, helpers: FormikHelpers<T>) => {
        const result = await onSubmit(values);
        const newDevice = result?.data?.createDevice;
        try {
          // if (newDevice) {
          //If create device payload is returned, we set the new device details.
          setNewDeviceCredentials({
            activationToken: newDevice?.activationToken,
            authenticationToken: newDevice?.authenticationToken,
            imei: newDevice?.id,
            alias: newDevice?.alias,
          });
          // }
          // await onSubmit(values);
        } catch (ex) {
          console.log(ex);
          if (getErrorType(ex) === ServerErrorCode.RESOURCE_ALREADY_EXISTS) {
            toast(
              `The request could not be fulfilled because a device with the requested IMEI (${values.imei}) already exists!`,
              {
                type: 'error',
              },
            );
          } else {
            toast(
              `An unexpected error occurred whilst trying to ${
                initialValues ? 'update' : 'create'
              } the device, please wait two minutes and try again or contact Rapid Spray support.`,
              {
                type: 'error',
              },
            );
          }
        }
        if (!isEdit) {
          setModalSwitch(true);
        }
      }}
    >
      {(formik) => {
        // Reduces duplicate code and ensures that the close button and clicking away to dismiss a form does the same thing
        const onCloseWrapper = () => {
          formik.resetForm();
          onClose();
        };
        if (!modalSwitch) {
          return (
            <DeviceRegistrationFormContent
              isEdit={isEdit}
              onClose={onCloseWrapper}
              formik={formik}
              isOpen={isOpen}
              isSuperAdmin={isSuperAdmin}
              orgLock={orgLock}
            />
          );
        } else {
          return (
            <DevicePostRegistrationContent
              isOpen={isOpen}
              onClose={onCloseWrapper}
              newDeviceCredentials={newDeviceCredentials}
              setModalSwitch={setModalSwitch}
            />
          );
        }
      }}
    </Formik>
  );
}
