import { useMemo } from 'react';
import HeatmapLayer from '../../../../shared/components/maps/Map/Layers/HeatmapLayer';
import { DeviceAssignmentLayerPair } from './JobMap';

interface DeviceAssignmentLayersProps {
  deviceAssignments: Array<DeviceAssignmentLayerPair>;
}

export default function HeatmapLayers({ deviceAssignments }: DeviceAssignmentLayersProps) {
  return useMemo(
    () => (
      <>
        {deviceAssignments.map((da, index) => {
          return <HeatmapLayer key={`deviceAssignmentLayer.${index}`} heatmap={da.heatmap} />;
        })}
      </>
    ),
    [deviceAssignments],
  );
}
