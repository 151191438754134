import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { OptionalObjectSchema } from 'yup/lib/object';
import FormTextAreaInput from '../../../shared/components/forms/form-text-area-input/FormTextAreaInput';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { toast } from 'react-toastify';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import useBlocker from '../../../shared/hooks/UseBlocker';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import {
  MaximumLongNameLength,
  MaximumDescriptionLength,
  MaximumChemicalActiveIngredientLength,
} from '../../../shared/constants/ValidationConstants';
import FormSelectInput from '../../../shared/components/forms/form-select-input/FormSelectInput';
import { chemicalTypeOptions } from '../utilities/ChemicalTypeUtilities';

type Props<T extends FormikValues> = {
  initialValues?: T;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: OptionalObjectSchema<any>;
  onClose: () => void;
  onSubmit: (values: T) => Promise<void>; // Means to provide custom onSubmit functionality
  isEdit?: boolean;
};

// Abstracted user form allowing for the same structure to be used for editing and creating a user
export default function ChemicalFormContent<T extends FormikValues>({
  isEdit,
  validator,
  initialValues,
  onClose,
  onSubmit,
  isOpen,
}: Props<T>) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);
  return (
    <Formik<T>
      initialValues={initialValues ?? (validator.cast({}) as T)}
      validationSchema={validator}
      enableReinitialize
      onSubmit={async (values, helpers: FormikHelpers<T>) => {
        try {
          await onSubmit(values);
          helpers.resetForm();
        } catch (ex) {
          console.log(ex);
          toast(
            `An unexpected error occurred whilst trying to ${initialValues ? 'update' : 'create'} the organisation`,
            {
              type: 'error',
            },
          );
        }
        onClose();
      }}
    >
      {(formik) => {
        return (
          <RSDialog
            title={isEdit ? 'Edit Chemical' : 'Add Chemical'}
            isOpen={isOpen}
            actions={
              <>
                <FormConfirmCloseButton
                  isBlocking={isBlocking}
                  formik={formik}
                  setIsBlocking={setIsBlocking}
                  onClose={() => {
                    formik.resetForm();
                    onClose();
                  }}
                />
                <RSButton
                  variant="primary"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  {isEdit ? 'Update' : 'Add'}
                </RSButton>
              </>
            }
          >
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                setIsBlocking(false);
              }}
              onChange={() => {
                setIsBlocking(true);
              }}
            >
              <FormTextInput
                required
                label="Name"
                name="name"
                placeholder="Name of chemical"
                maxLength={MaximumLongNameLength}
              />
              <FormTextInput
                required
                label="Active Ingredient"
                name="activeIngredient"
                placeholder="Active Ingredient"
                maxLength={MaximumChemicalActiveIngredientLength}
              />
              <FormSelectInput required name="type" label="Type" options={chemicalTypeOptions} />
              <FormTextInput
                required
                label="Manufacturer"
                name="manufacturer"
                placeholder="Manufacturer"
                maxLength={MaximumLongNameLength}
              />
              <FormTextAreaInput
                required
                label="Description"
                name="description"
                placeholder="Write a description for the chemical here..."
                maxLength={MaximumDescriptionLength}
              />
            </Form>
          </RSDialog>
        );
      }}
    </Formik>
  );
}
