import { ShareStatus } from '../../../graphql/generated';
import { BadgeType } from '../../../shared/components/tags/RSBadge';

export const shareStatusToText: { [key: string]: string } = {
  [ShareStatus.Denied]: 'Declined',
  [ShareStatus.Waiting]: 'Waiting',
  [ShareStatus.Accepted]: 'Accepted',
};

export const shareStatusToBadgeType: { [key: string]: BadgeType } = {
  [ShareStatus.Accepted]: 'success',
  [ShareStatus.Waiting]: 'warning',
  [ShareStatus.Denied]: 'danger',
};
