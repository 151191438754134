import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import GridTile from '../../layout/tiles/GridTile';

type RSBannerType = 'alert' | 'danger';

interface RSBannerProps {
  type: RSBannerType;
  text: string;
  title?: string;
}

export default function RSBanner(props: RSBannerProps) {
  const [bgColour, title] = useMemo(() => {
    return [props.type == 'alert' ? 'bg-amber-100' : 'bg-red-100', props.title ? props.title : ''];
  }, []);

  return (
    <GridTile title={title} className={`col-span-3 md:col-span-6 selection:${bgColour} p-4`}>
      <FontAwesomeIcon icon={faWarning} className="pr-4" />
      {props.text}
    </GridTile>
  );
}
