import { UserRole } from '../../../graphql/generated';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';

// UserRoles that can be help by users of an organisation
export const organisationRoles = [UserRole.OrganisationAdmin, UserRole.SkilledOperator, UserRole.Operator];
// UserRoles that can be held by users without an organisation
export const globalRoles = [UserRole.SuperAdmin, UserRole.Manufacturer];

export const userRoleToText: { [key: string]: string } = {
  [UserRole.Manufacturer]: 'Manufacturer',
  [UserRole.OrganisationAdmin]: 'Organisation Administrator',
  [UserRole.SkilledOperator]: 'Skilled Operator',
  [UserRole.Operator]: 'Operator',
  [UserRole.SuperAdmin]: 'Super Administrator',
};

export const userRoleDropdownItems: Array<DropDownOption<UserRole>> = (function () {
  return Object.entries(userRoleToText).map(([key, val]) => ({
    displayValue: val,
    value: key as UserRole,
  }));
})();

export function userRolesToDropdownItems(roles: UserRole[]) {
  return roles.map((x) => ({
    displayValue: userRoleToText[x],
    value: x,
  }));
}

export function getUserRoleOptions(
  isSuperAdmin: boolean,
  isEdit: boolean | undefined,
  userRole: UserRole,
  isOrganisational: boolean,
) {
  // If the user using the form is a SuperAdmin and not on the organisation user page
  if (isSuperAdmin && !isOrganisational) {
    // If a SuperAdmin is creating a user they can set whatever role
    if (!isEdit) {
      return Object.values(UserRole);
    }
    // If the role the user already has is a global user they can only be updated to be a global user
    else if (globalRoles.includes(userRole)) {
      return globalRoles;
    }
    // If the role the user already has is an org user they can only be updated to be a org user
    else {
      return organisationRoles;
    }
  }
  // Org users can only create other org users
  else {
    return organisationRoles;
  }
}
