import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Options } from 'ol/layer/BaseVector';
import Geometry from 'ol/geom/Geometry';

export default function VectorLayer(options: Options<VectorSource<Geometry>>) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const vectorLayer = new OLVectorLayer(options);

    map.addLayer(vectorLayer);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, options]);

  return null;
}
