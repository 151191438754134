import { useNavigate } from 'react-router-dom';
import { Firmware } from '../../../graphql/generated';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';

interface FirmwareTagProps {
  firmware: Firmware;
}

export default function FirmwareTag({ firmware }: FirmwareTagProps) {
  const navigate = useNavigate();
  return (
    <RSTag
      title={firmware.version}
      className="bg-gray-300"
      onClick={() => navigate(`${localRoutingConstants.firmware.root}/${firmware.id}`)}
    />
  );
}
