import { useEffect } from 'react';
import useOverrideBreadcrumbStore from './stores/UseOverrideBreadcrumbStore';

interface UseSetBreadcrumbsProps<T> {
  loading: boolean;
  entity: T | undefined;
  params: {
    [x: string]: (item: T) => string;
  };
}

export default function useSetBreadcrumbs<T>({ loading, entity, params }: UseSetBreadcrumbsProps<T>) {
  const setCustomBreadcrumb = useOverrideBreadcrumbStore((store) => store.setCrumbForPath);

  useEffect(() => {
    if (!loading && entity !== undefined && entity !== null) {
      const breadcrumbParams: { [x: string]: string } = {};

      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          breadcrumbParams[key] = params[key](entity);
        }
      }

      setCustomBreadcrumb(breadcrumbParams);
    }
  }, [entity]);
}
