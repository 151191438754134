import { ApolloError } from '@apollo/client/errors';
import {
  faBed,
  faCalendar,
  faClock,
  faClockRotateLeft,
  faCode,
  faCrosshairs,
  faDroplet,
  faIdBadge,
  faPencil,
  faSatellite,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Device,
  FirmwareLockStatus,
  Job,
  LastCalibrationDto,
  Recipe,
  User,
  useGetDeviceQuery,
} from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import { RSTag } from '../../../shared/components/tags/RSTag';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useSetBreadcrumbs from '../../../shared/hooks/UseSetBreadcrumbs';
import NotFoundPage from '../../../shared/layout/errors/NotFoundPage';
import QueryError from '../../../shared/layout/errors/QueryErrorPage';
import BubbleLoader from '../../../shared/layout/loading/BubbleLoader';
import RSPivotTable from '../../../shared/layout/table/RSPivotTable';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import DeviceFormDialog from '../components/DeviceFormDialog';
import FirmwarePivotRow from '../components/FirmwarePivotRow';
import { GetFullUnitDisplay } from '../../../shared/utilities/UnitConversionUtilities';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default function DevicePage({}: Props) {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { deviceId } = useParams();
  const navigate = useNavigate();

  const {
    data: data,
    loading: loading,
    error: error,
    fetchMore,
  } = useGetDeviceQuery({
    variables: {
      id: deviceId as string,
    },
  });

  const device = data?.device as Device;

  useSetBreadcrumbs({
    loading: loading,
    entity: device,
    params: {
      deviceId: (x) => x.alias,
    },
  });

  if (loading) {
    return <BubbleLoader />;
  } else if (error) {
    return <QueryError resourceName="device" apolloError={error as ApolloError} />;
  } else if (device === null && !loading) {
    return <NotFoundPage />;
  }

  const rows = [
    {
      name: 'Id/IMEI',
      value: device.id,
      icon: faPencil,
    },
    {
      name: 'Alias',
      value: device.alias,
      icon: faIdBadge,
    },
    {
      name: 'Calibration Volume',
      value: GetFullUnitDisplay(device.calibrationVolume, 2),
      icon: faDroplet,
    },
    {
      name: 'Calibration Timeout',
      value: `${device?.calibrationTimeout}  (s)`,
      icon: faClock,
    },
    {
      name: 'Rinse Volume',
      value: GetFullUnitDisplay(device.rinseVolume, 2),
      icon: faDroplet,
    },
    {
      name: 'Rinse Timeout',
      value: `${device?.rinseTimeout} (s)`,
      icon: faClock,
    },
    {
      name: 'Logging Period',
      value: `${device?.loggingPeriod} (s)`,
      icon: faClockRotateLeft,
    },
    {
      name: 'Sleep Timeout',
      value: `${device?.sleepTimeout} (min)`,
      icon: faBed,
    },
    {
      name: 'GPS Accuracy',
      value: GetFullUnitDisplay(device.gpsAccuracy, 2),
      icon: faSatellite,
    },
    {
      name: 'Activation Date',
      value: moment(device.activationDate).toLocaleString(),
      icon: faCalendar,
    },
    {
      name: 'Last Calibration',
      value: formatCalibratedTime(device.lastCalibration),
      icon: faCrosshairs,
      expandable: device.lastCalibration ? true : false,
      notCopyable: true,
      expandContent: device.lastCalibration ? (
        <RSPivotTable
          leftBorder={false}
          rows={[
            {
              name: 'Ticks',
              value: device.lastCalibration.ticks,
              icon: faClock,
            },
            {
              name: 'Volume Dispensed',
              value: GetFullUnitDisplay(device.lastCalibration.volumeDispensed, 2),
              icon: faDroplet,
            },
          ]}
        />
      ) : undefined,
    },
    {
      name: 'Firmware',
      icon: faCode,
      notCopyable: true,
      value: <FirmwarePivotRow firmwareInformation={device?.firmwareInformation} />,
    },
  ];

  const jobCols: Array<RSTableColumnDefinition<Job>> = [
    {
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
      id: 'name',
    },
    {
      title: 'Start Time',
      accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
      id: 'startTime',
    },
    {
      title: 'Operator',
      accessor: (x) => {
        const user = x.deviceAssignments.find((x) => x?.device.id == device.id)?.user as User;
        return (
          <RSTag
            className="bg-gray-300"
            title={user?.fullName}
            onClick={(event) => {
              event.stopPropagation();
              return navigate(`${localRoutingConstants.users.root}/${user.id}`);
            }}
          />
        );
      },
      id: 'operator',
    },
    {
      title: 'Recipe',
      accessor: (x) => {
        const recipe = x.recipe as Recipe;
        return (
          <RSTag
            className="bg-gray-300"
            title={recipe?.name}
            onClick={(event) => {
              event.stopPropagation();
              return navigate(`${localRoutingConstants.recipes.root}/${recipe.id}`);
            }}
          />
        );
      },
      id: 'recipe',
    },
  ];

  return (
    <>
      {/* Component */}
      <RSGrid>
        <GridTile
          colSpan={3}
          title={'Overview'}
          actions={
            <PermissionGuard policy={AuthPolicy.DeviceManagement}>
              <RSButton variant="primary" onClick={() => setShowEdit(true)}>
                <FontAwesomeIcon icon={faPencil} />
                Edit Device
              </RSButton>
            </PermissionGuard>
          }
        >
          <RSPivotTable rows={rows} />
        </GridTile>
        <GridTile colSpan={3} title={'Associated Jobs'}>
          <RSTable
            data={(data?.device?.jobs?.edges?.map((e) => e.node) as unknown as Job[]) ?? []}
            totalCount={data?.device?.jobs?.totalCount}
            columns={jobCols}
            onClickPathPrefix={`${localRoutingConstants.jobs.root}`}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.device?.jobs?.pageInfo.endCursor as string) : undefined,
                },
              });
            }}
          />
        </GridTile>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.DeviceManagement} isVisible={showEdit}>
        <DeviceFormDialog
          initialValues={device}
          orgLock={
            device.organisation?.organisationFirmware?.lockStatus == FirmwareLockStatus.Locked
              ? device.organisation?.organisationFirmware?.firmware.id
              : undefined
          }
          isOpen={true}
          onClose={() => setShowEdit(false)}
        />
      </PermissionGuard>
    </>
  );
}
function formatCalibratedTime(lastCalibratedAt: LastCalibrationDto | undefined): string {
  if (!lastCalibratedAt) return 'Never';

  return `${moment.duration(moment().diff(moment(lastCalibratedAt.time))).humanize()} ago`;
}
