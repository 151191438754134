import { ApolloError } from '@apollo/client/errors';

type Props = {
  resourceName: string;
  apolloError: ApolloError;
};

export default function QueryError({ resourceName, apolloError }: Props) {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <h2>There was an error loading {resourceName}:</h2>
      <p>{apolloError.message}</p>
    </div>
  );
}
