import { ChemicalType } from '../../../graphql/generated';
import { DropDownOption } from '../../../shared/components/input/DropDownMenu';

export const chemicalTypeToText: { [key in ChemicalType]: string } = {
  [ChemicalType.Liquid]: 'Liquid',
  [ChemicalType.Solid]: 'Solid',
};

export const chemicalTypeOptions: DropDownOption<string>[] = [
  {
    value: ChemicalType.Liquid,
    displayValue: chemicalTypeToText[ChemicalType.Liquid],
  },
  {
    value: ChemicalType.Solid,
    displayValue: chemicalTypeToText[ChemicalType.Solid],
  },
];

export const chemicalTypeAxisLabels: { [key in ChemicalType]: string } = {
  [ChemicalType.Liquid]: 'Volume',
  [ChemicalType.Solid]: 'Weight',
};
