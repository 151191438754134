/**
 * Stores available compass direction representations
 */
const directionText: string[] = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

/**
 * Converts a bearing to a compass direction
 * @param bearing The bearing to be converted
 * @returns The compass direction
 */
export function BearingToDirectionText(bearing: number) {
  return directionText[Math.round(bearing / 45)];
}
