import { toast } from 'react-toastify';
import { FirmwareLockStatus, useBatchSetOrganisationFirmwareMutation } from '../../../graphql/generated';
import { BatchSelection } from '../../../shared/layout/table/RSTable';
import OrganisationFirmwareFormContent from './OrganisationFirmwareFormContent';
type Props = {
  selection: BatchSelection;
  isOpen: boolean;
  onClose: () => void;
};

export default function BatchOrganisationFirmwareFormDialogue({ selection, isOpen, onClose }: Props) {
  const [batchSetFirmware] = useBatchSetOrganisationFirmwareMutation();

  return (
    <OrganisationFirmwareFormContent
      initialValues={{
        firmwareId: '',
        firmwareVersion: '',
        lockedStatus: FirmwareLockStatus.Unlocked,
      }}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={async (value) => {
        await batchSetFirmware({
          variables: {
            input: {
              firmwareId: value.firmwareId,
              organisationIds: selection.data,
              selectAll: selection.selectAll,
              lockStatus: value.lockedStatus,
            },
          },
          onCompleted() {
            toast('Firmware for the organisation has been updated.', { type: 'success' });
          },
          onError: (error) => {
            toast(
              'An unknown error occurred when updating this organisations firmware. Please try again later in 2 minutes or contact support',
              { type: 'error' },
            );
          },
        });
        onClose();
      }}
    />
  );
}
