import { UserStatus } from '../../../graphql/generated';

export const userStatusToText: { [key: string]: string } = {
  [UserStatus.Archived]: 'Archived',
  [UserStatus.Compromised]: 'Compromised',
  [UserStatus.Confirmed]: 'Confirmed',
  [UserStatus.ForceChangePassword]: 'Unconfirmed',
  [UserStatus.ResetRequired]: 'Reset Required',
  [UserStatus.Unknown]: 'Unknown',
};

export const userStatusToTailwindClassName: { [key: string]: string } = {
  [UserStatus.Archived]: 'bg-red-400 text-red-800',
  [UserStatus.Compromised]: 'bg-red-400 text-red-800',
  [UserStatus.Confirmed]: 'bg-green-400 text-green-800',
  [UserStatus.ForceChangePassword]: 'bg-red-400 text-red-800',
  [UserStatus.ResetRequired]: 'bg-red-400 text-red-800',
  [UserStatus.Unknown]: 'bg-red-400 text-red-800',
};
