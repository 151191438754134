import moment from 'moment';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { GetJobDocument, Job, useCompleteJobMutation } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import { weedValueToButtonsPressed } from '../utilities/WeedButtonUtilities';
import RSConfirmationDialog from '../../../shared/components/rs-dialog/confirmation/RSConfirmationDialog';

interface CompleteJobDialogProps {
  job: Job;
  isOpen: boolean;
  onClose: VoidFunction;
}

export default function CompleteJobDialog({ job, isOpen, onClose }: CompleteJobDialogProps) {
  const [completeJob] = useCompleteJobMutation();

  const errors: string[] = useMemo(() => {
    const errors: string[] = [];
    if (!job.startTime) {
      errors.push('Start Time must be set');
    }
    if (!job.endTime) {
      errors.push('End Time must be set');
    } else if (moment(job.endTime) > moment()) {
      errors.push('End Time cannot be in the future.');
    }
    if (!job.location) {
      errors.push('Location must be set');
    }
    if (!job.deviceAssignments.length) {
      errors.push('A device Assignment must be set.');
    }
    if (!job.recipe) {
      errors.push('Recipe must be set');
    }

    // Check whether or not a button was pressed with no weed assignment
    const weedsEncountered = job.deviceAssignments
      .flatMap((da) => da.sprayLogs.map((s) => s.w))
      .filter((value, index, array) => array.indexOf(value) === index)
      .flatMap((x) => weedValueToButtonsPressed[x]);
    for (let index = 0; index < weedsEncountered.length; index++) {
      const element = weedsEncountered[index] as number;
      if (!job.weedAssignments[element - 1]) {
        errors.push(`You must set a weed for button ${element}`);
      }
    }

    return errors;
  }, [job]);

  return (
    <>
      <RSConfirmationDialog
        isOpen={isOpen}
        confirmTitle={'Complete'}
        title={'Complete Job?'}
        type={errors.length == 0 ? 'primary' : 'disabled'}
        onConfirm={async () => {
          await completeJob({
            variables: {
              input: {
                id: job.id,
              },
            },
            onCompleted(data) {
              toast(`${data.completeJob.name} has been successfully completed!`, {
                type: 'success',
              });
              onClose();
            },
            onError(error) {
              toast(`Error: ${error.message}`, {
                type: 'error',
              });
            },
            refetchQueries: [GetJobDocument],
          });
        }}
        closeTitle={'Close'}
        onClose={onClose}
      >
        <>
          <span>
            Completing a job calculates recipe and chemical usage statistics but prevents any additional changes being
            made, preserving its current state.
          </span>
          {errors.length > 0 && (
            <>
              <br />
              <br />
              The following item(s) prevent this job from being completed:
              <ul>
                {errors.map((error, index) => {
                  return (
                    <li key={`error.${index}`} className="mx-5 list-disc">
                      {error}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </>
      </RSConfirmationDialog>
    </>
  );
}
