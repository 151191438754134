import * as Yup from 'yup';
import { MaximumDescriptionLength } from '../../../shared/constants/ValidationConstants';

export const firmwareValidator = Yup.object({
  description: Yup.string().required().min(5).max(MaximumDescriptionLength),
  version: Yup.string()
    .required()
    .trim()
    .matches(/^(\d)*((\.(\d)+)){1,3}$/, 'Must match: major.minor[.build[.revision]]'),
  firmwareUploadSlot1: Yup.mixed().required('Firmware Binary for slot 1 is required'),
  firmwareUploadSlot2: Yup.mixed().required('Firmware Binary for slot 2 is required'),
});
