import { useState, useRef, useEffect, ReactElement } from 'react';

interface SimpleDropdownProps {
  trigger: ReactElement;
  children: ReactElement | ReactElement[] | undefined;
  align?: 'left' | 'right';
}

const SimpleDropdown = ({ trigger, children, align = 'left' }: SimpleDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div ref={ref} className="relative border-gray-100">
      <div onClick={toggleDropdown} className="cursor-pointer">
        {trigger}
      </div>
      {isOpen && (
        <div
          className={`absolute ${align === 'left' ? 'left-0' : 'right-0'} mt-2 py-2 w-48 bg-white rounded-lg shadow-xl`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default SimpleDropdown;
