import { FieldMetaProps } from 'formik';

type FormErrorTextProps<T> = {
  meta: FieldMetaProps<T>;
};

export default function FormErrorText<T>({ meta }: FormErrorTextProps<T>) {
  if (meta.touched && meta.error && !Array.isArray(meta.error)) {
    return <div className="rs-error-text">{meta.error ?? 'This field is invalid'}</div>;
  }
  return <></>;
}
