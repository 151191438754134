import { QueryHookOptions, QueryResult } from '@apollo/client';
import useSearchSortFilter, { SearchFieldNames } from '../../../hooks/UseSearchSortFilter';
import { SearchableQueryVariables } from '../combo-box/RSComboBox';
import { ReactNode, useMemo, useState } from 'react';
import { Autocomplete, InputAdornment, TextField, ThemeProvider, createTheme, debounce } from '@mui/material';

interface MuiComboBoxWithQueryProps<
  TEntity,
  TQuery,
  TQueryVariables extends SearchableQueryVariables<TFilterInput>,
  TFilterInput,
> {
  dataAccessorCallback: (data: TQuery) => TEntity[];
  searchFieldNames: SearchFieldNames<TFilterInput>;
  query: (baseOptions: QueryHookOptions<TQuery, TQueryVariables>) => QueryResult<TQuery, TQueryVariables>;
  first?: number;
  variables?: Omit<TQueryVariables, 'where' | 'first'>;
  onChange: (entity: TEntity) => void;
  defaultValue: TEntity | undefined;
  icon?: ReactNode;
  placeholder?: string;
  extraPadding?: boolean;
  denyDeselect?: boolean;
  name: string;
  optionLabelAccessor: (entity: TEntity) => string;
}

export function MuiComboBoxWithQuery<
  TEntity,
  TQuery,
  TQueryVariables extends SearchableQueryVariables<TFilterInput>,
  TFilterInput,
>({
  dataAccessorCallback,
  defaultValue,
  onChange,
  query,
  searchFieldNames,
  denyDeselect,
  first,
  icon,
  variables,
  name,
  optionLabelAccessor,
}: MuiComboBoxWithQueryProps<TEntity, TQuery, TQueryVariables, TFilterInput>) {
  const { where, setSearchString } = useSearchSortFilter<TFilterInput>({
    searchableFieldNames: searchFieldNames,
  });

  const { data, loading } = query({
    variables: {
      ...variables,
      where: where,
      first: first ?? 3,
    } as TQueryVariables,
  });

  const [InputValue, setInputValue] = useState('');

  const debouncedInputChange = useMemo(
    () => debounce((newInputValue: string) => setSearchString(newInputValue), 500),
    [],
  );

  const handleInputChange = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    setInputValue(value);
    debouncedInputChange(value);
  };

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#00788A',
          },
        },
      })}
    >
      <Autocomplete
        options={data ? dataAccessorCallback(data) : []}
        loading={loading}
        defaultValue={defaultValue}
        getOptionLabel={optionLabelAccessor}
        inputValue={InputValue}
        onInputChange={handleInputChange}
        disableClearable={denyDeselect ?? false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={name}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : null,
            }}
          />
        )}
        onChange={(_, value) => {
          onChange(value as TEntity);
        }}
      />
    </ThemeProvider>
  );
}
